/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import {
    NavLink,
    useRouteMatch,
    useHistory
} from "react-router-dom";

import footerIcon from '../../../assets/images/sidebar-foot.png';
import { LogoutOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../../assets/images/defaultAvatar.jpeg';
import Logo from '../../../assets/images/logo.png';
import { GET, POST } from '../../../apiClient';
import { studentLogout, tutorLogout } from '../../../globalConstant';
import { notification } from "antd";


const SideBar = (props) => {
    let { url } = useRouteMatch();
    let history = useHistory();
    const dispatch = useDispatch();
    const [isCollapse, setCollapse] = useState(false);
    const [isUserRole, setUserRole] = useState('');
    const [userEmail, setUserEmail] = useState('');

    let user = useSelector((state) => state.studentProfileReducer);
    // console.log(user);
    const toggleMenu = () => {
        props.toggleMenu();
        setCollapse(!isCollapse)
    }

    const logoutHandler = () => {
        localStorage.clear();

        const url = (isUserRole === 'tutor' ? tutorLogout : studentLogout);
        GET(url).then(res => {
            if (res.data.data.success == 1) {
                notification.success({ message: 'Success' });
                dispatch({ type: 'LOGOUT' });
                history.push('/');
            }
            else {
                notification.error({ message: 'Error' });

            }
        });
    }

    const getUserInfo = async () => {
        const info = await localStorage.getItem('userInfo');
        const role = JSON.parse(info).userRole;
        setUserRole(role);
        setUserEmail(JSON.parse(info).email);
    }

    useEffect(() => {
        getUserInfo();
    }, []);


    return (
        <>
            <div className={(window.innerWidth<556) ? "sidebar-menu closesidebar"  : (isCollapse) ? "sidebar-menu closesidebar" : "sidebar-menu"}>
                <div className="overflow">
                    <div className="user-avatar">
                        <div className="avatar">
                            {/* <img src={DefaultAvatar} alt="avatar" /> */}
                            <img src={user?.photo || DefaultAvatar} alt="avatar" />
                        </div>
                        <h6>{isUserRole}</h6> 
                        { user.firstName && <span style={{color:'white'}}><strong>{user.firstName}&nbsp;{user.lastName}</strong><br/></span> }
                        <span style={{color:'white'}}>{userEmail}</span>
                    </div>
                    <div className="actionbtn" onClick={() => toggleMenu()}>
                        <span></span>
                    </div>
                    <menu id="nav">
                        <ul>
                            <li className="dashboard">
                                <NavLink title="Dashboard" to={`${url}`} exact activeClassName="active dashboard" >
                                    <i></i>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            {/* <li className="archive"><NavLink title="Archive" to={`${url}/archive`} exact activeClassName="active archive" ><i></i> <span>Archive</span></NavLink></li> */}
                            {/* { (isUserRole === 'student') && <li className="parental"><NavLink title="Parental Watch" to={`${url}/parental-watch`} exact activeClassName="active parental" ><i></i> <span>Parental Watch</span></NavLink></li> } */}
                            { (isUserRole === 'tutor') && <li className="parental"><NavLink title="Parental Watch" to={`${url}/payment`} exact activeClassName="active parental" ><i></i> <span>My Payments</span></NavLink></li> }
                           <li className="parental"><NavLink title="Parental Watch" to={`${url}/subscription`} exact activeClassName="active parental" ><i></i> <span>Subscription</span></NavLink></li> 



                            <li className="quran"><NavLink title="Quran Revision" to={`${url}/revision`} exact activeClassName="active quran" ><i></i> <span>Quran Revision</span></NavLink></li>
                            <li className="availability"><NavLink title="Availability" to={`${url}/availability`} exact activeClassName="active availability" ><i></i>
                            { (isUserRole === 'tutor') ?<span>Availability</span>:<span>Booked Classes</span>}
                             </NavLink></li>
                            <li className="messages"><NavLink title="Messages" to={`${url}/chat`} exact activeClassName="active messages" ><i></i> <span>Messages</span></NavLink></li>
                            <li className="edit"><NavLink title="Edit Profile" to={`${url}/profile`} exact activeClassName="active edit" ><i></i> <span>Edit Profile</span></NavLink></li>
                            {/* <li className="logout" onClick={logoutHandler}> <p> Logout</p></li> */}






                        </ul>
                    </menu>
                    <div className="helpsupport">
                        <a href="/" title="Need Help & Support?">
                            <div className="helpsupprt-img">
                                <img src={footerIcon} alt="" />
                            </div>
                            <h6>Need HElp & SUPPORT?</h6>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideBar
