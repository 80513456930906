/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Card, Button, Rate, Radio, message, Modal } from 'antd';
import DefaultAvatar from '../../../assets/images/defaultAvatar.jpeg';
import EmptyVideo from '../../../assets/images/video_files.svg';
import EmptyAudio from '../../../assets/images/audio_player.svg';
import { GET, POST } from '../../../apiClient';
import { tutorDetail, studentTutorDetail } from '../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import getUserInfo from "../../../shared/Helpers/getUserInfo";
import StarRatings from 'react-star-ratings';





const TutorDetail = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [detail, setDetail] = useState({})
    const [level, setLevel] = useState(1);
    const [compSkill, setCompSkill] = useState(1);
    const [optionsWithDisabled, setOptions] = useState([

        { label: '1', value: 0, disabled: true },
        { label: '2', value: 1, disabled: true },
        { label: '3', value: 2, disabled: true },
        { label: '4', value: 3, disabled: true },
        { label: '5', value: 4, disabled: true },
        { label: '6', value: 5, disabled: true },
        { label: '7', value: 6, disabled: true },
        { label: '8', value: 7, disabled: true },
        { label: '9', value: 8, disabled: true },
        { label: '10', value: 9, disabled: true },
    ]);
    const [englishOptionsWithDisabled, setEnglishOptions] = useState([

        { label: '1', value: 0, disabled: true },
        { label: '2', value: 1, disabled: true },
        { label: '3', value: 2, disabled: true },
        { label: '4', value: 3, disabled: true },
        { label: '5', value: 4, disabled: true },
        { label: '6', value: 5, disabled: true },
        { label: '7', value: 6, disabled: true },
        { label: '8', value: 7, disabled: true },
        { label: '9', value: 8, disabled: true },
        { label: '10', value: 9, disabled: true },

    ]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [isUserInfo, setUserInfo] = useState({});
    const [user, setUser] = useState("");

    const [isLogin, setLogin] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        dispatch({ type: 'LOADING', payload: true });
        GET(`${studentTutorDetail}/${params.id}/details`).then(res => {
            const { data } = res;

            if (data.data.success == 1) {
                console.log(res)
                setDetail(data.data.data);
                const templevel = englishOptionsWithDisabled;
                templevel[data.data.data.englishProficiency - 1].disabled = false;



                const temp2 = optionsWithDisabled;
                temp2[data.data.data.computerSkills - 1].disabled = false;
                setOptions(temp2)
                setEnglishOptions(templevel);
                setCompSkill(data.data.data.computerSkills - 1);
                setLevel(data.data.data.englishProficiency - 1);

            }
            else {
                notification.error({ message: 'Error' });
            }
            dispatch({ type: 'LOADING', payload: false });
        });


        return () => {


        }
    }, [])

    useEffect(() => {
        getUserInfo().then((res) => {
            setUserInfo(res);

            if (isLogin === true) {
                setUserName();
            }
        });
    }, []);
    const sendInvite = (invitedTo) => {
        try {
            let data = {
                invitedTo,
                invitedBy: isUserInfo.loginId,
                role: isUserInfo.userRole,
                username: user,
            };
            console.log("invitedTo", data);
            const invitation = POST('contact', data);

            console.log("Success", invitation);
            message.loading(`Please wait`).then(() => {
                message.success({
                    content: `Invitation sent`,
                    style: {
                        zIndex: "999999",
                        fontSize: "20px",
                    },
                });
                // setInvitedId(invitedTo);
                // getAllTutors(isUserInfo);
            });
        } catch (error) {
            console.log(error);
            message.loading(`Please wait`).then(() => {
                message.warning({
                    content: `Error while sending invitation`,
                    style: {
                        zIndex: "999999",
                        fontSize: "20px",
                    },
                });
            });
        }
    };
    const setUserName = async () => {
        const userInfo = await localStorage.getItem("userInfo");
        const name = JSON.parse(userInfo);
        console.log("User Info", name);

        setUser(name.username);
        setLogin(true);
    };
    const desc = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];

    return (
        <div className="listing">

            <div className="back-btn" onClick={() => history.push(`${history.goBack()}`)} ><span className="mr-2"><ArrowLeftOutlined /></span>Back</div>
            {/* <Button style={{ fontFamily: 'initial' }} type="primary" icon={<ArrowLeftOutlined />}
                 >Back</Button> */}

            <Row className="m-0" align="top" gutter={[48, 32]}>


                <Col span={12}>
                    <Card bordered={false} onClick={() => history.push(`${history.location.pathname}`)}>
                        <div className="center ">
                            <div className="mr-4"><img src={detail?.profileImage || DefaultAvatar} className="avatar" /> </div>
                            <div className="info mt-3">
                                <div className="text-capitalize"><b>{detail.firstName} {detail.lastName}</b></div>
                                <div>{detail.email}</div>
                                <div>{detail.gender}</div>
                                <div>{detail.dob}</div>
                                <div>{detail.cellNumber}</div>
                                <div>{detail.province} {detail.country}</div>
                                <div>{detail.timeZone}</div>
                                <div>{detail.sect}</div>
                                {detail.hourlyRate && (<div>${detail.hourlyRate}/hr </div>)}
                                {detail?.payoneerId ? <div>{detail?.payoneerId}</div> : null}
                                {detail?.totalClasses ? <div>Total {detail?.totalClasses} Classes</div> : null}

                            </div>
                            <div>
                                <a
                                    className="green-btn "
                                    onClick={() => sendInvite(params.id)}
                                >
                                    Send Invitation
                              </a>
                            </div>
                        </div>

                    </Card>

                </Col>
                <Col span={12}>
                    <Card>
                        <div className="left">
                            <div className="content">
                                <small  >About</small>
                                {detail?.about ? <small><strong> {detail.about} </strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        {/* <div className="left">
                            <div className="content">
                                <small>Skills</small>

                                {detail?.skills?.length ? <small ><strong className='text-capitalize'>{detail?.skills?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider /> */}
                        <div className="left">
                            <div className="content">
                                <small>Languages</small>

                                {detail.languages?.length ? <small > <strong className='text-capitalize'>{detail?.languages?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Subjects</small>
                                {detail.subjects?.length ? <small > <strong className='text-capitalize'>{detail?.subjects?.join(', ')}</strong> </small> : 'N/A'}

                            </div>

                        </div>

                        {detail?.avgRating ?
                            <>
                                <Divider />
                                <div className="left">
                                    <div className="content">
                                        <small>Rating</small>
                                        {/* <Rate allowHalf  style={{color:'yellow'}} value={2.3} /> */}
                                        <span className="d-flex">
                                           
                                                <StarRatings
                                                    // rating={3.2}
                                                    rating={detail?.avgRating}
                                                    starRatedColor="yellow"
                                                    starDimension="25px"
                                                    numberOfStars={5}
                                                    name='rating'
                                                />
                                                <small className="ml-2">({detail?.avgRating})</small>
                                               

                                        </span>

                                    </div>

                                </div>
                            </>
                            : null}
                        {detail?.reviews?.length > 0 ?
                            <>
                                <Divider />
                                <div className="left">
                                    <div className="content">
                                        <small>Reviews</small>
                                       
                                       {detail?.reviews?.map((val,index)=>(
                                           <>
                                           <small>{val.studentReviewToTutor}
                                           <span className="ml-2">({val.studentRatingToTutor})</span>
                                           </small>
                                           </>
                                       ))}
                                       

                                    </div>

                                </div>
                            </>
                            : null}





                    </Card>
                </Col>


            </Row>
            <Row className="m-0" align="top" gutter={[48, 32]}>
                <Col span={24}>
                    <Card>

                        <div className="left">
                            <div className="content">

                                <small>What is your level of English proficiency?</small>
                                <div >
                                    <div className="d-flex mt-4">
                                        <small className="level-text"> <strong> Not Good </strong></small>


                                        <Radio.Group style={{ display: 'flex' }}
                                            options={englishOptionsWithDisabled}
                                            value={level}
                                            defaultValue={level}

                                        />


                                        <small className="level-text"> <strong> Very Good </strong></small>

                                    </div>
                                </div>


                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">

                                <small>How would you rate your computer skills?</small>
                                <div >
                                    <div className="d-flex mt-4">
                                        <small className="level-text"> <strong> Not Good </strong></small>

                                        <Radio.Group style={{ display: 'flex' }}
                                            options={optionsWithDisabled}
                                            value={compSkill}
                                            defaultValue={compSkill}


                                        />
                                        <small className="level-text"> <strong> Very Good </strong></small>

                                    </div>
                                </div>


                            </div>

                        </div>

                    </Card>
                </Col>
            </Row>

            <Row className="m-0" align="top" gutter={[48, 32]}>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Audios</b> </small>


                                {detail?.signUpAudio?.audioLink1 || detail?.signUpAudio?.audioLink2 || detail?.signUpAudio?.audioLink3 ?

                                    <div className="m-0 center" >


                                        <div className="mb-2 mt-3">
                                            {detail?.signUpAudio?.audioLink1 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink1} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div className="mb-2">
                                            {detail?.signUpAudio?.audioLink2 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink2} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div>
                                            {detail?.signUpAudio?.audioLink3 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink3} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>




                                    </div>

                                    :
                                    <div className="w-100 text-center mt-4">
                                        <img src={EmptyAudio} width="150px" />
                                        <p className="not-fount-text">No audio found</p>
                                    </div>
                                }





                            </div>

                        </div>




                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Videos</b></small>
                                <Row className="m-0" align="top" gutter={[20, 20]}>

                                    {detail?.signUpVideos?.videoLink1 || detail?.signUpVideos?.videoLink2 || detail?.signUpVideos?.videoLink3 ?
                                        <>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink1 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink1} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink2 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink2} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink3 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink3} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                        </>
                                        :

                                        <div className="w-100 text-center mt-4">
                                            <img src={EmptyVideo} width="150px" />
                                            <p className="not-fount-text">No video found</p>
                                        </div>
                                    }



                                </Row>

                            </div>

                        </div>
                    </Card>
                </Col>
            </Row>
            {detail?.certification?.length > 0 && (
                <Row className="m-0" align="top" gutter={[48, 32]}>
                    <Col span={24}>
                        <Card>
                            <small><b>Certificates</b></small>
                            <div style={{ display: 'flex', marginRight: '35px' }}>

                                {detail?.certification && detail?.certification.map((item, index) => (




                                    <div key={index} onClick={() => { setModalImage(item.certificationImageUrl); setIsModalVisible(true) }}>
                                        <img style={{ width: '135px', marginRight: '35px' }} src={item.certificationImageUrl} alt="image" />
                                    </div>

                                ))}
                            </div>
                        </Card>
                    </Col>
                </Row>

            )}

            <Modal
                onCancel={handleCancel}
                visible={isModalVisible}
                footer={false}
            >
                <img className="w-100" src={modalImage} />
            </Modal>
        </div>
    );
}

export default TutorDetail;