/* eslint-disable */ 
import React from 'react'
import './style.css'

const Faqs = () => {
    return (
        <>
            <section id="banner-wrap2">
                <div className="container">
                    <div className="banner-content">
                        <h1>FREQUENTLY ASKED QUESTIONS</h1>
                        <ul className="breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>FAQs</li>
                        </ul>
                    </div>
                </div>
            </section>

            <article className="innerpage">
                <section>
                    <div className="container" id="faqs">
                        <div className="simplestep-content">
                            <div className="simplestep-heading">
                                <h6>Start In The Name Of Allah</h6>
                                <h3>For Tutors</h3>
                                    <h4>HOW TO SIGN UP AS A TUTOR?</h4>
                                        <p>
                                            1.	Goto homepage and click on 'Signup’. Now, click on ‘Signup as a Tutor’ and fill up the form completely.<br/>
                                            2.	Agree to the ‘Term of Use’ and read the rules on the right side of the form. Now click on ‘SignUp’.<br/>
                                            3.	Check your email to validate and click on verify link.<br/>
                                            4.	You can login now with your email credentials that you used for registration.<br/>
                                            5.	Complete your profile with all questions and information. Upload all relevant audio files, certificates etc.<br/>
                                            6.	You can start using the platform once the admin approves your account.
                                        </p>
                                        <br/>
                                    <h4>HOW TO MARK YOUR AVAILABILITY?</h4>
                                        <p>
                                            1.	Once you login to your dashboard, click on Availability on left panel menu.<br/>
                                            2.	Mark the days of calendar when you are available to teach students.<br/>
                                            3.	Automatically all subsequent days in the week will be marked. You can delete or insert any planned leave in the slot easily by clicking it.<br/>
                                            4.	Click on Save.<br/>
                                            5.	Your availability schedule is now visible to those students who visit your profile and have accepted the invitation to connect.
                                        </p>
                                        <br/>
                                    <h4>HOW TO TEACH OR JOIN A SCHEDULED CLASS?</h4>
                                        <p>
                                            1.	A tutor may be able to teach a student only if he/she is connected with the student which is possible by sending invitation request.<br/>
                                            2.	Once a student has booked a class with you, you will be notified via email.<br/>
                                            3.	Click on approve button in the email to confirm the appointment request.<br/>
                                            4.	Visit the dashboard to check your upcoming class and student info. <br/>
                                            5.	Click on ‘Join class button’ which will be enabled 30mins before class.
                                        </p>
                                        <br/>
                                    <h4>HOW TO SEE YOUR EARNINGS?</h4>
                                        <p>
                                            1.	Once you have started teaching, you can view your earnings by going to the left panel menu and clicking on my payments.<br/>
                                            2.	Click on My Payments to view status of payments.<br/>
                                            3.	Click on Subscription to view the student details of classes being done.
                                        </p>
                                        <br/>
                                <b>Barak Allahu Feekum!</b>
                            </div>
                            <br/>
                            <hr/>
                            <br/>
                            <div className="simplestep-heading">
                                <h3>For Students</h3>
                                    <h4>HOW TO SIGN UP AS A STUDENT?</h4>
                                        <p>
                                            1.	Goto homepage www.nidaulquran.com and click on 'Signup’. Now, click on ‘Signup as a Student’ and fill up the form completely.<br/> 
                                            2.	Read the rules on the right side of the form completely and click on ‘SignUp’.<br/>
                                            3.	Check your email to validate and click on verify link.<br/>
                                            4.	You can login now with your email credentials that you used for registration    .<br/>
                                            5.	Complete your profile with all information required correctly.<br/>
                                            6.	You can start now exploring the platform.
                                        </p>
                                        <br/>
                                    <h4>HOW TO REQUEST A FREE TRIAL?</h4>
                                        <p>
                                            1.	Once you are logged on to your account, click on dashboard.<br/>
                                            2.	A red button ‘Request a Free Trial’ will be visible to those students who have their profile fully completed.<br/>
                                            3.	Once you press the button, choose a schedule convenient to you so a tutor is made available.<br/>
                                            4.	Once administrator approve your request, you will be notified through a link which you will use to join the trial class with the tutor.
                                        </p>
                                        <br/>
                                    <h4>HOW TO FIND TUTOR, SEND INVITE, AND DO MESSAGING?</h4>
                                        <p>
                                            1.	Once you are logged into your profile, on the top menu click on Find Tutor. Choose a tutor as per your requirement and send the invite.<br/>
                                            2.	Once the tutor accepts your invite you can start connecting by messaging or chatting.
                                        </p>
                                        <br/>
                                    <h4>HOW TO BOOK A CLASS WITH A TUTOR?</h4>
                                        <p>
                                            1.	A student may be able to book a class only if you are connected together through invitation request.<br/>
                                            2.	Once a connection is made, a button ‘book a slot’ is visible on the dashboard under the Tutor’s name.<br/>
                                            3.	Select the time convenient to you in the availability of the tutor when you click Book a slot.<br/>
                                            4.	Complete Payment <br/>
                                            5.	Once Tutor confirms and approves the appointment, you will be charged and emailed accordingly.
                                        </p>
                                        <br/>
                                    <h4>HOW TO JOIN A SCHEDULED CLASS?</h4>
                                        <p>
                                            1.	Once your appointment has been confirmed by Tutor and you get a Payment notification email.<br/>
                                            2.	Visit the dashboard to check your upcoming class and Tutor info. <br/>
                                            3.	Click on ‘Join class button’ which will be enabled 30mins before class or wait for your tutor to start the class.
                                        </p>
                                        <br/>
                                    <h4>HOW TO SEE YOUR SUBSCRIPTIONS AND BOOKED CLASSES?</h4>
                                        <p>
                                            1.	Once you have started learning & attending classes, you can click on 'Subscriptions' in left panel and see details by class, tutor and payments.<br/>
                                            2.	You can view all your scheduled classes on the left panel by clicking 'Booked Classes'.
                                        </p>
                                        <br/>
                                <b>Jazakum Allahu Khair!</b>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        </>
    )
}

export default Faqs
