/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import './style.css';

// import Header from '../header'
import SideBar from '../sidebar';
// import Footer from '../footer'
// import CurrentStatus from '../currentStatus';

const AdminLayout = 
(props) => {
  const [isToggle, setToggle] = useState(false)
  const [isRole, setUserRole] = useState('')

//   const getUserInfo = async () => {
//     const info = await localStorage.getItem('userInfo');
//     console.log('_INFO_', JSON.parse(info).userRole);
//     const role = JSON.parse(info).userRole;  
//     setUserRole(role);
// }

useEffect(() => {
//   getUserInfo();
}, [])
  return (
    <>
      {/* <Header toggle={!isToggle} /> */}
      <SideBar toggleMenu={() => setToggle(!isToggle)} />
      <div className={window.innerWidth<556 ? "menu" : isToggle ? "menu" : "collapse-menu"}>
        {/* <CurrentStatus name={isRole}/> */}
        {props.children}
      </div>
      {/* <Footer toggle={!isToggle} /> */}
    </>
  )
}

export default AdminLayout
