/* eslint-disable */ 
import React, { useEffect, useState } from 'react';
import './style.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    useHistory,
} from "react-router-dom";
import { StepForwardOutlined } from '@ant-design/icons';
import stepIcon1 from "../../../assets/images/threestepicon1.png";
import stepActiveIcon1 from "../../../assets/images/threestepiconactive1.png";
import stepIcon2 from "../../../assets/images/threestepicon2.png";
import stepActiveIcon2 from "../../../assets/images/threestepiconactive2.png";
import stepIcon3 from "../../../assets/images/threestepicon3.png";
import stepActiveIcon3 from "../../../assets/images/threestepiconactive3.png";

import slideIcon1 from '../../../assets/images/threestepiconactiveslide1.png';
import slideIcon2 from '../../../assets/images/threestepiconactiveslide2.png';
import slideIcon3 from '../../../assets/images/threestepiconactiveslide3.png';
import Steps from '../../components/steps/Steps';
import FourStep from '../../components/FourSteps/fourStep';

import SignupModal from "../../components/userAuthorization/SignupModal/SignupModal";

const PlanAndPricing = () => {

    const [isSignUpModal, setSiginUpModal] = useState(false);

    let { path } = useRouteMatch();
    let history = useHistory();
    const [isLogin, setLogin] = useState(false);
    const [role, setRole] = useState('');
    const [isSlide, setSlide] = useState('plans');

    const slideHandler = (slide) => {
        setSlide(slide);
    }
    useEffect(() => {
        setUserName();
    }, []);
    const setUserName = async () => {
        const userInfo = await localStorage.getItem("userInfo");
        const name = JSON.parse(userInfo);
        console.log("User Info", name);
        if (name) {
            setLogin(true);
            setRole(name.userRole)
        }
    };

    return (
        <>
        
        {isSignUpModal && (
            <SignupModal
                closeModal={() => setSiginUpModal(false)}
                showSignupModal={() => setSiginUpModal(true)}
            />
        )}    

        <section id="planpricing-page">
                <div className="container">
                    <h2>PLANS & PRICING</h2>
                    <p>These price plans are just registration fees. Tution fee is seperate for each plan.</p>
                    <div className="steps" align="center">
                        <h3 style={{textAlign:'center',fontSize:30}}> Click to select Plan</h3>
                    </div>
                    <div className="planpricing-wrap">
                        <div className="planpricing">
                            <div className="planpricing-head">
                                <h3>FREE</h3>
                            </div>
                            <div className="planpricing-body">
                                <ul>
                                    <li>
                                        <span>
                                            <label className="plan-radio">30 Minutes Trial
                                            <input checked type="radio" name="radio01" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio">No Functions
                                            <input checked type="radio" name="radio02" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Upgrade Plan
                                            <input checked type="radio" name="radio03" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Upgrade Plan
                                            <input checked type="radio" name="radio04" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="planpricing-foot">
                                <a onClick={() => {
                                    if (isLogin) {
                                        history.push(`/${role}`)
                                    }
                                    else {
                                        //history.push(`/home`)
                                        setSiginUpModal(true)
                                    }
                                }}>
                                    <h3>$0.0/hr</h3>
                                </a>
                            </div>
                        </div>
                        <div className="planpricing">
                            <div className="planpricing-head">
                                <h3>STANDARD</h3>
                            </div>
                            <div className="planpricing-body">
                                <ul>
                                    {/* <li>
                                        <span>
                                            <label className="plan-radio" > 7 Hours
                                            <input checked type="radio" name="radio8" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                        <strong>$10.99</strong>
                                    </li> */}
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Online Class
                                            <input checked type="radio" name="radio11" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Quran Revision
                                            <input checked type="radio" name="radio12" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Messages
                                            <input checked type="radio" name="radio13" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Upgrade Plan
                                            <input checked type="radio" name="radio14" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="planpricing-foot">
                                <a onClick={() => {
                                    if (isLogin) {
                                        history.push(`/${role}`)
                                    }
                                    else {
                                        //history.push(`/home`)
                                        setSiginUpModal(true)
                                    }
                                }}>
                                    <h3>$1.5/hr</h3>
                                </a>
                            </div>
                        </div>
                        <div className="planpricing">
                            <div className="planpricing-head">
                                <h3>ADVANCED</h3>
                            </div>
                            <div className="planpricing-body">
                                <ul>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Online Classes
                                            <input checked type="radio" name="radio21" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Quran Revision
                                            <input checked type="radio" name="radio22" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Messages
                                            <input checked type="radio" name="radio23" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            <label className="plan-radio" > Archives
                                            <input checked type="radio" name="radio24" />
                                                <em className="radioBtn"></em>
                                            </label>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="planpricing-foot">
                                <a onClick={() => {
                                    if (isLogin) {
                                        history.push(`/${role}`)
                                    }
                                    else {
                                        //history.push(`/home`)
                                        setSiginUpModal(true)
                                    }
                                }}>
                                    <h3>$2/hr</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="steps" align="center">
                        {/* <h3><span>Step 1</span> Sign Up And Complete Your Profile</h3>
                        <h3><span>Step 2</span> Find Tutor And Book A Slot</h3>
                        <h3><span>Step 3</span> Select Plan And Process Payment</h3>
                        <h3><span>Step 4</span> Start Learning By Joining The Class</h3> */}
                        {/* <h3 style="text-align: center;"> GET STARTED NOW! SIGNUP TODAY</h3> */}
                        {/* <h3 className="signUp"> GET STARTED NOW! SIGNUP TODAY</h3> */}
                    </div>



                </div>
            </section>
          <FourStep isLogin={isLogin} role={role} />                

        </>
    );
};

export default PlanAndPricing;
