/* eslint-disable */ import React, { useState, useEffect } from "react";
import { Table, Pagination, Space, Button, Tag, DatePicker, notification } from "antd";
import './subscription.css';
import { useSelector, useDispatch } from "react-redux";

import ExportExcel from '../../../shared/ExcelExport/exportExcel';
import { studentSubscription } from '../../../../globalConstant';
import { POST } from '../../../../apiClient';

const columns = [

  {
    title: "Tutor Name",


    render: (text, record) => (
      <Space size="middle">

        {text.teacherID.username}

      </Space>


    )
  },
  {
    title: "Sessions",    
    width: "20%",
    render: (text, record) => (
      <Space size="middle">

        {text.duration * 2}

      </Space>


    )
  },
  {
    title: "Amount",
    dataIndex: "amountCharged",
    width: "20%",
  },
  {
    title: "Status",


    render: (text, record) => (
      <Space size="middle">
        <Tag color={text.status ? 'green' : 'volcano'}>
          {text.status ? 'Paid' : 'Pending'}
        </Tag>
      </Space>


    )
  },

];

const Subscription = () => {

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [listing, setListing] = useState();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);



  const [pagination, setPagination] = useState(
    {
      current: 1,
      pageSize: 5,
      total: 25,
    }
  )

  useEffect(() => {
    getTransactions();

  }, [limit, offset]);

  const getTransactions = () => {
    dispatch({ type: "LOADING", payload: true });
    let params = {
      limit, offset
    }
    POST(studentSubscription, params).then(res => {
     

      if (res.status === 200) {
        setListing(res.data.data.transactions);
        setTotalRecords(res.data.data.totalCount);
        setPagination({ ...pagination, total: res.data.data.totalCount });
        const temp = [];
        res.data.data.transactions.forEach((val) => {
          temp.push(
            {
              TutorName: val.teacherID.username,
              Sessions: val.duration * 2,
              Amount: val.amountCharged,
              Status: (val.status) ? 'Paid' : 'Pending'
            }
          )
        })
        setData(temp)
      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
      }

      dispatch({ type: "LOADING", payload: false });
    })
  }
  const handleTableChange = (paginations, filters, sorter) => {
    
    setOffset((paginations.current * paginations.pageSize) - paginations.pageSize);
    setLimit(paginations.pageSize);
  };
  const onChange = (date, dateString) => {
  
  }
  const paginations = (page, pageSize) => {
    setOffset(page * pageSize - pageSize);
    setLimit(pageSize);
  };
  return (
    <div className="mx-3">
      <div className="py-3 float-right d-flex">
        <div className="mr-2"><ExportExcel data={data} /> </div>
        <DatePicker onChange={onChange} /></div>
      <Table

        columns={columns}
        rowKey={(record) => record.id}
        dataSource={listing}
        pagination={pagination}
        // loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Subscription;
