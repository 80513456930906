/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import './login.css';
import useForm from "./useForm";
import validate from "./validateLogin";
import LoaderUi from '../../../../UiLoader/Loader';
import { POST } from '../../../../apiClient';
import { adminLogin } from '../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import {  notification } from "antd";



const Login = ({ isAuthenticated }) => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { handleChange, handleSubmit, values, errors } = useForm(submit, validate);

    function submit() {
        
        setLoading(true);

        let params = {
            email: values.email,
            password: values.password
        }
        POST(adminLogin, params).then((res) => {

            if(res.status === 200){
                const {data} = res;      
                notification.success({ message: 'Success' });
                setLoading(false);
                
                dispatch({ type: 'LOGIN', payload: data });
           
            
        }
        if(res.status === 500){
            
            notification.error({ message: res.err.error.message });
            setLoading(false);

        }

        });


    }


    return (
        <>
            {isLoading ? <LoaderUi /> : null}
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100 p-l-85 p-r-85 p-t-55 p-b-55">
                        <form onSubmit={handleSubmit} noValidate className="login100-form validate-form flex-sb flex-w">
                            <span className="login100-form-title p-b-32">
                                Admin Login
        </span>
                            <span className="txt1 p-b-11">
                                Email
        </span>
                            <div className="wrap-input100 validate-input m-b-36" >

                                <input
                                    className={`input100 ${errors.email && "inputError"}`}
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />


                            </div>
                            {errors.email && <small className="error mb-1">{errors.email}</small>}
                            <div className="txt1 mt-2">
                                Password
        </div>
                            <div className="wrap-input100 validate-input mb-12" >


                                <input
                                    className={`input100 ${errors.email && "inputError"}`}
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                />

                            </div>
                            {errors.password && <small className="error mb-2 ">{errors.password}</small>}
                            <div className="mt-5 container-login100-form-btn">
                                <button type="submit" className="login100-form-btn">
                                    Login
          </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>



            
        </>
    );
}

export default Login;