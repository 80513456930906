/* eslint-disable */ 
import React ,{useState,useEffect} from 'react';
import './style.css';

import activeIcon from '../../assets/images/active-icon.png';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from 'antd';
const { Title } = Typography;
const CurrentStatus = ({name}) => {
    let userss = useSelector((state) => state.studentProfileReducer);
    // console.log(userss)
    const [user,setUser] = useState({});

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('userInfo'));
        // console.log(data)
        setUser(data)
    }, [])
    return (
        <>
            <div className="welcomesection">
                <h2>Welcome&nbsp;{name}&nbsp;To Nida Ul Quran!</h2>
                   <>
                    {!userss?.isProfileCompleted ? 
                        
                        
                    <Title level={4} type="danger">Please complete your Profile as its compulsory in order to book your classes and use the platform & its services.</Title>
                    :
                    null
                    }
                    </>
                {/* <ul>
                    <li>
                        <dl id="sample" className="dropdown">
                            <dt><a href="/"><span><img className="alert-icon" src={activeIcon} alt="icon" /> Online </span> </a> </dt>
                            {/* <dd>
                                <ul>
                                    <li><a href="#"><img className="alert-icon" src="images/active-icon.png" alt="" > Online</a></li>
                                    <li><a href="#"><img className="alert-icon" src="images/offline-icon.png" alt="" > Offline</li>
                                </ul>
                            </dd>  
                        </dl>
                    </li>
                    {/* <li className="pref">
                        <a href="#"><i></i> <em>Preferences</em></a>
                    </li>
                </ul> */}
                <br/>
            </div>
        </>
    )
}

export default CurrentStatus
