/* eslint-disable */ import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Modal, Popover, Space, Spin } from 'antd';
import { CheckCircleTwoTone, DeleteFilled, FieldTimeOutlined, FileTextOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//data
import data from './constants'
// style
import './style.css';
import dayjs from 'dayjs';
import { GET, POST } from '../../../../apiClient';

// Editable Context
const EditableContext = React.createContext();
// const start = new Date();
// const end = new Date(new Date().setMinutes(start.getMinutes() + 30)); 
var token = JSON.parse(localStorage.getItem('token'));

const Scheduler = () => {
  const getColumns = (date) => {
    let date1 = new Date(date.valueOf()) 
    let date2 = date;
    const columns = [
      {
        title: '',
        dataIndex: 'slot',
        width: '100px',
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 0)).getDate()}</span> Mon</span>,
        dataIndex: 'Monday',
        date_: date + 0,
        value: '1',
        render: (item, record) => data.length >= 1 ? (
          <><Popover placement="topLeft" content={<div><p><UserOutlined />
          {'Ahsan'}</p><p><FieldTimeOutlined />
          {'PKR'}</p><p><FileTextOutlined />
          {'Eng'}</p></div>}
          title="Student Details"><span className="tag-color-pending">Student</span></Popover></>):''
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 1)).getDate()}</span> Tue</span>,
        dataIndex: 'Tuesday',
        date_: date + 1,
        value: '2',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 2)).getDate()}</span> Wed</span>,
        dataIndex: 'Wednesday',
        date_: date + 2,
        value: '3',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 3)).getDate()}</span> Thu</span>,
        dataIndex: 'Thursday',
        date_: date + 3,
        value: '4',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 4)).getDate()}</span> Fri</span>,
        dataIndex: 'Friday',
        date_: date + 4,
        value: '5',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 5)).getDate()}</span> Sat</span>,
        dataIndex: 'Saturday',
        date_: date + 5,
        value: '6',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 6)).getDate()}</span> Sun</span>,
        dataIndex: 'Sunday',
        date_: date + 6,
        value: '0',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      }
    ];
    // setColumn(columns)
    return columns;
  };

  // Hammad Start
  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff)); 
  }

  const [iDate, setInitialDate] = useState(startOfWeek(new Date()));
  const [columns, setColumn] = useState(getColumns(startOfWeek(new Date())));
  const prevWeek = () => {
    let myDate = new Date(iDate);
    myDate.setDate(myDate.getDate() - 7);
 
    setInitialDate(myDate);
    let newCol = getColumns(myDate);
    setColumn(newCol);
  };

  const nextWeek = () => {
    let newDate = dayjs(iDate).add(7, "day").toDate();
  
    const colDate = newDate;
    setInitialDate(newDate);
    let newCol = getColumns(colDate);
    setColumn(newCol);
  };
  // Hammad end
  const [weekDate, setWeekDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState(data);
  const [record, setRecord] = useState({
    start: '',
    end: '',
    day: '',
    key: ''
  });

  let tempData = [...data];

  useEffect(() => {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
    getData();
  }, [iDate]);

  useEffect(() => {
    if (record.start !== '') {
     
      handleAdd(record.day, record.key);
    }
  }, [record]);

  const getData = async () => {
    var tempData = [...data];

    let d = new Date(iDate)
    d.setHours(0,0,0,0)
    d.toUTCString()

    let n = d.toISOString()
    var offSet = -(new Date().getTimezoneOffset() / 60)

    setLoading(true);
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));  
    GET(`get-appointment-by-id?studentID=${userInfo.loginId}&weekDate=${n}`)
      .then((res) => {
        let arr = [];
        res.data.data.forEach(curr => arr.push({
          schedule: curr.schedule,
          isBooked: curr.isBooked,
          student: curr.teacher[0]
        }));
        // let tempData = [...dataSource];
        if (arr.length === 0) {
          setDataSource([...tempData])
          setLoading(false)
          return
        }
        
        arr.forEach(item => {2
          //debugger

          var startHourTZ = Number(item.schedule.startHour)+Number(offSet)
          var startMinuteTZ = item.schedule.startMinute
  
          if (startHourTZ >= 24) {
            if (item.schedule.weekday==6) {
              item.schedule.weekday=0
            } else {
              item.schedule.weekday = item.schedule.weekday+1
            }
            startHourTZ= startHourTZ%24
          } else if (startHourTZ < 0) {
            if (item.schedule.weekday==0) {
              item.schedule.weekday=6
            } else {
              item.schedule.weekday= item.schedule.weekday-1
            }
            startHourTZ= 24+startHourTZ
          }

  
          let ind = tempData.findIndex((x) => {
            return x.startHour==String(startHourTZ) && x.startMinute==String(startMinuteTZ)
          })
          let obj = tempData.find((y) => {
            return y.startHour==String(startHourTZ) && y.startMinute==String(startMinuteTZ)
          })
          
  
          let day = ''
          switch (item.schedule.weekday) {
            case 0:
              day = 'Sunday'
              break;
            case 1:
              day = 'Monday'
              break;
            case 2:
              day = 'Tuesday'
              break;
            case 3:
              day = 'Wednesday'
              break;
            case 4:
              day = 'Thursday'
              break;
            case 5:
              day = 'Friday'
              break;
            case 6:
              day = 'Saturday'
              break;
            
            default:
              break;
          }
          
          if (item.isBooked) {
            item.text = 'Scheduled';
            delete obj[day]
            const updatedObj = Object.assign({[day]: item}, obj)
            tempData.splice(ind, 1, updatedObj)
            setDataSource([...tempData])
          } else {
            item.text = 'Pending';
            delete obj[day]
            const updatedObj = Object.assign({[day]: item}, obj)
            tempData.splice(ind, 1, updatedObj)
            setDataSource([...tempData])
          }
          setLoading(false)
        })   
      })
      .catch(err => console.log(err));
  };

  const handleDelete = (day, key, slot, val) => {
    const ind = data.findIndex((item) => {
      return item.key == key;
    });
    const obj = data.find(item => {
      return item.key == key && item.hasOwnProperty(day);
    });

    const dataBody = {
      calID: Number(key),
      weekday: Number(val)
    };
    if (obj) {
      POST('tutor/delete-schedule', dataBody)
        .then(res => {
         
          // if(obj) {
          delete obj[day];

          // const updatedObj = Object.assign({[day]: 'Available'}, obj)
          data.splice(ind, 1, obj);
          setDataSource([...data, data]);

        })
        .catch(err => console.log(err));
    }

  
  };

  const handleAdd = (day, key) => {
  
    // getDayandDate(value, slot)


    const ind = data.findIndex((item) => {
      return item.key == key;
    });
    const obj = data.find((item) => {
      return item.key == key;
    });

    const updatedObj = Object.assign({ [day]: 'Available' }, obj);
    data.splice(ind, 1, updatedObj);
   

    const dataBody = {
      calID: Number(updatedObj.key),
      startDate: new Date(record.start),
      endDate: new Date(record.end)
    };

    POST('tutor/create-schedule', dataBody)
      .then(res => {
     
        setDataSource([...data, data]);
      })
      .catch(err => console.log(err));

    // setDataSource([...data, data])     
  };

  const handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource({
      dataSource: newData,
    });
  };

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const convertMinute12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${minutes}`;
  };

  const getDayandDate = (day, slot) => {
    let d = new Date(day).getDate();
    let m = new Date(day).getMonth();
    let y = new Date(day).getFullYear();

    const tempHourStart = convertTime12to24(slot.split('-')[0]);
    const tempMinuteStart = convertMinute12to24(slot.split('-')[0]);

    const tempHourEnd = convertTime12to24(slot.split('-')[1]);
    const tempMinuteEnd = convertMinute12to24(slot.split('-')[1]);
    var now = new Date();
    const start = new Date(`${m + 1}-${d}-${y} ${tempHourStart}:${tempMinuteStart}`);
    const end = new Date(`${m + 1}-${d}-${y} ${tempHourEnd}:${tempMinuteEnd}`);


    // if (start < now) { 
    //   start.setDate(start.getDate() + 7)
    // }
    // if (end < now)
    //   end.setDate(end.getDate() + 7)

    if (record.day == '') {
      setObjRecord(prevState => ({
        ...prevState,
        start,
        end
      }));
    
    } else {
      setRecord(prevState => ({
        ...prevState,
        start,
        end
      }));
    
    }
  };

  const { confirm } = Modal;

  const mapcolumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        onClick: () => {
          if (col.dataIndex === 'slot') {
            return
          }
        }
      }),
    };
  });

  var options = { month: 'long' };
  return (
    <section id="main-content">
      <div className="container-fluid">
        <div className="calendar-page">
          <div className="DateT">
            <button type="button" className="ant-btn previousBtn" onClick={prevWeek}><span>Previous</span></button>
            <button type="button" className="ant-btn nextBtn" onClick={nextWeek}><span>Next</span></button>
            <h4 className="float-right my-3">{new Intl.DateTimeFormat('en-US', options).format(iDate)} {iDate.getFullYear()}</h4>
          </div>
          {loading ?
            <Space size="middle">
              <Spin
                size="large"
              />
              <Table
                columns={mapcolumns}
                dataSource={data}
                pagination={false}
                scroll={{ y: 375 }}
                bordered
                className="availability-calendar"
              />
            </Space>
            :
            <Table
              columns={mapcolumns}
              dataSource={dataSource}
              pagination={false}
              scroll={{ y: 375 }}
              bordered
              className="availability-calendar"
            />
          }
          {/* <div className="btn-row"> <Button className="float-right primary-btn" onClick={showConfirm}>Save</Button></div> */}
        </div>
      </div>
    </section>
  );
};

export default Scheduler;