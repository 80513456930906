/* eslint-disable */ 
import React, { useState, useEffect } from 'react';

import { Tabs,Row,Col } from 'antd';
import All from './copmponents/all';
import Pending from './copmponents/pending';
import Blocked from './copmponents/blocked';





const { TabPane } = Tabs;


const Students = () => {

    

    return (
        <div>
             <Row className="m-0" gutter={[48, 32]}>
                    <Col span={24} >
        <div className="card-container">
            
            <Tabs type="card" className="custom-tabnav"  destroyInactiveTabPane="true" >
                <TabPane tab="All" key="1" >
                    <All />
                </TabPane>
                <TabPane tab="Pending" key="2">
                    <Pending />
                </TabPane>
                <TabPane tab="Blocked" key="3" >
                    <Blocked />
                </TabPane>
            </Tabs>
        </div>
        </Col>
            </Row>
    </div>
    );
}

export default Students;