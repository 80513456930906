/* eslint-disable */ 
import React, { useState } from 'react'
import './style.css';
import {
  BrowserRouter as Router,
  Switch, Redirect,
  Route,
  useRouteMatch
} from "react-router-dom";

import AdminLayout from '../../components/Layout';
// import StudentProfile from '../../../shared/profile'
// import Archive from '../../components/archive';
// import ParentalWatch from '../../components/parentalWatch';
// import FourOfour from '../../../../404';
import Landing from '../../components/Landing/Landing';
// import QuranRevision from '../../../shared/quranRevision';
// import Scheduler from '../../../student/components/scheduler/Scheduler';
// import Chat from '../../../shared/chat';
import Students from '../../components/Students/students';
import Tutors from '../../components/Tutors/tutors';
import Billing from '../../components/Billing/billing';
import Detail from '../../components/Tutors/copmponents/detail';
import StudentDetail from '../../components/Students/copmponents/studentdetail';
import Login from '../../components/Login/login';
import { useSelector, useDispatch } from "react-redux";
import LoaderUi from '../../../../UiLoader/Loader';
import { Row, Col, Card, Button } from 'antd';
import Payout from '../../components/Payouts/payout';
import Trials from '../../components/Trials/trials';
import Configuration from '../../components/Configuration/configuration';


const AdminDashboard = () => {
  let { path } = useRouteMatch();
  const adminData = useSelector((state) => state.adminReducer);

  const FourofFour = () => (
    <div>
      Not Found
    </div>
  )

  return (
    <div>
      {adminData.isAuthenticated ?
        <AdminLayout>
          <div>{adminData.loading ? <LoaderUi /> : null}</div>
          <Row className="m-0" gutter={[48, 32]}>
          <Col span={24}>
          
            <h2>Welcome To Admin Dashboard</h2>
          </Col>
        </Row>

          <div className="dashboardWrapper mx-3">

            <Switch>
              {adminData.isAuthenticated ?
                (<Route path={`${path}`} exact>
                  <Redirect to={`${path}/tutors`} />
                </Route>) : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/tutors`} component={Tutors} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/tutors/:id`} component={Detail} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/students`} component={Students} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/students/:id`} component={StudentDetail} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/billing`} component={Billing} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/payouts`} component={Payout} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/trials`} component={Trials} /> : <Redirect to='/admin' />}
              {adminData.isAuthenticated ? <Route exact path={`${path}/configuration`} component={Configuration} /> : <Redirect to='/admin' />}

              <Route component={FourofFour} />
            </Switch>
          </div>
        </AdminLayout>
        :

        <div>
          <Route path='/admin' component={Login}></Route>
          <Redirect to='/admin' /></div>
      }
    </div>
  )
}

export default AdminDashboard
