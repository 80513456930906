import axios from 'axios';

// import store from '../src/index'; // for error handling for api response
// import { ToastContainer, toast, Slide } from 'react-toastify';

const baseUrl = 'https://api.nidaulquran.com'; //prod
// const baseUrl = 'http://54.186.118.166:5500';  //Staging
// const baseUrl = 'http://localhost:5500'; //local


// GEt Method for get request
axios.defaults.withCredentials = true;
const GET = async (url) => {

    const response = await axios.get(`${baseUrl}/${url}`, { withCredentials: true })
    .catch(function (error) {
        if (error.response) {
        return {
            err :error.response.data,
            status:error.response.status
        };

        }
    });

    return response;


};

// POST Method for post request
const POST = async (url, params) => {


    const response = await axios.post(`${baseUrl}/${url}`, params, { withCredentials: true })
    .catch(function (error) {
        if (error.response) {      
        return {
            err :error.response.data,
            status:error.response.status
        };

        }
    });

    return response;
};
// PUT Method for put request
const PUT = async (url, params) => {

    const response = await axios.put(`${baseUrl}/${url}`, params, { withCredentials: true })
    .catch(function (error) {
        if (error.response) {
        return {
            err :error.response.data,
            status:error.response.status
        };

        }
    });

    return response;
};
// Delete Method for delete request
const DELETE = async (url, param) => {
   

    const response = await axios.delete(`${baseUrl}/${url}`, {
        withCredentials: true,
        data: { cardId: param.cardId }
    });
    
    return response;
};
// POST Method for post request
const PICTURE = async (url, imageFile) => {

    let bodyFormData = new FormData();
    bodyFormData.append('image', imageFile.image);

    const response = await axios({
        method: 'post',
        url: `${baseUrl}/${url}`,
        withCredentials: true,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response;

};

const VIDEO = async (url, imageFile) => {

    let bodyFormData = new FormData();
    bodyFormData.append('video', imageFile.image);

    const response = await axios({
        method: 'post',
        url: `${baseUrl}/${url}`,
        withCredentials: true,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response;

};

// const logOut = () => {
//     // toast.success('Logout Successfully')
//     store.dispatch({ type: 'logout' });
// }


export { baseUrl, GET, POST, PUT, DELETE, PICTURE, VIDEO };
