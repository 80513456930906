const initialState = {
  data: {},
  isAuthenticated : JSON.parse(localStorage.getItem('isAuthenticated')) ||  false,
  loading:false
};

const adminReducer = (state = initialState, {type,payload}) => {

  switch (type) {
    case 'LOGIN':
      localStorage.setItem('isAuthenticated',true)
      return {
        ...state,
        data: payload.data.data,
        isAuthenticated :  true
      };

    case 'LOADING':
       
        return {...state,loading:payload}  ;

    case 'LOGOUT':
      localStorage.setItem('isAuthenticated',false)
        return {...state, isAuthenticated : false}  ;


      default:
      return state;
  }
};

export default adminReducer;