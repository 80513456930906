/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './trials.css';
import {
    Table, Pagination, Space, Button, DatePicker,
    Modal, Tag, Select, notification
} from "antd";
import {
    NavLink,
    useRouteMatch,
    useHistory
} from "react-router-dom";

import footerIcon from '../../../../assets/images/sidebar-foot.png';
import { LogoutOutlined } from '@ant-design/icons';
import DefaultAvatar from '../../../../assets/images/defaultAvatar.jpeg';
import { GET, POST } from '../../../../apiClient';
import { freeTrials, tutorListing, freeTrialsApproved } from '../../../../globalConstant';
import moment from 'moment';


const { Option } = Select;

const Trials = () => {
    const columns = [
        {
            title: "Student Name",
            dataIndex: "studentName",

            key: "studentName",
            render: (text, record) => (
                <Space size="middle" >
                    {text}
                </Space>


            )
        },
        {
            title: "Subject",
            dataIndex: "subject",

            key: "subject",
            render: (text, record) => (
                <Space size="middle" >
                    {text}
                </Space>


            )
        },
        {
            title: "Schedule Start Time",
            dataIndex: "scheduleStartTime",
            key: "scheduleStartTime",
            render: (text, record) => (
                <Space size="middle" >
                    {moment(text).format('MMM DD, YYYY | hh:mm A')}
                </Space>


            )
        },
        {
            title: "Schedule End Time",
            dataIndex: "scheduleEndTime",

            key: "scheduleEndTime",
            render: (text, record) => (
                <Space size="middle" >
                    {moment(text).format('MMM DD, YYYY | hh:mm A')}
                </Space>


            )
        },
        {
            title: "Student TimeZone",
            dataIndex: "timeZone",

            key: "timeZone",
            render: (text, record) => (
                <Space size="middle" >
                    {text}
                </Space>
            )
        },
        {
            title: "Tutor Assigned",
            dataIndex: "tutorName",

            key: "tutorName",
            render: (text, record) => (
                <Space size="middle" >
                    {text ? text : 'N/A'}
                </Space>
            )
        },
        {
            title: "Status",


            render: (text, record) => (
                <Space size="middle" >
                    {text.isAdminApproved ? <Tag color='green' >
                        Approved
                </Tag> : <Tag color="volcano">Pending</Tag>}
                </Space>


            )
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {text.isAdminApproved ?

                        <Button type="primary" danger disabled={true}>Approved</Button>
                        : <Button type="primary" danger onClick={() => showModal(text, record)}>Assign</Button>
                    }

                </Space>
            ),
        },

    ];
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [listing, setListing] = useState();
    const [pagination, setPagination] = useState(
        {
            current: 1,
            pageSize: 10,
            total: 0,
        }
    )
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [record, setRecord] = useState({});
    const [tutors, setTutors] = useState([]);
    const [selectedTutorId, setSelectedTutorId] = useState('');
    const [isAdminApproved, setIsAdminApproved] = useState('');


    useEffect(() => {

        getFreeTrialsListing();
        getTutorListing();

    }, [limit, offset, isAdminApproved]);

    const getTutorListing = () => {
        GET(
            `${tutorListing}?status=4`
        ).then((res) => {
            if (res.status === 200) {
                const { data } = res;

                setTutors(data.data.data.tutorsList);

            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
            }

        });
    }
    const getFreeTrialsListing = () => {
        dispatch({ type: "LOADING", payload: true });
        let params = {
            limit: limit,
            offset: offset,
            isAdminApproved

        }


        POST(freeTrials, params).then(res => {
            console.log(res);
            dispatch({ type: "LOADING", payload: true });
            if (res.status === 200) {

                setListing(res.data.data.freeTrials);
                setPagination({ ...pagination, total: res.data.data.totalCount });
                dispatch({ type: "LOADING", payload: false });

            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: "LOADING", payload: false });
            }


        })
    }
    const handleTableChange = (paginations, filters, sorter) => {

        setOffset((paginations.current * paginations.pageSize) - paginations.pageSize);
        setLimit(paginations.pageSize);
        setPagination({ ...pagination, current: paginations.current });
    };
    const showModal = (val, rec) => {

        setRecord(val);
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    }
    const handleOk = () => {
        console.log("pay", record, selectedTutorId);
        if (selectedTutorId == '') return notification.error({ message: 'Select Tutor' });
        dispatch({ type: "LOADING", payload: true });

        let params = {
            tutorId: selectedTutorId,
            freeTrialId: record._id,

        }

        console.log(params);

        POST(freeTrialsApproved, params).then(res => {
            console.log(res);

            if (res.status === 200) {
                handleCancel();
                dispatch({ type: "LOADING", payload: false });

                notification.success({ message: 'Successfull' });
                getFreeTrialsListing();


            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: "LOADING", payload: false });
            }

        })
    }

    function onChange(value) {
        console.log(`selected ${value}`);
        setSelectedTutorId(value);
    }
    function handleChange(value) {
        if (value == "true") {
            setIsAdminApproved(true);
        }
        else {
            setIsAdminApproved(value)
        }

    }



    return (
        <div>
            <Modal
                title="Confirm"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p className="text-danger">Select Tutor to Assign</p>
                <Select
                    showSearch
                    style={{ width: 480 }}
                    placeholder="Select a tutor"
                    optionFilterProp="children"
                    onChange={onChange}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {tutors && tutors.map((val, index) => (

                        <Option key={index} value={val._id} >

                            <div>{val.username} </div>
                            <div>{val.email}</div>
                        </Option>
                    ))
                    }

                </Select>
            </Modal>

            <div className="py-3 float-right d-flex">

                <div className="mx-2">
                    <Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
                        <Option value="">All</Option>
                        <Option value="true">Approved</Option>
                    </Select></div>


            </div>

            <Table

                columns={columns}
                rowKey={(record) => record._id}
                dataSource={listing}
                pagination={pagination}
                // loading={loading}
                onChange={handleTableChange}
            />
        </div>
    )
}
export default Trials;