import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    notification } from 'antd';

import './callTemplate.css';
import { GET, POST, PUT } from '../apiClient';


const CallTemplate = () => {
    const params = new URLSearchParams(window.location.search);

    console.log(params.get("callUrl"));
    // const callUrl = params.get("callUrl");
    const id = params.get("id");
    const loginId = params.get("loginId");
    const cid = params.get("cid");
    const type = params.get("type");
    console.log(cid);
    const CALL_URL = "https://video.nidaulquran.com/";

    useEffect(() => {
        
        const encodedData = window.btoa(cid); // encode a string
        
       


        let param ={
            cid: cid,
            loginId,
            type:+type
        } 
        POST(`call`,param).then(res=>{
           
            if(res.status == 200){
                window.open(`${CALL_URL}${encodedData}`, "_blank");                
            }

            if(res.status ==409){
                
                notification.error({message:res.err.message});
            }
         })

        return () => {            
        }
    }, []);


    return (
        <div className="call-page">
      
        </div>
    );
}

export default CallTemplate;