import React, { useState } from 'react';
import { Modal, Button, Input, Rate, Card, Divider, notification } from 'antd';
import './rating.css'
import Logo from '../assets/images/logo.png';
import { POST } from '../apiClient';
import { useHistory } from 'react-router-dom';


const { TextArea } = Input;
const Rating = () => {
    const desc = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];
    const [value, setValue] = useState(5);
    const [btndisable, setBtndisable] = useState(true);
    const [review, setReview] = useState('');
    const params = new URLSearchParams(window.location.search);
    const loginId = params.get("loginId");
    const call = params.get("cid");
    console.log(call, loginId);

    const history = useHistory();


    const handleChange = value => {
        setValue(value);
    };
    const submit = () => {
        
        history.push('/');
        let param = {
            cid: call,
            loginId,
            rate: value,
            review
        }

        POST('student/class/review', param).then(res => {
      
            if (res.status == 200) {

                notification.success({ message: 'Thanks for your feedback' })
                setBtndisable(false);
            }

            if (res.status == 409) {

                notification.error({ message: res.err.message });
            }
        })
    }

    return (
        <div className="rating-screen">
            <div className="site-card-border-less-wrapper">
                <Card bordered={false} style={{ fontWeight: 'bold', width: 500, textAlign: 'center' }}>
                    <img src={Logo} width="150" />
                    <Divider />
                    <h2 >How's your experience ?</h2>
                    <div>
                        <span>
                            <div> <Rate tooltips={desc} onChange={handleChange} value={value} /></div>
                            {value ? <div className="ant-rate-text">{desc[value - 1]}</div> : ''}
                        </span>
                    </div>
                    <br />
                    <div >
                        <TextArea placeholder="Any Comments" rows={5} value={review} onChange={(e) => setReview(e.target.value)} />,
                    </div>

                    {btndisable ?
                        <>
                            <Divider />

                            <div>
                                <button onClick={submit} className="rating-submit">Submit</button>
                            </div>
                        </>
                        : null}
                </Card>
            </div>,
        </div>
    );
}

export default Rating;