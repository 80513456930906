const adminLogin = 'admin/login';
const adminLogout ='admin/logout';
const tutorLogout ='tutor/logout';
const studentLogout ='student/logout';
const studentListing ='admin/students/list';
const tutorListing ='admin/tutor/list';
const tutorDetail ='admin/tutor';
const studentDetail ='admin/student';
const audioUpload='upload/signUpAudio';
const videoUpload='upload/signUpVideos';
const approveTeacher = 'admin/approve/teacher';
const blockUnblockTeacher = 'admin/block/unblock/tutor';
const blockUnblockStudent = 'admin/block/unblock/student';
const approveStudent = 'admin/approve/student';
const studentTutorDetail = 'student/tutor';
const studentSubscription ='student/transactions';
const tutorPayouts ='tutor/payouts';
const adminTutorPayouts = 'admin/tutors/payouts';
const adminPay='admin/tutors/payout/paid';
const tutorSubscription='tutor/subscriptions';
const allCertificates = 'tutor/certificates';
const certificateUpload = 'tutor/upload/certificate';
const certificateUpdate= 'tutor/certificates/update';
const cashInListing = 'admin/cashIn/listing';
const freeTrialVersion='student/freetrial/create';
const freeTrials='admin/freeTrial/listing';
const freeTrialsApproved='admin/freeTrial/approved';
const adminConfig='admin/configurations';
const configuration='student/system/configurations';

export {
    adminLogin,
    adminLogout,
    studentListing,
    tutorListing,
    tutorDetail,
    studentDetail,
    tutorLogout,audioUpload,
    videoUpload,
    approveTeacher,
    blockUnblockTeacher,
    approveStudent,
    blockUnblockStudent,
    studentLogout,
    studentTutorDetail,
    studentSubscription,
    tutorPayouts,
    adminTutorPayouts,
    adminPay,
    tutorSubscription,
    certificateUpload,
    certificateUpdate,
    allCertificates,
    cashInListing,
    freeTrialVersion,
    freeTrials,
    freeTrialsApproved,
    adminConfig,
    configuration

}