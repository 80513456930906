/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button,Pagination } from 'antd';
import DefaultAvatar from '../../../../../assets/images/defaultAvatar.jpeg';
import { studentListing, blockUnblockStudent, approveStudent } from '../../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GET, POST } from '../../../../../apiClient';
import Empty from '../../../../../assets/images/undraw_empty_xct9.svg';
import '../students.css';
import { useDebounce } from 'react-use';



const All = (props) => {
   
    const dispatch = useDispatch();
    const history = useHistory();
    const [students, setStudents] = useState([])
    const [searchText, setSearchText] = useState('');
    const [val, setVal] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalRecords, setTotalRecords] = useState(1);
    const pageSizeOptions =[10,20,30];


    useEffect(() => {

        getStudents();
        return () => {
        }
    }, [searchText,limit,offset])

    const [, cancel] = useDebounce(
        () => {

            setSearchText(val);
        },
        500,
        [val]
    );
    const getStudents = () => {
        dispatch({ type: 'LOADING', payload: true });

        GET(`${studentListing}?status=1&searchText=${searchText}&limit=${limit}&offset=${offset}`).then(res => {
            const { data } = res;

            if (data.data.success == 1) {

                setStudents(data.data.data.studentsList);
                setTotalRecords(data.data.data.count);
            }
            else {
                notification.error({ message: 'Error' });
            }
            dispatch({ type: 'LOADING', payload: false });
        });
    }

    const approve = (id) => {

        dispatch({ type: 'LOADING', payload: true });

        GET(`${approveStudent}/${id}`).then(res => {
           
            if (res.status === 200) {
                dispatch({ type: 'LOADING', payload: false });
                notification.success({ message: res.data.data.message });
                getStudents();
            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: 'LOADING', payload: false });

            }
        });

    }
    const statusChange = (id, status) => {


        dispatch({ type: 'LOADING', payload: true });
        let params = {
            studentId: id,
            isBlocked: status
        }
        POST(`${blockUnblockStudent}`, params).then(res => {
          
            if (res.status === 200) {
                dispatch({ type: 'LOADING', payload: false });
                notification.success({ message: res.data.data.message });
                getStudents();

            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: 'LOADING', payload: false });

            }
        });

    }
    const pagination = (page, pageSize) => {
        setOffset(page * pageSize - pageSize);
        setLimit(pageSize);
      };


    const deleteStudent = async (stId) => {
        if (confirm('Are you sure you want to delete?')) {
            localStorage.setItem(`hideStudent${stId}`, stId);
            window.location.reload();
        }
    };

    return (
        <div>
        <div className="listing">

            <div className="search-div">

                <input
                    type="text"
                    value={val}
                    placeholder="Search"
                    onChange={({ currentTarget }) => {
                        setVal(currentTarget.value);
                    }}
                />    

            </div>
            

            { students.length ?
                <Row className="m-0" gutter={[48, 32]}>

                    {students && students.map((val, ind) => (
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} key={ind} style={{display : (localStorage.getItem(`hideStudent${val._id}`) === val._id) ? 'none' : 'block'}}>
                            <Card bordered={false} hoverable="true" >
                                <div className="d-flex ">
                                    <div className="mr-4"><img src={val.profileImage || DefaultAvatar} className="avatar" /> </div>
                                    <div className="content flex-grow-1">

                                        <div className="d-flex justify-content-between">
                                            <div className="text-capitalize">{val?.firstName} {val?.lastName} {!val.firstName ? val.username : null} </div>

                                            {val?.isBlocked ? <div className="blocked">Blocked</div> :
                                                <div className={val?.isConfirm ? 'approve' :'not-approve'}>
                                                    {val?.isConfirm ? "Approved" : "Not Approved"}
                                                </div>
                                            }
                                        </div>

                                        {/* <small>Interest: <strong className='text-capitalize'>{val.skills.join(',')}</strong></small>
                                <small>Spoken Languages: <strong className='text-capitalize'>{val.languages.join(',')}</strong></small> */}
                                        <small>Email: <strong>{val?.email}</strong></small>
                                        {val?.gender && <small>Gender: <strong>{val?.gender}</strong></small>}
                                    </div>

                                </div>
                                <Row className="m-0 end-btns" justify="end">
                                    {!val?.isConfirm ? <Button type="primary" danger onClick={() => approve(val._id)}> Approve</Button> : null}
                                    {!val?.isBlocked ? <Button danger onClick={() => statusChange(val._id, true)}>Block</Button>
                                        :
                                        <Button danger onClick={() => statusChange(val._id, false)}>Un-Block</Button>
                                    }
                                    <Button className="edit-btn" onClick={() => history.push(`${history.location.pathname}/${val._id}`)}>Details</Button>
                                    <button style={{ marginLeft: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}
                                            onClick={() => deleteStudent(val._id)}>Delete</button>
                                </Row>

                            </Card>
                        </Col>
                    ))}

                </Row>
                :

                <div className="illustrator">
                    <div style={{ marginTop: '6rem', textAlign: 'center' }}>
                        <img src={Empty} height="400px" />
                        <h2 className="text-center">Not Found</h2>
                    </div>
                </div>
            }
            </div>
              {students.length ? (
      <div className="d-flex justify-content-center">
        <Pagination
          total={totalRecords}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`          }
            defaultPageSize={10}
          defaultCurrent={1}
          pageSizeOptions={pageSizeOptions}
          onChange={(page, pageSize) => pagination(page, pageSize)}
        />
      </div>
      ):null}
        </div>
        
    );
}

export default All;