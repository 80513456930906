/* eslint-disable */ import React, { useEffect, useState } from 'react';
import './style.css';
import { useFormik } from "formik";
import { message, Select, Radio, Progress, Upload, Row, Col, Modal, notification } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { studentProfileAction } from '../../../../store/Actions'
import * as types from '../../../../store/Actions/Types'
import axiosInstance from "../../../../axios";
import LoaderUi from '../../../../UiLoader/Loader'
import { countries } from '../../../../shared/countriesData/CountriesData';
import { timezone } from '../../../../shared/timeZoneData/TimeZone';
import { GET, POST, PUT, baseUrl } from '../../../../apiClient';
import { audioUpload, videoUpload, allCertificates, certificateUpdate, certificateUpload } from '../../../../globalConstant';
import axios from 'axios';




function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
const TutorProfile = () => {
    const photo = "https://emeraldpsychiatry.com/wp-content/uploads/2018/05/dummy_players.png";
    const dispatch = useDispatch();
    
    let userProfilePhoto = useSelector(
        (state) => state.studentProfileReducer.photo
      );
    const [isUserId, setUserID] = useState(null);
    const [isUserRole, setUserRole] = useState('');
    const [isUserName, setUserName] = useState('');
    const [isProfileId, setProfileId] = useState(null);
    const [isProfileImageId, setProfileImageId] = useState(false);
    const [isPasswordChnageModal, setPasswordChnageModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isCountry, setCountry] = useState(null);
    const [isTimeZone, setIsTimeZone] = useState(null);
    const [islanguages, setlanguages] = useState(["English", "Urdu", "Arabic", "Persian", "French"]);
    const [isUpdatelanguages, setUpdatelanguages] = useState([]);
    const [isFiqah, setFiqah] = useState('');
    const [isSect, setSect] = useState('');
    const [isSubjects, setSubjects] = useState([]);
    const [userProfileData, setUserProfileData] = useState('');
    const [isUserProfileInfo, setUserProfileInfo] = useState({});
    const [isUserEmail, setUserEmail] = useState('');
    const [certificates, setCertificates] = useState([])
    const [level, setLevel] = useState(1);
    const [compSkill, setCompSkill] = useState(1);


    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const subjectsCheckboxHandler = (e) => {
        let value = e.target.value;
        let checked = e.target.checked;
        if ((checked && value)) {
            setSubjects(prevState => [...prevState, value])
        } else {
            let update = isSubjects.filter(sub => sub !== value);
            setSubjects(update);
        }
    }


    const languagesChangeHandler = (value) => {
        setUpdatelanguages(value)
        formik.values.languages = value;
    }
    const countryChangeHandler = (value) => {
        setCountry(value)
        formik.values.country = value;
    }
    const timeZoneChangeHandler = (value) => {
        setIsTimeZone(value)
        formik.values.timeZone = value;
    }
    const passwordChangeModalHandler = () => {
        setPasswordChnageModal(!isPasswordChnageModal)
    }
    // const fiqahChangeHandler = (value) => {      
    //     const fiqah = value.target.value;
    //     setFiqah(fiqah)
    //     formik.values.fiqh = fiqah;
    // }
    const sectChangeHandler = (value) => {
       
        const sect = value.target.value;
        setSect(sect)
        formik.values.sect = sect;
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        cellNumber: '',
        country: "",
        province: "",
        languages: [],
        timeZone: "",
        city: "",
        subjects: [],
        profileImage: "",
        about: "",
        userId: '',
        // address: "",
        hourlyRate: 0,
        // fiqh: "",
        sect: "Sunni",
        payoneerId: ""
    }

    // form on submit handler
    const onSubmit = async (values) => {

        setLoading(true)
        Object.keys(values).forEach((key) => (values[key] == '') && delete values[key]);
        const studentData = {
            ...values,
            userId: isUserId,
            subjects: isSubjects,
            videoLink1: video.one,
            videoLink2: video.two,
            videoLink3: video.three,
            audioLink1: audio.one,
            audioLink2: audio.two,
            audioLink3: audio.three,
            englishProficiency: level,
            computerSkills: compSkill,

        }
     
        if (isProfileId) {
            try {
                const userProfileUpdate = await PUT(
                    `${isUserRole}/profile/${isUserId}`,
                    studentData
                );
              
                if (userProfileUpdate.status === 200) {
                    const userInfo = userProfileUpdate.data.data;
               

                    let userInfodata = localStorage.getItem("userInfo");
                    userInfodata = JSON.parse(userInfodata);
                    userInfodata.isProfileCompleted = true;
                   
                  
                    localStorage.setItem("userInfo",JSON.stringify(userInfodata));
          
                   
                    
                    
                    const { firstName, lastName } = userInfo.profile;
                    firstName && dispatch(studentProfileAction('UPDATE_FNAME', firstName));
                    lastName && dispatch(studentProfileAction('UPDATE_LNAME', lastName));
                    dispatch(studentProfileAction('UPDATE_PROFILE', true));
                    let userStoredInfo = localStorage.getItem('userInfo')
                    userStoredInfo = JSON.parse(userStoredInfo);
                    userStoredInfo = { ...userStoredInfo, firstName, lastName }
                    localStorage.setItem('userInfo', JSON.stringify(userStoredInfo))
                    message.loading("Updating your profile...")
                        .then(() => {
                            message.success("Your Profile has been updated successfully ");
                            setLoading(false)
                        })
                }
            } catch (error) {
                message.error("something went wrong");
                setLoading(false)
            }
        } else {

            try {
                const userProfileUpdate = await POST(
                    `tutor/profile`,
                    studentData
                );
               
                if (userProfileUpdate.status === 200) {
                    const userInfo = userProfileUpdate.data.data;
                   
                    message.loading("Updating your profile...")
                        .then(() => {
                            message.success("Your Profile has been updated successfully ");
                            setLoading(false)
                        })

                }
            } catch (error) {
                message.error("something went wrong" + error.message);
                setLoading(false)
            }
        }
    }
    useEffect(() => {
  
       
        if(isUserProfileInfo){
       
              formik.values.firstName=   isUserProfileInfo.firstName || '';
              formik.values.lastName= isUserProfileInfo.lastName ||'';
              formik.values.dob=  isUserProfileInfo.dob ||'';
              formik.values.gender= isUserProfileInfo.gender ||'';
              formik.values.cellNumber= isUserProfileInfo.cellNumber ||'';
              formik.values.country = isUserProfileInfo.country ||'';
              formik.values.province = isUserProfileInfo.province ||'';
              formik.values.languages = isUserProfileInfo.languages ||'';
              formik.values.timeZone = isUserProfileInfo.timeZone ||'';
              formik.values.city = isUserProfileInfo.city ||'';
              formik.values.subjects = isUserProfileInfo.subjects ||'';
              formik.values.about = isUserProfileInfo.about ||'';
              formik.values.hourlyRate =isUserProfileInfo.hourlyRate || 0;
              formik.values.payoneerId =isUserProfileInfo.payoneerId || "";
             //  formik.valuesfiqh =isUserProfileInfo.fiqh ||'';
             //  formik.valuessect = isUserProfileInfo.sect ||'';
         
        }
       }, [isUserProfileInfo])
    const validate = (values) => {
       
        let errors = {};

        // if (!values.email) {
        //   errors.email = "Email Required";
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        // ) {
        //   errors.email = "Invalid email format";
        // }

        if (!values.firstName) {
            errors.firstName = "First Name is required";
        }
        if (!values.lastName) {
            errors.lastName = "Last Name is required";
        }
        if (!values.dob) {
            errors.dob = "Date of birth is required";
        }
        if (!values.gender) {
            errors.gender = "Gender is required";
        }
        if (!values.cellNumber) {
            errors.cellNumber = "Cell Number is required";
        }
        if (!values.country) {
            errors.country = "country is required";
        }
        if (!values.province) {
            errors.province = "province is required";
        }
        if (!isSubjects.length > 0) {
            errors.subjects = "Subjects is required";
        }
        if (!values.payoneerId) {
            errors.payoneerId = "payoneer id is required";
        }
        if (!values.languages.length) {
            errors.languages = "languages is required";
        }
        if (!values.timeZone) {
            errors.timeZone = "timeZone is required";
        }
        if (!values.city) {
            errors.city = "city is required";
        }
        
        if (!values.about) {
            errors.about = "about is required";
        }

        if (!values.hourlyRate) {
          errors.hourlyRate = "hourlyRate is required";
        }
        // if (!values.fiqh) {
        //   errors.fiqh = "fiqh is required";
        // }
        // if (!values.sect) {
        //   errors.sect = "sect is required";
        // }
        return errors;
    };
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    });

    let studentChangePassword = {
        password: '',
        newPassword: '',
    }

    const studentChangePassword_OnSubmit = async (values, { resetForm }) => {
        try {
            setLoading(true);
            resetForm({});
            const userProfileUpdate = PUT(
                `${isUserRole}/changepassword/${isUserId}`,
                values
            );
            if (userProfileUpdate.status === 200) {

                message.loading("Updating your Password")
                    .then(() => {
                        message.success("Your password updated successfully ");
                        setLoading(false);
                        setPasswordChnageModal(false);
                    })
            }
        } catch (error) {
            message.error("something went wrong");
            setLoading(false);
        }
    }

    const studentChangePasswordFormik = useFormik({
        initialValues: studentChangePassword,
        onSubmit: studentChangePassword_OnSubmit,
    });

    const updateProfilePhoto = async (e) => {
        let file = e.target.files[0];
        const photo = window.URL.createObjectURL(file);
        dispatch(studentProfileAction(types.UPDATE_PHOTO, photo));
        let formData = new FormData();
        formData.append('profileImage', file, file.name);
        formData.append('userRole', isUserRole);
        formData.append("userId", isUserId);

       
        if (isProfileImageId) {
            try {
                const userProfileUpdate = await axiosInstance(
                    {
                        method: 'put',
                        url: `user/profile/image/${isUserId}`,
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                );
                
                if (userProfileUpdate.status === 200) {
                    const { profileImage } = userProfileUpdate.data.data[0];
                    dispatch(studentProfileAction('UPDATE_PHOTO', profileImage))
                    let userStoredInfo = localStorage.getItem('userInfo')
                    userStoredInfo = JSON.parse(userStoredInfo);
                    userStoredInfo = { ...userStoredInfo, profileImage }
                    localStorage.setItem('userInfo', JSON.stringify(userStoredInfo))
                    message.loading("Uploading your profile photo...")
                        .then(() => {
                            message.success("Your profile photo has been uploaded successfully");
                        })
                }
            } catch (error) {
                console.log("UPDATE PHOTO ERROR", error.message)
            }
        } else {
            try {

                const userProfileUpdate = await axiosInstance(
                    {
                        method: 'post',
                        url: `user/profile/image`,
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                )
               
                // return
                if (userProfileUpdate.status === 200) {
                    // const { userId } = userProfileUpdate.data.data.studentProfile[0];
                    // dispatch(studentProfileAction('UPDATE_ID', userId))
                    const profileImage = userProfileUpdate.data.data.profileImage;
                    dispatch(studentProfileAction('UPDATE_PHOTO', profileImage))
                    let userStoredInfo = localStorage.getItem('userInfo')
                    userStoredInfo = JSON.parse(userStoredInfo);
                    userStoredInfo = { ...userStoredInfo, profileImage }
                    localStorage.setItem('userInfo', JSON.stringify(userStoredInfo))
                    message.loading("Uploading your profile photo...")
                        .then(() => {
                            message.success("Your profile photo has been uploaded successfully");
                        })
                }
            } catch (error) {
                message.error("something went wrong!");
                console.log("UPDATE PHOTO ERROR", error.message)
            }
        }
    }

    const getUserProfile = async () => {
        let userInfo = localStorage.getItem('userInfo')
        userInfo = JSON.parse(userInfo);
      
        setUserRole(userInfo.userRole);
        setUserID(userInfo.loginId);
        setUserEmail(userInfo.email);
        setUserName(userInfo.username);
        try {
            const userProfile = await GET(
                `${userInfo.userRole}/profile/${userInfo.loginId}` // rememmber to store in local storage when login
            );

            
            const profileImage = userProfile.data.data.image.profileImage;
        
          
            const profileData = userProfile.data.data.profile;
           
            setProfileId(profileData._id); // get profile ID
           
            setUserProfileInfo(profileData);
            setUpdatelanguages(profileData.languages);
            setSubjects(profileData.subjects)
            // setFiqah(profileData.fiqh);
            setSect(profileData.sect);
            setAudio({
                ...audio,
                one: profileData?.signUpAudio?.audioLink1 || '',
                two: profileData?.signUpAudio?.audioLink2 || '',
                three: profileData?.signUpAudio?.audioLink3 || ''
            })
            setVideo({
                ...video,
                one: profileData?.signUpVideos?.videoLink1 || '',
                two: profileData?.signUpVideos?.videoLink2 || '',
                three: profileData?.signUpVideos?.videoLink3 || ''
            })
            setCertificates(profileData?.certification);

            setLevel(profileData?.englishProficiency || 1);
            // const templevel = englishOptionsWithDisabled;
            // templevel[dprofileData.englishProficiency - 1].disabled = false;

            // setEnglishOptions(templevel);

            setCompSkill(profileData?.computerSkills || 1);

            // const temp = optionsWithDisabled;
            // temp[profileData.computerSkills - 1].disabled = false;

            // setOptions(temp)




            //setUserProfileInfo({country : "berlin", city:"america"}) // if st
            // profileImage && dispatch(studentProfileAction(types.UPDATE_PHOTO, profileImage));
        } catch (error) {
            console.log("GET PROFILE ERROR", error)
            setProfileId(null);
        }
        /* setUserProfileInfo({firstName : "l",
          about :"asasd"}) // if st*/


    }

    const getUserProfileImage = async () => {
        let userInfo = localStorage.getItem('userInfo')
        userInfo = JSON.parse(userInfo);
       
        setUserID(userInfo.loginId);

        try {
            const userProfileImage = await axiosInstance.get(
                `user/profile/image/${userInfo.loginId}` // rememmber to store in local storage when login
            );
         
            if (userProfileImage) {
                const profileImage = userProfileImage.data.data[0].profileImage
                // profileImage && dispatch(studentProfileAction(types.UPDATE_PHOTO, profileImage));

                setProfileImageId(true);
            }
        } catch (error) {
            console.log("GET PROFILE IMAGE ERROR", error);
            setProfileImageId(null);
        }

    }

    useEffect(() => {
        getUserProfile();
        // getCertificates();
        getUserProfileImage();
    }, [])


    const [audio, setAudio] = useState({
        one: '',
        two: '',
        three: ''
    });
    const [video, setVideo] = useState({
        one: '',
        two: '',
        three: ''
    });
    const [certificate, setCertificate] = useState({
        one: '',
        two: '',
        three: ''
    });

    const [prog, setProg] = useState({
        one: 0, two: 0, three: 0
    });
    const [progVideo, setProgVideo] = useState({
        one: 0, two: 0, three: 0
    });
    const [progCertificate, setProgCertificate] = useState(0);
    const [val, setVal] = useState(0);
    const [valVideo, setValVideo] = useState(0);
    const [valCertificate, setValCertificate] = useState(0);

    const fileChange = async (event, file) => {
        event.persist();
        const filee = Math.floor((file.size / 1024) / 1000);
        if (filee > 11) return notification.error({ message: 'File cannot be greater than 10MB' });

        let bodyFormData = new FormData();
        bodyFormData.append('audio', file);

        await axios({
            method: 'post',
            url: `${baseUrl}/${audioUpload}`,
            withCredentials: true,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progress) => {
                let temp = Math.round((progress.loaded / progress.total) * 100);
                if (val == 1) {

                    setProg({ ...prog, one: temp });

                }
                if (val == 2) {

                    setProg({ ...prog, two: temp });

                }
                if (val == 3) {

                    setProg({ ...prog, three: temp });

                }
            }

        }).then(res => {
            if (val == 1) {
                setAudio({
                    ...audio,
                    one: res.data.data.data.url
                });
                setProg({ ...prog, one: 0 });

            }
            if (val == 2) {
                setAudio({
                    ...audio,
                    two: res.data.data.data.url
                })
                setProg({ ...prog, two: 0 });

            }
            if (val == 3) {
                setAudio({
                    ...audio,
                    three: res.data.data.data.url
                })
                setProg({ ...prog, three: 0 });

            }
        });


    };
    const fileChangeVideo = async (event, file) => {

        event.persist();

        const filee = Math.floor((file.size / 1024) / 1000);
        if (filee > 11) return notification.error({ message: 'File cannot be greater than 10MB' });

        let bodyFormData = new FormData();
        bodyFormData.append('video', file);

        await axios({
            method: 'post',
            url: `${baseUrl}/${videoUpload}`,
            withCredentials: true,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progress) => {
                let temp = Math.round((progress.loaded / progress.total) * 100);
                if (valVideo == 1) {

                    setProgVideo({ ...progVideo, one: temp });

                }
                if (valVideo == 2) {

                    setProgVideo({ ...progVideo, two: temp });

                }
                if (valVideo == 3) {

                    setProgVideo({ ...progVideo, three: temp });

                }
            }

        }).then(res => {
            if (valVideo == 1) {
                setVideo({
                    ...video,
                    one: res.data.data.data.url
                });
                setProgVideo({ ...progVideo, one: 0 });

            }
            if (valVideo == 2) {
                setVideo({
                    ...video,
                    two: res.data.data.data.url
                })
                setProgVideo({ ...progVideo, two: 0 });

            }
            if (valVideo == 3) {
                setVideo({
                    ...video,
                    three: res.data.data.data.url
                })
                setProgVideo({ ...progVideo, three: 0 });

            }
        });


    };
    const fileChangeCertificate = async (event, file) => {
        event.persist();
        


        const filee = Math.floor((file.size / 1024) / 1000);
        if (filee > 11) return notification.error({ message: 'File cannot be greater than 10MB' });

        let bodyFormData = new FormData();
        bodyFormData.append('image', file);

        await axios({
            method: 'post',
            url: `${baseUrl}/${certificateUpload}`,
            withCredentials: true,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progress) => {
                let temp = Math.round((progress.loaded / progress.total) * 100);
                setProgCertificate(temp);

            }

        }).then(res => {
           



            let params = {
                certificationAward: "abc",
                certificationFrom: 1607339705,
                certificationYear: 2000,
                certificationImageUrl: res.data.data.certificateUrl,
                isNewCertificate: true,
                certificateId: ""
            }
            POST(certificateUpdate, params).then(response => {
           
                getCertificates();
            })
        })


    };
    const getCertificates = () => {
        GET(allCertificates).then(res => {
          
            setCertificates(res.data.data.certificates);
            setProgCertificate(0);
        })
    }
    const onInputClick = (event) => {
        event.persist();
        event.target.value = ''
    }
    const onInputClickVideo = (event) => {
        event.persist();
        event.target.value = ''
    }
    const onInputClickCertificate = (event) => {
        event.persist();
        event.target.value = ''
    }

    const levelChange = (e) => {
        setLevel(e.target.value);
    }
    const computerSkill = (e) => {
        setCompSkill(e.target.value);
    }
    return (
        <>
            {isLoading && <LoaderUi />}
            <section id="main-content">
                <div className="container-fluid">
                    <div className="edit-page">
                        <div className="__topbar-header">
                            <h3>Edit Profile</h3>
                            <a href="/" onClick={passwordChangeModalHandler} id="password-btn">Change Password</a>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="editpage-topcontent clearfix">
                                <div className="edittop-left">
                                    <div className="white-box">
                                        <label>Profile Picture</label>
                                        <div className="profilepicture-wrap">
                                            <div className="row">
                                                <div className="small-12 medium-2 large-2 columns">
                                                    <div className="circle">
                                                        <img className="profile-pic" src={userProfilePhoto || photo} alt="image" />
                                                    </div>
                                                    <p><b>{isUserName}</b></p>
                                                    <div className="p-image">
                                                        <label htmlFor="profileImg-upload" className=" mb-0 profileImg-upload" >
                                                            <div>Upload </div>
                                                        </label>
                                                        <input id="profileImg-upload" className="profileImg-upload" type="file" accept="image/*" onChange={(e) => updateProfilePhoto(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="edittop-right">
                                    <div className="white-box">
                                        <div className="edit-row">
                                            <label>About</label>
                                            <textarea
                                                rows="3"
                                                cols="8"
                                                name="about"
                                                defaultValue={isUserProfileInfo.about || formik.values.about} onChange={formik.handleChange}>
                                            </textarea>
                                            {formik.touched.about && formik.errors.about ? (
                                                    <div className="error"> {formik.errors.about}</div>
                                                ) : null}
                                        </div>
                                        <div className="edit-row clearfix">
                                            <div className="editcol-left">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    defaultValue={isUserProfileInfo.firstName || formik.values.firstName} onChange={formik.handleChange} />
                                                {formik.touched.firstName && formik.errors.firstName ? (
                                                    <div className="error"> {formik.errors.firstName}</div>
                                                ) : null}
                                            </div>
                                            <div className="editcol-right">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    defaultValue={isUserProfileInfo.lastName || formik.values.lastName}
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.touched.lastName && formik.errors.lastName ? (
                                                    <div className="error"> {formik.errors.lastName}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="edit-row clearfix">
                                            <div className="editcol-left">
                                                <label>Date of Birth</label>
                                                <input type="date" name="dob" defaultValue={formik.values.dob || isUserProfileInfo.dob} onChange={formik.handleChange} />
                                                {formik.touched.dob && formik.errors.dob ? (
                                                    <div className="error"> {formik.errors.dob}</div>
                                                ) : null}
                                            </div>
                                            <div className="editcol-right">
                                                <label>Gender</label>
                                                <select name="gender"
                                                    value={formik.values.gender || isUserProfileInfo.gender}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option label="Select Gender"></option>
                                                    <option value="Male" label="Male">Male</option>
                                                    <option value="Female" label="Female">Female</option>
                                                </select>
                                                {formik.touched.gender && formik.errors.gender ? (
                                                    <div className="error"> {formik.errors.gender}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-box text-right">
                                <div className="edit-row clearfix">
                                    <div className="editcol-left">
                                        <label>Email Address</label>
                                        <input type="email" name="email" value={isUserEmail} readOnly />
                                    </div>
                                    <div className="editcol-right">
                                        <label>Phone Number</label>
                                        <input
                                            type="tel"
                                            name="cellNumber"
                                            defaultValue={isUserProfileInfo.cellNumber || formik.values.cellNumber}
                                            onChange={formik.handleChange} />
                                             {formik.touched.cellNumber && formik.errors.cellNumber ? (
                                                    <div className="error"> {formik.errors.cellNumber}</div>
                                                ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="white-box">
                                {/* <div className="edit-row">
                                    <label>Address</label>
                                    <input type="text" name="address" defaultValue={userProfileData.address} onChange={formik.handleChange} />
                                    </div> */}
                                <div className="edit-row clearfix">
                                    <div className="editcol-left">
                                        <label>Country</label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', backgroundColor: '#edf0f8 !important' }}
                                            name="country"
                                            placeholder="Please select country"
                                            value={isCountry || isUserProfileInfo.country}
                                            onChange={countryChangeHandler}
                                        >
                                            {
                                                countries.map(country => (
                                                    <Option key={country.name}>
                                                        {country.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                        {formik.touched.country && formik.errors.country ? (
                                                    <div className="error"> {formik.errors.country}</div>
                                                ) : null}
                                    </div>
                                    <div className="editcol-right">
                                        <label>Timezone</label>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: '100%', backgroundColor: '#edf0f8 !important' }}
                                            name="timezone"
                                            placeholder="Please select timezone"
                                            value={isTimeZone || isUserProfileInfo.timeZone}
                                            onChange={timeZoneChangeHandler}
                                        >
                                            {
                                                timezone.map(tmz => (
                                                    <Option key={tmz.text}>
                                                        {tmz.text}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                        {formik.touched.timeZone && formik.errors.timeZone ? (
                                                    <div className="error"> {formik.errors.timeZone}</div>
                                                ) : null}
                                    </div>
                                </div>
                                <div className="edit-row clearfix">
                                    <div className="editcol-left">
                                        <label>State/Province</label>
                                        <input
                                            type="text"
                                            name="province"
                                            defaultValue={isUserProfileInfo.province || formik.values.province} onChange={formik.handleChange} />
                                     {formik.touched.province && formik.errors.province ? (
                                                    <div className="error"> {formik.errors.province}</div>
                                                ) : null}
                                    </div>
                                    <div className="editcol-right">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            defaultValue={isUserProfileInfo.city || formik.values.city} onChange={formik.handleChange} />
                                  {formik.touched.city && formik.errors.city ? (
                                                    <div className="error"> {formik.errors.city}</div>
                                                ) : null}
                                    </div>
                                </div>
                                <div className="edit-row clearfix">
                                    <div className="editcol-left">
                                        <label>Languages</label>
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%', backgroundColor: '#edf0f8' }}
                                            name="languages"
                                            placeholder="Please select"
                                            value={isUpdatelanguages || formik.values.languages}
                                            onChange={languagesChangeHandler}
                                        >
                                            {
                                                islanguages.map(lang => (
                                                    <Option key={lang}>
                                                        {lang}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                        {formik.touched.languages && formik.errors.languages ? (
                                                    <div className="error"> {formik.errors.languages}</div>
                                                ) : null}
                                    </div>

                                    <div className="editcol-right">
                                        <label>Payoneer ID</label>
                                        <input
                                            type="text"
                                            name="payoneerId"
                                            defaultValue={isUserProfileInfo.payoneerId || formik.values.payoneerId} onChange={formik.handleChange} />
                                      {formik.touched.payoneerId && formik.errors.payoneerId ? (
                                                    <div className="error"> {formik.errors.payoneerId}</div>
                                                ) : null}
                                    </div>
                                </div>
                            </div>

                            <Row className="m-0" align="top" gutter={[48, 32]}>


                                <Col span={12} className="pb-0">
                                    <div className="white-box">
                                        <label>Subjects</label>
                                        <div className="checkbox-button">
                                            <input
                                                type="checkbox"
                                                className="checkbox-button__input"
                                                id="choice1-1"
                                                value='recitation'
                                                name="subjects"
                                                checked={isSubjects.includes('recitation')}
                                                onChange={subjectsCheckboxHandler}
                                            />
                                            <span className="checkbox-button__control"></span>
                                            <span className="checkbox-button__label">Recitation</span>


                                            {isSubjects.includes('recitation') &&
                                                <div className="sub-text">Recite from Surah Yousaf or Surah Al Nisa or Ayat Ul Kursi or Surah Al
                                                Naas or Surah Al Falaq</div>
                                            }
                                        </div>
                                        <div className="checkbox-button">
                                            <input
                                                type="checkbox"
                                                className="checkbox-button__input"
                                                id="choice1-2"
                                                value={'tajweed'}
                                                name="subjects"
                                                checked={isSubjects.includes('tajweed')}
                                                onChange={subjectsCheckboxHandler}
                                            />
                                            <span className="checkbox-button__control"></span>
                                            <span className="checkbox-button__label">Tajweed</span>
                                            {isSubjects.includes('tajweed') &&
                                                <div className="sub-text">
                                                    Explain Ikhfa. Explain Ikhfa Shafawi. Explain Idgham
                                                </div>
                                            }
                                        </div>

                                        <div className="checkbox-button">
                                            <input
                                                type="checkbox"
                                                className="checkbox-button__input"
                                                id="choice1-3"
                                                value={'hifz'}
                                                name="subjects"
                                                checked={isSubjects.includes('hifz')}
                                                onChange={subjectsCheckboxHandler}
                                            />
                                            <span className="checkbox-button__control"></span>
                                            <span className="checkbox-button__label">Hifz</span>
                                            {isSubjects.includes('hifz') &&
                                                <div className="sub-text">
                                                    Recite from Surah Yousaf or Surah Al Nisa or Ayat Ul Kursi or Surah Al
                                                    Naas or Surah Al Falaq
                                                </div>
                                            }
                                        </div>
                                        <div className="checkbox-button">
                                            <input
                                                type="checkbox"
                                                className="checkbox-button__input"
                                                id="choice1-4"
                                                value={'arabic'}
                                                name="subjects"
                                                checked={isSubjects.includes('arabic')}
                                                onChange={subjectsCheckboxHandler}
                                            />
                                            <span className="checkbox-button__control"></span>
                                            <span className="checkbox-button__label">Arabic</span>
                                            {isSubjects.includes('arabic') &&
                                                <div className="sub-text">
                                                    Explain why is it important to learn how to speak Arabic?
                                                </div>
                                            }
                                        </div>
                                        <div className="checkbox-button">
                                            <input
                                                type="checkbox"
                                                className="checkbox-button__input"
                                                id="choice1-4"
                                                value={'islamicStudies'}
                                                name="subjects"
                                                checked={isSubjects.includes('islamicStudies')}
                                                onChange={subjectsCheckboxHandler}
                                            />
                                            <span className="checkbox-button__control"></span>
                                            <span className="checkbox-button__label">Islamic Studies</span>
                                            {isSubjects.includes('islamicStudies') &&
                                                <div className="sub-text">
                                                    Explain in 5 minutes what is Tawheed. what is prophethood?
                                                </div>
                                            }
                                        </div>

                                        <div className="edit-row">
                                           
                                            <input
                                                style={{display:'none'}}
                                                cols="8"
                                                name="about"
                                                defaultValue={isUserProfileInfo.subjects || formik.values.subjects} onChange={formik.handleChange}
                                            />

                                      
                                        { formik.errors.subjects ? (
                                                    <div className="error mt-2"> {formik.errors.subjects}</div>
                                                ) : null}
                                                </div>       
                                        <br />
                                    </div>
                                </Col>
                                <Col span={12} className="pb-0">

                                    <div className="white-box">

                                        <section className="audio-section">
                                            <div style={{ color: 'black', fontSize: '17px', fontWeight: 'bold', marginBottom: '10px' }}> Please upload audio MP3 and video MP4</div>
                                            <label>Audio </label>
                                            <div className="d-flex " style={{flexWrap:'wrap'}}>

                                                <div>

                                                    {audio.one ?



                                                        <audio className="mr-3" controls>
                                                            <source src={audio.one} type="audio/mp3" />
                                                            <source src={audio.one} type="audio/mp4" />
                                                        </audio>

                                                        :
                                                        <>
                                                            <label htmlFor="file-upload" className=" mb-0 audio-file" onClick={() => setVal(1)}>
                                                                <div>+Upload </div>

                                                            </label>
                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {prog.one ? <Progress percent={prog.one} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload" name="picture" accept="audio/*" onClick={(event) => onInputClick(event)}
                                                        onChange={(event) => fileChange(event, event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>


                                                <div>

                                                    {audio.two ?



                                                        <audio className="mr-3" controls>
                                                            <source src={audio.two} type="audio/mp3" />
                                                            <source src={audio.two} type="audio/mp4" />
                                                        </audio>

                                                        :
                                                        <>
                                                            <label htmlFor="file-upload" className=" mb-0 audio-file" onClick={() => setVal(2)}>
                                                                <div>+Upload </div>

                                                            </label>
                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {prog.two ? <Progress percent={prog.two} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload" name="picture" accept="audio/*"
                                                        onChange={(event) => fileChange(event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>


                                                <div>

                                                    {audio.three ?



                                                        <audio className="mr-3" controls>
                                                            <source src={audio.three} type="audio/mp3" />
                                                            <source src={audio.three} type="audio/mp4" />
                                                        </audio>

                                                        :

                                                        <>
                                                            <label htmlFor="file-upload" className=" mb-0 audio-file" onClick={() => setVal(3)}>
                                                                <div>+Upload </div>

                                                            </label>

                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {prog.three ? <Progress percent={prog.three} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload" name="picture" accept="audio/*"
                                                        onChange={(event) => fileChange(event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>


                                            </div>

                                        </section>

                                        <section className="video-section pt-1">
                                            <label>Video</label>
                                            <div className="d-flex" style={{flexWrap:'wrap'}}>

                                                <div>

                                                    {video.one ?



                                                        <video width="300" height="300" className="mr-3" controls>
                                                            <source src={video.one} type="video/mp3" />
                                                            <source src={video.one} type="video/mp4" />
                                                        </video>


                                                        :
                                                        <>
                                                            <label htmlFor="file-upload-video" className=" mb-0 video-file" onClick={() => setValVideo(1)}>
                                                                <div>+Upload </div>

                                                            </label>
                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {progVideo.one ? <Progress percent={progVideo.one} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload-video" name="video" accept="video/*" onClick={(event) => onInputClickVideo(event)}
                                                        onChange={(event) => fileChangeVideo(event, event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>
                                                <div>

                                                    {video.two ?



                                                        <video width="300" height="300" className="mr-3" controls>
                                                            <source src={video.two} type="video/mp4" />
                                                            <source src={video.two} type="video/mp4" />
                                                        </video>


                                                        :
                                                        <>
                                                            <label htmlFor="file-upload-video" className=" mb-0 video-file" onClick={() => setValVideo(2)}>
                                                                <div>+Upload </div>

                                                            </label>
                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {progVideo.two ? <Progress percent={progVideo.two} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload-video" name="video" accept="video/*" onClick={(event) => onInputClickVideo(event)}
                                                        onChange={(event) => fileChangeVideo(event, event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>
                                                <div>

                                                    {video.three ?



                                                        <video width="300" height="300" className="mr-3" controls>
                                                            <source src={video.three} type="video/mp4" />
                                                            <source src={video.three} type="video/mp4" />
                                                        </video>


                                                        :
                                                        <>
                                                            <label htmlFor="file-upload-video" className=" mb-0 video-file" onClick={() => setValVideo(3)}>
                                                                <div>+Upload </div>

                                                            </label>
                                                            <div className="pl-3">[Max: 10MB]</div>
                                                        </>

                                                    }
                                                    {progVideo.three ? <Progress percent={progVideo.three} size="small" strokeColor="#52c41a" /> : null}
                                                    <input type="file" id="file-upload-video" name="video" accept="video/*" onClick={(event) => onInputClickVideo(event)}
                                                        onChange={(event) => fileChangeVideo(event, event.target.files[0])} className="d-none h-100 w-100" />
                                                </div>
                                            </div>

                                        </section>

                                    </div>
                                </Col>
                            </Row>

                            <section className="image-section pt-0">

                                <label>Certificates</label>
                                <div className="d-flex">

                                    <div style={{ display: 'flex', marginRight: '35px' ,flexWrap:'wrap'}}>

                                        {certificates.length > 0 && certificates.map((item, index) => (




                                            <div key={index} onClick={() => { setModalImage(item.certificationImageUrl); setIsModalVisible(true) }}>
                                                <img style={{ width: '135px', marginRight: '35px' }} src={item.certificationImageUrl || photo} alt="image" />
                                            </div>

                                        ))}
                                    </div>
                                    <div>
                                        <label htmlFor="file-upload-image" className=" mb-0 video-file" onClick={() => setValCertificate(1)}>
                                            <div>+Upload </div>

                                        </label>
                                        <div className="pl-3">[Max: 10MB]</div>


                                        {progCertificate ? <Progress percent={progCertificate} size="small" strokeColor="#52c41a" /> : null}
                                        <input type="file" id="file-upload-image" name="image" accept="image/*" onClick={(event) => onInputClickCertificate(event)}
                                            onChange={(event) => fileChangeCertificate(event, event.target.files[0])}
                                            className="d-none h-100 w-100" />

                                    </div>



                                </div>






                            </section>



                            <div className="white-box">

                                <br /><br />
                                {
                                    (isUserRole === 'tutor') &&
                                    <>
                                        <div className="edit-row three-col clearfix">
                                            <div className="editcol-left">
                                                <label>Hourly Rate (Dollars)</label>
                                                <input name="hourlyRate" type="number" defaultValue={isUserProfileInfo.hourlyRate} onChange={formik.handleChange} />
                                                {formik.touched.hourlyRate && formik.errors.hourlyRate ? (
                                                    <div className="error"> {formik.errors.hourlyRate}</div>
                                                ) : null}
                                            </div>
                                            {/* <div className="editcol-left">
                                                <label>Fiqh</label>
                                                <select
                                                    name="fiqah"
                                                    value={isFiqah || formik.values.fiqah}
                                                    onChange={fiqahChangeHandler}
                                                >
                                                    <option>Hanafi</option>
                                                    <option>Shafi</option>
                                                    <option>Deobandi</option>
                                                    <option>Beralvi</option>
                                                    <option>Malki</option>
                                                    <option>Jafri</option>
                                                    <option>Zaidiyyah</option>
                                                </select>
                                            </div> */}
                                            <div className="editcol-left">
                                                <label>Sect</label>
                                                <select
                                                    name="sect"
                                                    value={isSect || formik.values.sect}
                                                    onChange={sectChangeHandler}
                                                >
                                                    <option>Sunni</option>
                                                    <option>Shiah</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="level-box">
                                <label>What is your level of English proficiency?</label>
                                <div className="d-flex mt-4">
                                    <div className="level-txt mr-5">  Not Good</div>
                                    <Radio.Group style={{ display: 'flex' }} onChange={(e) => levelChange(e)} value={level}>
                                        <Radio className="radio-label" value={1}>1</Radio>
                                        <Radio className="radio-label" value={2}>2</Radio>
                                        <Radio className="radio-label" value={3}>3</Radio>
                                        <Radio className="radio-label" value={4}>4</Radio>
                                        <Radio className="radio-label" value={5}>5</Radio>
                                        <Radio className="radio-label" value={6}>6</Radio>
                                        <Radio className="radio-label" value={7}>7</Radio>
                                        <Radio className="radio-label" value={8}>8</Radio>
                                        <Radio className="radio-label" value={9}>9</Radio>
                                        <Radio className="radio-label" value={10}>10</Radio>
                                    </Radio.Group>
                                    <div className="level-txt ml-3"> Very Good</div>
                                </div>
                            </div>
                            <div className="level-box">
                                <label>How would you rate your computer skills?</label>
                                <div className="d-flex mt-4">
                                    <div className="level-txt mr-5">  Not Good</div>
                                    <Radio.Group style={{ display: 'flex' }} onChange={(e) => computerSkill(e)} value={compSkill}>
                                        <Radio className="radio-label" value={1}>1</Radio>
                                        <Radio className="radio-label" value={2}>2</Radio>
                                        <Radio className="radio-label" value={3}>3</Radio>
                                        <Radio className="radio-label" value={4}>4</Radio>
                                        <Radio className="radio-label" value={5}>5</Radio>
                                        <Radio className="radio-label" value={6}>6</Radio>
                                        <Radio className="radio-label" value={7}>7</Radio>
                                        <Radio className="radio-label" value={8}>8</Radio>
                                        <Radio className="radio-label" value={9}>9</Radio>
                                        <Radio className="radio-label" value={10}>10</Radio>
                                    </Radio.Group>
                                    <div className="level-txt ml-3"> Very Good</div>
                                </div>
                            </div>








                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}> <button type="submit" className="green-btn" >Save</button></div>
                        </form>
                    </div>
                </div>
            </section>





            <Modal
                onCancel={handleCancel}
                visible={isModalVisible}
                footer={false}
            >
                <img className="w-100" src={modalImage} />
            </Modal>







            {/* update password modal */}
            {
                isPasswordChnageModal && (
                    <>
                        <div className="modal-window"></div>
                        <div className="window-wrapper">
                            <a href="/" onClick={() => setPasswordChnageModal(false)} title="Close" className="modal-close">Close</a>
                            <div className="modalhead">
                                <h2>Change Password</h2>
                            </div>
                            <div className="modalbody">
                                <p>Update your password frequently if you find any suspicious login!</p>
                                <form onSubmit={studentChangePasswordFormik.handleSubmit}>
                                    <div className="inputwrap old">
                                        <span></span>
                                        <input
                                            type="password"
                                            placeholder="Old Password"
                                            name="password"
                                            defaultValue={studentChangePasswordFormik.values.password || userProfileData.password}
                                            onChange={studentChangePasswordFormik.handleChange}
                                        />
                                    </div>
                                    <div className="inputwrap">
                                        <span></span>
                                        <input
                                            type="password"
                                            placeholder="New Password"
                                            name="newPassword"
                                            defaultValue={studentChangePasswordFormik.values.newPassword || userProfileData.newPassword}
                                            onChange={studentChangePasswordFormik.handleChange}
                                        />
                                    </div>
                                    <div className="inputwrap">
                                        <span></span>
                                        <input
                                            type="password"
                                            placeholder="Re-enter Your New Password"
                                            name="newConfirmPassword"
                                            defaultValue={studentChangePasswordFormik.values.newConfirmPassword || userProfileData.newConfirmPassword}
                                            onChange={studentChangePasswordFormik.handleChange}
                                        />
                                    </div>
                                    {
                                        isLoading ?
                                            <button
                                                type="submit"
                                                className="green-btn"
                                                disabled
                                            >Updating your password...</button>
                                            :
                                            <button
                                                type="submit"
                                                className="green-btn"
                                                disabled={studentChangePasswordFormik.values.newPassword !== studentChangePasswordFormik.values.newConfirmPassword}
                                            >Update</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </>
                )
            }

        </>
    )
}

export default TutorProfile;
