import {createStore,combineReducers, applyMiddleware} from 'redux';
import { logger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import {rootReducer} from './rootReducer';
import adminReducer from './Reducers/adminReducer';
import studentProfileReducer from './Reducers/studentProfileReducer';
import socketReducer from './Reducers/socketReducer';

  const reducers = combineReducers({
    studentProfileReducer,
    adminReducer,
    socketReducer
});

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
  
      if (serializedState === null) {
        return undefined;
      }
  
      return JSON.parse(serializedState);
  
    } catch (err) {
      return undefined;
    }
  };
  
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (err) {
  
    }
  };
  
  const persistedState = loadState();
  // const store = createStore(reducers, persistedState)
  const store = createStore(reducers)
// store.subscribe(() => saveState(store.getState()));
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(logger)));


export default store; 
