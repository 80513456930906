/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CompassOutlined } from '@ant-design/icons';
import { Button } from 'antd'
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const ExportExcel = ({ data }) => {



    const fileSave = () => {
        const k = Object.keys(data);
        const v = Object.values(data);

        const worksheet = XLSX.utils.json_to_sheet(v);
        const workBook = {
            Sheets: {
                'subscription': worksheet
            },
            SheetNames: ['subscription']
        }
        const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

        const datae = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        fileSaver.saveAs(datae, 'subscription data' + EXCEL_EXTENSION);
    }


    return (
        <div>
            <Button onClick={() => fileSave()}>
                Export Excel
            </Button>
        </div>
    );
}

export default ExportExcel;