/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./style.css";
import {
  BrowserRouter as Router,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import axios from "../../../../axios";
import getUserInfo from "../../../../shared/Helpers/getUserInfo";
import msgIcon from "../../../../assets/images/messagewhite-icon.png";
import CallIcon from "../../../../assets/images/videocall-icon.png";
import { Tabs, Empty, message, notification, Spin, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { GET, POST, PUT } from '../../../../apiClient';

import { Socket } from "socket.io-client";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import StudentDashboard from '../../../student/containers/dashboard';
import moment from 'moment';
const { TabPane } = Tabs;
// this.state = {DeleteId: []};


const Landing = () => {
  let SocketData = useSelector((state) => state.socketReducer.data);
  const CALL_URL = "https://video.nidaulquran.com/";
  const history = useHistory();

  const photo =
    "https://emeraldpsychiatry.com/wp-content/uploads/2018/05/dummy_players.png";
  const [isAllStudents, setAllStudents] = useState([]);
  const [isAllInvited, setAllInvited] = useState([]);
  const [isAllRecommendations, setAllRecommendations] = useState([]);
  const [isUserInfo, setUserInfo] = useState({});
  const [isInvitedId, setInvitedId] = useState(null);
  const [isPendingInvitations, setPendingInvitations] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [tabState, setTabState] = useState(false);
  const [user, setUser] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [rImg, setRImg] = useState([]);
  const [siImg, setSiImg] = useState([]);
  const [riImg, setRiImg] = useState([]);

  let { path } = useRouteMatch();
  let location = useLocation();
  useEffect(() => {
    setSelectedTab(location.tab);
    setTabState(true);
  }, [location]);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Appointments are approved",
      description: "The appointments have been approved.",
    });
  };

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    if (params.has("contactID")) {
      let body = {
        contactID: params.get("contactID"),
        transactionId: params.get("transactionId"),
      };
      POST("approve-appointments", body,)
        .then((res) => {

          debugger;
          if (res.status == 200) {

            openNotificationWithIcon("success");

          }
          if (res.status == 409) {

            notification.error({ message: res.err.message });

          }

        })
        .catch((err) => console.log(err));
    }
  }, []);

  const getAllStudents = async (user) => {
    setLoading(true);
    // console.log("Get all students hit");
    try {
      const students = await GET(`contact/allcontacts?loginId=${user.loginId}&role=${user.userRole}`);
      // console.log("All students", students.data.data);

      students.data.data.forEach(element => {

        if (element.nextClass.length > 0) {
          const start = element.nextClass[0].startDateUnix;
          const end = element.nextClass[0].endDateUnix;

          const before = moment(start * 1000).subtract(element.joinClassButton, "minutes").unix();
          const cu = moment().unix();

          console.log(start, end, before, cu, 'cu > before && cu < end')
          if (cu > before && cu < end) {


            element.hideBtn = false;
          }
          else {

            element.hideBtn = true;
          }

        }
        else {
          element.hideBtn = true;
        }

      });




      const allTutorsData = students.data.data;
      setAllStudents(allTutorsData);
      const userImgs = allTutorsData.map((item, index) => item.profileImage);
      setProfileImg(userImgs);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.notify(
          "Request Timed out. Please Check your Network Connectivity."
        );
      }
      setLoading(false);
    }
  };

  const recommendedUsers = async (user) => {

    setLoading(true);
    try {
      const recommendation = await GET(`contact/recommendation/?loginId=${user.loginId}&role=${user.userRole}`);

      const allRecommendations = recommendation.data.data;

      setAllRecommendations(allRecommendations);
      const userImgs = allRecommendations.map(
        (item, index) => item.profileImage
      );
      setRImg(userImgs);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUserName = async () => {
    const userInfo = await localStorage.getItem("userInfo");
    const name = JSON.parse(userInfo);

    setUser(name.username);
  };

  const sendInvite = async (invitedTo) => {

    try {
      let data = {
        invitedTo: invitedTo,
        invitedBy: isUserInfo.loginId,
        role: isUserInfo.userRole,
        username: user,
      };

      const invitation = await POST("contact", data);

      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation sent`,
          style: {
            zIndex: "999999",
            fontSize: "20px",
          },
        });
        setInvitedId(invitedTo);
        getPendingInvitations(isUserInfo);
        getAllStudents(isUserInfo);
        recommendedUsers(isUserInfo);

      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingInvitations = async (isUserInfo) => {

    try {
      const pendingInvitations = await GET(
        `contact/pendinginvites/?loginId=${isUserInfo.loginId}&role=${isUserInfo.userRole}`
      );

      pendingInvitations && setPendingInvitations(pendingInvitations.data.data);
      const allPendingInvites = pendingInvitations.data.data;
      const userImgs = allPendingInvites?.map(
        (item, index) => item.profileImage
      );
      setRiImg(userImgs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const acceptInvitationHandler = async (value) => {
    const token = await localStorage.getItem("token");
    const myToken = JSON.parse(token);

    const { invitedBy, invitedTo, role, isAccepted } = value;
    try {
      const acceptInvitation = PUT("contact", {
        invitedBy: invitedBy,
        invitedTo: invitedTo,
        role: role,
        isAccepted,
        username: user,
      });
      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation Accepted!`,
          style: {
            zIndex: "999999",
            fontSize: "20px",
          },
        });
        getPendingInvitations(isUserInfo);
        getAllStudents(isUserInfo);
        recommendedUsers(isUserInfo);
        recommendedUsers(isUserInfo);
      });
    } catch (error) {
      console.log(error);

    }
  };

  const rejectInivitationHandler = async (value) => {

    const { invitedBy, invitedTo, role, isAccepted } = value;
    try {
      const acceptInvitation = await PUT("contact", {
        invitedBy: invitedBy,
        invitedTo: invitedTo,
        role: role,
        isAccepted,
        username: user,
      });
      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation Rejected!`,
          style: {
            zIndex: "999999",
            fontSize: "20px",
          },
        });
      });
      getPendingInvitations(isUserInfo);
      getAllStudents(isUserInfo);
      recommendedUsers(isUserInfo);
      recommendedUsers(isUserInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const sentMessage = async (id) => {
    try {
      const contact = await POST('chatcontact', {
        "studentId": `${isUserInfo.loginId}`,
        "teacherId": `${id}`,
        "role": `${isUserInfo.userRole}`
      });
      contact.status && history.push(`/${isUserInfo.userRole}/chat`);
    } catch (error) {
      console.log("Already exist!");
    }

  };

  const deleteStudent = async (stId) => {
    if (confirm('Are you sure you want to delete?')) {
      localStorage.setItem(`hideStudent${stId}`, stId+isUserInfo.loginId);
      window.location.reload();
    }
};

  const setupCall = async (id, callId) => {

    // alert("dialing........");
    // try {
    //   let call = await POST('call', {
    //     teacherId: isUserInfo.loginId,
    //     studentId: id,
    //     isCallInitiate: true,
    //   });

    // const callInfo = call.data.data;
    // SocketData.emit("call", {
    //   ...callInfo,
    //   callUrl: `${CALL_URL}${id}${isUserInfo.loginId}`,
    //   role: `${isUserInfo.userRole}`,
    // });
    window.open(`/call?callUrl=${CALL_URL}&cid=${callId}&type=2&id=${id}&loginId=${isUserInfo.loginId}`, "_blank")

    // window.open(`${CALL_URL}${id}${isUserInfo.loginId}`, "_blank");
    // } catch (error) {
    //   console.log("ERROR, while dialing...", error.message);
    // }
  };

  const getInvitedUsers = async (user) => {

    setLoading(true);
    try {
      const users = await GET(`contact/invitations?loginId=${user.loginId}&role=${user.userRole}`);

      const allInvited = users.data.data;
      setAllInvited(allInvited);
      const userImgs = allInvited.map((item, index) => item.profileImage);
      setSiImg(userImgs);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserInfo().then((res) => {
      getAllStudents(res);
      setUserInfo(res);
      getPendingInvitations(res);
      recommendedUsers(res);
      setUserName();
      getInvitedUsers(res);
    });
  }, []);

  useEffect(() => {

    const timer = setInterval(() => {
      getUserInfo().then((res) => {
        getAllStudents(res);
        setUserInfo(res);
        getPendingInvitations(res);
        recommendedUsers(res);
        setUserName();
        getInvitedUsers(res);
      });
      // console.log('timer start')
    }, 30000);

    return () => {

      clearInterval(timer);
    }
  }, [])

  return (
    <div className="tab-wrapper">
      <Tabs defaultActiveKey="1" type="card" size={"large"}>
        <TabPane tab="Connected Students" key="1">
          <div id="current-tutor-wrap">
            <div className="current-tutor-left">
              {isLoading === true ? (
                <div className="example">
                  <Spin size="large" />
                </div>
              ) : isAllStudents ? (
                isAllStudents.map(
                  (student, index) =>
                    student.firstName && (
                      <div key={index}>
                        <div className="reusedbox-content clearfix d-flex box-padding position-relative" style={{display : (localStorage.getItem(`hideStudent${student.userId}`) === student.userId + isUserInfo.loginId) ? 'none' : 'flex'}}>
                          {student.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}

                          <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={profileImg.length >= 0 &&
                            profileImg[index] &&
                            profileImg[index][0]
                            ? profileImg[index][0].profileImage
                            : photo} />

                          {/* <img
                              src={
                                profileImg.length >= 0 &&
                                  profileImg[index] &&
                                  profileImg[index][0]
                                  ? profileImg[index][0].profileImage
                                  : photo
                              }
                              alt=""
                            /> */}


                          <div className="boxcontent ">
                            <h4>
                              {student.firstName} {student.lastName}
                            </h4>
                            {/* <span>Next session in:</span>
                              <em>10h:03m</em><br /> */}
                            <span>Subject: &nbsp;</span>
                            {student.subjects.map((subject) => (
                              <em
                                key={subject}
                                style={{
                                  textTransform: "capitalize",
                                  marginBottom: "6px",
                                }}
                              >
                                {subject}, &nbsp;
                              </em>
                            ))}
                            <br />
                            {/* <a className="btn-cancel">Cancel</a>
                            <a className="btn-cancelborder">
                              End this Contract
                            </a> */}
                            {!student.hideBtn ?
                              <button style={{ marginRight: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}
                                onClick={() => setupCall(student.userId, student.nextClass[0]._id)}>Join a Class</button>
                              : null}

                            <button style={{ background: "#02a28e", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}
                              onClick={() => sentMessage(student.userId)}>Send Message</button>

                            {/*<button style={{ marginLeft: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}*/}
                            {/*        onClick={() => deleteStudent(student.userId)}>Delete Student</button>*/}
                          </div>
                          {/* <div className="box-otherlinks">
                            <ul>
                              <li>
                                <a
                                  className="bluebox"
                                  onClick={() => sentMessage(student.userId)}
                                >
                                  <img src={msgIcon} alt="icon" />
                                </a>
                              </li>
                              <li>
                                <a
                                  className="greenbox"
                                  onClick={() => setupCall(student.userId)}
                                >
                                  <img src={CallIcon} alt="icon" />
                                </a>
                              </li>
                            </ul>
                          </div>                           */}
                        </div>
                      </div>
                    )
                )
              ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
            <div className="current-tutor-right">
              <Tabs
                onChange={() => setTabState(false)}
                type="card"
                {...(selectedTab === "tab2" && tabState === true
                  ? { activeKey: "3" }
                  : null)}
                {...(selectedTab === "tab1" && tabState === true
                  ? { activeKey: "1" }
                  : null)}
              >
                <TabPane tab="Recommended" key="1">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isLoading === true ? (
                        <div className="example">
                          <Spin />
                        </div>
                      ) : isAllRecommendations ? (
                        isAllRecommendations.map(
                          (student, index) =>
                            student.firstName && (
                              <div key={index}>
                                <div className="right-content" style={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)', marginBottom: '30px' }}>
                                  <div className="currenttutuors-tab">
                                    <div className="reusedbox-content d-flex  position-relative " style={{ boxShadow: 'none', marginBottom: '0px' }}>
                                      {student.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}

                                      <div style={{ width: '20%' }}>
                                        <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={profileImg.length >= 0 &&
                                          rImg.length >= 0 &&
                                          rImg[index] &&
                                          rImg[index][0]
                                          ? rImg[index][0].profileImage
                                          : photo} />
                                      </div>


                                      <div className="boxcontent" style={{ width: "auto" }} >
                                        <h4>
                                          {student.firstName} {student.lastName}
                                        </h4>
                                        <span>Interest: </span>
                                        {student.subjects.map((subject) => (
                                          <em
                                            key={subject}
                                            style={{
                                              textTransform: "capitalize",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {subject}, &nbsp;
                                          </em>
                                        ))}
                                        <br />
                                        <span>Spoken Languages: </span>
                                        {student.languages.map((language) => (
                                          <em
                                            key={language}
                                            style={{ marginBottom: "8px" }}
                                          >
                                            {" "}
                                            {language}, &nbsp;{" "}
                                          </em>
                                        ))}
                                        <br />
                                        <span>Country: </span>
                                        <em style={{ marginBottom: "8px" }}>
                                          {" "}
                                          {student.country || null}
                                        </em>
                                        <br />
                                      </div>

                                    </div>

                                    <div style={{ textAlign: 'right' }}>

                                      {isUserInfo &&
                                        isUserInfo.isAdminApproved &&
                                        isUserInfo.userRole !== "student" && (
                                          <button

                                            className="greenbtn"
                                            style={{
                                              textAlign: 'center',
                                              color: '#fff',
                                              borderRadius: '3px',
                                              padding: '10px 15px',
                                              marginBottom: '10px',
                                              background: ' #02a28e',
                                              border: '1px solid #02a28e',
                                              fontFamily: 'NexaBold'
                                            }}
                                            onClick={() =>
                                              sendInvite(student.userId)
                                            }
                                          >
                                            Send Invitation
                                          </button>
                                        )}
                                    </div>

                                  </div>

                                </div>
                              </div>
                            )
                        )
                      ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Sent Invitations" key="2">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isLoading === true ? (
                        <div className="example">
                          <Spin />
                        </div>
                      ) : isAllInvited ? (
                        isAllInvited.map(
                          (student, index) =>
                            student.firstName && (
                              <div key={index}>
                                <div className="right-content">
                                  <div className="currenttutuors-tab">
                                    <div className="reusedbox-content d-flex  clearfix position-relative">
                                      {student.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}

                                      {/* <div className="image-wrap"> */}

                                      <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={siImg.length >= 0 &&
                                        siImg[index] &&
                                        siImg[index][0]
                                        ? siImg[index][0].profileImage
                                        : photo} />


                                      {/* <img
                                          src={
                                            siImg.length >= 0 &&
                                              siImg[index] &&
                                              siImg[index][0]
                                              ? siImg[index][0].profileImage
                                              : photo
                                          }
                                          alt=""
                                        />
                                      </div> */}
                                      <div
                                        className="boxcontent"
                                        style={{ width: "auto" }}
                                      >
                                        <h4>
                                          {student.firstName} {student.lastName}
                                        </h4>
                                        <span>Interest: </span>
                                        {student.subjects.map((subject) => (
                                          <em
                                            key={subject}
                                            style={{
                                              textTransform: "capitalize",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {" "}
                                            {subject}, &nbsp;{" "}
                                          </em>
                                        ))}
                                        <br />
                                        <span>Spoken Languages: </span>
                                        {student.languages.map((language) => (
                                          <em
                                            key={language}
                                            style={{ marginBottom: "8px" }}
                                          >
                                            {" "}
                                            {language}, &nbsp;{" "}
                                          </em>
                                        ))}
                                        <br />
                                        <span>Country:</span>
                                        <em style={{ marginBottom: "8px" }}>
                                          {student.country}
                                        </em>
                                        <br />
                                      </div>
                                      {/* <div style={{ textAlign: 'right', marginTop: '90px' }}>
                                            <a href="#" className="green-btn">Send Invite</a>
                                          </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Recieved Invitations" key="3">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isPendingInvitations ? (
                        isPendingInvitations.map((tutor, index) => (
                          <div key={index}>
                            <div className="right-content">
                              <div className="currenttutuors-tab">
                                <div className="reusedbox-content clearfix d-flex  position-relative">
                                  {tutor.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}

                                  {/* <img
                                      src={
                                        riImg.length >= 0 &&
                                          riImg[index] &&
                                          riImg[index][0]
                                          ? riImg[index][0].profileImage
                                          : photo
                                      }
                                      alt=""
                                    /> */}

                                  <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={riImg.length >= 0 &&
                                    riImg[index] &&
                                    riImg[index][0]
                                    ? riImg[index][0].profileImage
                                    : photo} />



                                  <div
                                    className="boxcontent"
                                    style={{ width: "auto" }}
                                  >
                                    <h4>
                                      {tutor.firstName} {tutor.lastName}
                                    </h4>
                                    <span>Interest: </span>
                                    {tutor.subjects.map((subject) => (
                                      <em
                                        key={subject}
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        {" "}
                                        {subject}, &nbsp;{" "}
                                      </em>
                                    ))}
                                    <br />
                                    <span>Spoken Languages: </span>
                                    {tutor.languages.map((language) => (
                                      <em
                                        key={language}
                                        style={{ marginBottom: "8px" }}
                                      >
                                        {" "}
                                        {language}, &nbsp;{" "}
                                      </em>
                                    ))}
                                    <br />
                                    <span>Country: </span>
                                    <em style={{ marginBottom: "8px" }}>
                                      {" "}
                                      {tutor.country}
                                    </em>
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "right",
                                      display: "inline-block",
                                    }}
                                  >
                                    <span
                                      className="accept-btn"
                                      onClick={() =>
                                        acceptInvitationHandler({
                                          invitedBy: tutor.userId,
                                          invitedTo: isUserInfo.loginId,
                                          role: isUserInfo.userRole,
                                          isAccepted: true,
                                        })
                                      }
                                    >
                                      Accept
                                    </span>
                                    &nbsp;&nbsp;
                                    <span
                                      className="reject-btn"
                                      onClick={() =>
                                        rejectInivitationHandler({
                                          invitedBy: tutor.userId,
                                          invitedTo: isUserInfo.loginId,
                                          role: isUserInfo.userRole,
                                          isAccepted: false,
                                        })
                                      }
                                    >
                                      Reject
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Landing;
