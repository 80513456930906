/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'antd';
import DefaultAvatar from '../../../../../assets/images/defaultAvatar.jpeg';
import { studentListing, blockUnblockStudent, approveStudent } from '../../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GET, POST } from '../../../../../apiClient';
import Empty from '../../../../../assets/images/undraw_empty_xct9.svg';
import '../students.css';
import { useDebounce } from 'react-use';


const Pending = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [students, setStudents] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [val, setVal] = useState('');
    useEffect(() => {
        getStudents();
        return () => {


        }
    }, [searchText])

    const [, cancel] = useDebounce(
        () => {

            setSearchText(val);
        },
        500,
        [val]
    );
    const statusChange = (id, status) => {


        dispatch({ type: 'LOADING', payload: true });
        let params = {
            studentId: id,
            isBlocked: status
        }
        POST(`${blockUnblockStudent}`, params).then(res => {

            if (res.status === 200) {
                dispatch({ type: 'LOADING', payload: false });
                notification.success({ message: res.data.data.message });
                getStudents();

            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: 'LOADING', payload: false });

            }
        });

    }


    const getStudents = () => {
        dispatch({ type: 'LOADING', payload: true });

        GET(`${studentListing}?status=2&searchText=${searchText}&limit=100&offset=0`).then(res => {

            if (res.status === 200) {
                const { data } = res;

                setStudents(data.data.data.studentsList);
            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
            }
            dispatch({ type: 'LOADING', payload: false });
        });
    }
    const approve = (id) => {

        dispatch({ type: 'LOADING', payload: true });

        GET(`${approveStudent}/${id}`).then(res => {
          
            if (res.status === 200) {
                dispatch({ type: 'LOADING', payload: false });

                notification.success({ message: res.data.data.message });
                getStudents();
            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
                dispatch({ type: 'LOADING', payload: false });

            }
        });

    }
    return (
        <div className="listing">
            <div className="search-div">

                <input
                    type="text"
                    value={val}
                    placeholder="Search"
                    onChange={({ currentTarget }) => {
                        setVal(currentTarget.value);
                    }}
                />

            </div>
            { students.length ?
                <Row className="m-0" gutter={[48, 32]}>

                    {students && students.map((val, ind) => (
                        <Col xs={24} sm={24} md={24} lg={24} xl={12} key={ind}>
                            <Card bordered={false} hoverable="true" >
                                <div className="d-flex ">
                                    <div className="mr-4"><img src={val.profileImage || DefaultAvatar} className="avatar" /> </div>
                                    <div className="content flex-grow-1">

                                        <div className="d-flex justify-content-between">
                                            <div className="text-capitalize">{val?.firstName} {val?.lastName} {!val.firstName ? val.username : null} </div>

                                            {val?.isBlocked ? <div className="blocked">Blocked</div> :
                                                <div className={val?.isConfirm ? 'approve' :'not-approve'}>
                                                    {val?.isConfirm ? "Approved" : "Not Approved"}
                                                </div>
                                            }
                                        </div>
                                        {/* <small>Interest: <strong className='text-capitalize'>{val.skills.join(',')}</strong></small>
                            <small>Spoken Languages: <strong className='text-capitalize'>{val.languages.join(',')}</strong></small> */}
                                        <small>Email: <strong>{val?.email}</strong></small>
                                        {val?.gender && <small>Gender: <strong>{val?.gender}</strong></small>}
                                    </div>

                                </div>
                                <Row className="m-0 end-btns" justify="end">
                                    {/* {!val?.isConfirm ? <Button type="primary" danger onClick={() => approve(val._id)}> Approve</Button> : null} */}
                                    {!val?.isBlocked ? <Button danger onClick={() => statusChange(val._id, true)}>Block</Button>
                                        :
                                        <Button danger onClick={() => statusChange(val._id, false)}>Un-Block</Button>
                                    }
                                    <Button className="edit-btn" onClick={() => history.push(`${history.location.pathname}/${val._id}`)}>Details</Button>
                                </Row>

                            </Card>
                        </Col>
                    ))}

                </Row>
                :

                <div className="illustrator">
                    <div style={{ marginTop: '6rem', textAlign: 'center' }}>
                        <img src={Empty} height="400px" />
                        <h2 className="text-center">Not Found</h2>
                    </div>
                </div>
            }
        </div>
    );
}

export default Pending;