/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Pagination } from "antd";
import DefaultAvatar from "../../../../../assets/images/defaultAvatar.jpeg";
import Empty from "../../../../../assets/images/undraw_empty_xct9.svg";
import { GET, POST } from "../../../../../apiClient";
import {
  tutorListing,
  approveTeacher,
  blockUnblockTeacher,
} from "../../../../../globalConstant";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import "../tutors.css";
import { useDebounce } from "react-use";

const All = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tutors, setTutors] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [val, setVal] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);

  useEffect(() => {
    getTutors();

    return () => {};
  }, [searchText, limit, offset]);
  const [, cancel] = useDebounce(
    () => {
      setSearchText(val);
    },
    500,
    [val]
  );

  const deleteStudent = async (stId) => {
    if (confirm('Are you sure you want to delete?')) {
      localStorage.setItem(`hideStudent${stId}`, stId);
      window.location.reload();
    }
  };

  const getTutors = () => {
    dispatch({ type: "LOADING", payload: true });

    GET(
      `${tutorListing}?status=1&searchText=${searchText}&limit=${limit}&offset=${offset}`
    ).then((res) => {
      if (res.status === 200) {
        const { data } = res;

        setTutors(data.data.data.tutorsList);
        setTotalRecords(data.data.data.count);
      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
      }
      dispatch({ type: "LOADING", payload: false });
    });
  };

  const approve = (id) => {
    dispatch({ type: "LOADING", payload: true });

    GET(`${approveTeacher}/${id}`).then((res) => {
      console.log(res);
      if (res.status === 200) {
        dispatch({ type: "LOADING", payload: false });
        notification.success({ message: res.data.data.message });
        getTutors();
      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
        dispatch({ type: "LOADING", payload: false });
      }
    });
  };
  const statusChange = (id, status) => {
    dispatch({ type: "LOADING", payload: true });
    let params = {
      tutorId: id,
      isBlocked: status,
    };
    POST(`${blockUnblockTeacher}`, params).then((res) => {
      console.log(res);
      if (res.status === 200) {
        dispatch({ type: "LOADING", payload: false });
        notification.success({ message: res.data.data.message });
        getTutors();
      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
        dispatch({ type: "LOADING", payload: false });
      }
    });
  };
  const pagination = (page, pageSize) => {
    setOffset(page * pageSize - pageSize);
    setLimit(pageSize);
  };

  return (
    <div>
      <div className="listing">
        <div className="search-div">
          <input
            type="text"
            value={val}
            placeholder="Search"
            onChange={({ currentTarget }) => {
              setVal(currentTarget.value);
            }}
          />
        </div>
        {tutors.length ? (
          <>
            <Row className="m-0" gutter={[48, 32]}>
              {tutors &&
                tutors.map((val, ind) => (
                  <Col xs={24} sm={24} md={24} lg={24} xl={12} key={ind} style={{display : (localStorage.getItem(`hideStudent${val._id}`) === val._id) ? 'none' : 'block'}}>
                    <Card bordered={false} hoverable="true">
                      <div className="d-flex ">
                        <div className="mr-4">
                          <img src={ val.profileImage ||DefaultAvatar} className="avatar" />{" "}
                        </div>

                        <div className="content flex-grow-1">
                          <div className="d-flex justify-content-between">
                            <div className="text-capitalize">
                              {val?.firstName} {val?.lastName}{" "}
                              {!val.firstName ? val.username : null}{" "}
                            </div>

                            {val?.isBlocked ? (
                              <div className="blocked">Blocked</div>
                            ) : (
                              <div
                                className={
                                  val?.isAdminApproved ? "approve" : "not-approve"
                                }
                              >
                                {val?.isAdminApproved ? "Approved" : "Not Approved"}
                              </div>
                            )}
                          </div>
                          <small>
                            Interest:
                            {val.skills ? (
                              <strong className="text-capitalize">
                                {val.skills.join(", ")}
                              </strong>
                            ) : (
                              "N/A"
                            )}
                          </small>
                          <small>
                            Spoken Languages:
                            {val.languages ? (
                              <strong className="text-capitalize">
                                {val.languages.join(", ")}
                              </strong>
                            ) : (
                              "N/A"
                            )}
                          </small>
                          <small>
                            Time Zone:
                            {val?.timeZone ? (
                              <strong>{val.timeZone}</strong>
                            ) : (
                              "N/A"
                            )}
                          </small>
                        </div>
                      </div>

                      <Row className="m-0 end-btns" justify="end">
                        {!val?.isAdminApproved ? (
                          <Button
                            type="primary"
                            danger
                            onClick={() => approve(val._id)}
                          >
                            {" "}
                            Approve
                          </Button>
                        ) : null}
                        {!val?.isBlocked ? (
                          <Button
                            danger
                            onClick={() => statusChange(val._id, true)}
                          >
                            Block
                          </Button>
                        ) : (
                          <Button
                            danger
                            onClick={() => statusChange(val._id, false)}
                          >
                            Un-Block
                          </Button>
                        )}
                        <Button
                          className="edit-btn"
                          onClick={() =>
                            history.push(
                              `${history.location.pathname}/${val._id}`
                            )
                          }
                        >
                          Details
                        </Button>
                        <button style={{ marginLeft: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}
                                onClick={() => deleteStudent(val._id)}>Delete</button>
                      </Row>
                    </Card>
                  </Col>
                ))}
            </Row>
          </>
        ) : (
          <div className="illustrator">
            <div style={{ marginTop: "6rem", textAlign: "center" }}>
              <img src={Empty} height="400px" />
              <h2 className="text-center">Not Found</h2>
            </div>
          </div>
        )}
      </div>
      {tutors.length ? (
      <div className="d-flex justify-content-center">
        <Pagination
          total={totalRecords}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultPageSize={10}
          defaultCurrent={1}
          onChange={(page, pageSize) => pagination(page, pageSize)}
        />
      </div>
      ):null}
    </div>
  );
};

export default All;
