/* eslint-disable */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form, Modal, Popover, notification, Menu, Dropdown, Space, Spin } from 'antd';
import { CheckCircleTwoTone, CheckOutlined, DeleteFilled, DeleteOutlined, FileTextOutlined, UserOutlined, FieldTimeOutlined, WarningOutlined, CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//moment
import moment from 'moment'
// style
import './style.css';
import dayjs from 'dayjs';
import { GET, POST, PUT } from '../../../../apiClient';
// Editable Context
const EditableContext = React.createContext();
const token = JSON.parse(localStorage.getItem('token'));
import data from './constants';
const Scheduler = () => {
  const [studentDetails, setStudentDetails] = useState({
    name: '',
    country: '',
    subjects: []
  });
  // const [columns, setColumn] = useState(getColumns(new Date()));

  const addDate = (indate, num) => {
    var date = new Date(indate)

    return date.setDate(date.getDate() + num)
  }

  const getColumns = (date) => {
    //debugger
    let date1 = new Date(date.valueOf()) 
    let date2 = new Date(date.valueOf());
    const columns = [
      {
        title: '',
        dataIndex: 'slot',
        width: '100px',
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 0)).getDate()}</span> Mon</span>,
        dataIndex: 'Monday',
        date_: addDate(date2, 0),
        value: '1',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 1)).getDate()}</span> Tue</span>,
        dataIndex: 'Tuesday',
        date_: addDate(date2, 1),
        value: '2',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 2)).getDate()}</span> Wed</span>,
        dataIndex: 'Wednesday',
        date_: addDate(date2, 2),
        value: '3',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 3)).getDate()}</span> Thu</span>,
        dataIndex: 'Thursday',
        date_: addDate(date2, 3),
        value: '4',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 4)).getDate()}</span> Fri</span>,
        dataIndex: 'Friday',
        date_: addDate(date2, 4),
        value: '5',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 5)).getDate()}</span> Sat</span>,
        dataIndex: 'Saturday',
        date_: addDate(date2, 5),
        value: '6',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      },
      {
        title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 6)).getDate()}</span> Sun</span>,
        dataIndex: 'Sunday',
        date_: addDate(date2, 6),
        value: '0',
        render: (item, record) => data.length >= 1 ? (
          item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-available">Available</span></Dropdown> : (item.text == 'Leave' ? <Dropdown overlay={menu2} trigger={['click']} placement="bottomLeft" arrow><span className="tag-color-leave">{item.text}</span></Dropdown> : <span>{item.text}</span>)))
            : '') : null
      }
    ];
    // setColumn(columns)
    return columns;
  };
  const [columns, setColumn] = useState(getColumns(startOfWeek(new Date())));
  const [dataSource, setDataSource] = useState(data);
  const [record, setRecord] = useState({
    start: '',
    end: '',
    day: '',
    key: ''
  });
  const [objRecord, setObjRecord] = useState({
    start: '',
    end: '',
    day: '',
    key: '',
    value: ''
  });
  const [delRecord, setDelRecord] = useState({
    start: '',
    end: '',
    day: '',
    key: ''
  })
  const [dataBody, setDataBody] = useState([]);
  const [leaveBody, setLeaveBody] = useState([]);
  const [leaveDeleteBody, setLeaveDeleteBody] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [menuKey, setMenuKey] = useState(-1);
  const [weekDate, setWeekDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState([]);
  // const [student, setStudentDetails] = useState({
  //   name: '',
  //   country: '',
  //   subjects: []
  // })
  const [appointments, setAppointments] = useState();
  // function startOfWeek(date) {
  //   var diff = date.getUTCDate() - date.getUTCDay() + (date.getUTCDay() === 0 ? -6 : 1);
  //   let udate= new Date(date.setUTCDate(diff)).setUTCHours(0);
    
  //   return new Date(udate)
  // }
  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff)); 
  }

  const [iDate, setInitialDate] = useState(startOfWeek(new Date()));
  // Hammad Start
  const prevWeek = () => {
    let myDate = new Date(iDate);
    myDate.setDate(myDate.getDate() - 7);
    
    setInitialDate(myDate);
    let newCol = getColumns(myDate);
    setColumn(newCol);
  };

  const nextWeek = () => {
    //debugger
    let newDate = dayjs(iDate).add(7, "day").toDate();
  
    const colDate = newDate;
    setInitialDate(newDate);
    let newCol = getColumns(colDate);
    setColumn(newCol);
  };
  // Hammad end

  // useEffect(() => {
  //   const now=new Date()
  //   if(weekDate==now){
  //     console.log('I was here')
  //     return
  //   } else {
  //     getData()
  //   }
  // }, [weekDate, columns])

  const openNotificationWithIcon = type => {
    if (type == 'success') {
      notification[type]({
        message: 'Success',
        description:
          'The schedule is updated.',
      });
    } else {
      notification[type]({
        message: 'Error',
        description:
          'Sorry the schedule cannot updated.',
      });
    }
  };

  const getData = async () => {
    var tempData = [...data];
    setLoading(true)

    let d = new Date(iDate)
    d.setHours(0,0,0,0)
    d.toUTCString()
    let n = d.toISOString()
    //debugger
    var offSet = -(new Date().getTimezoneOffset() / 60)
  
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    try {
      let result = await Promise.all([
        GET('tutor/get-schedule'),
        GET(`get-appointment-by-id?teacherID=${userInfo.loginId}&weekDate=${n}`),
        GET(`get-leaves-by-id?teacherID=${userInfo.loginId}&weekDate=${n}`)
      ]);
    
      if (result[0]) {
        let databody = JSON.parse(JSON.stringify(result[0].data.data));
        let arr1 = result[0].data.data;
        setDataBody([...databody]);

        arr1.forEach(item => {
          //debugger
          let startHourTZ = Number(item.startHour) + Number(offSet);
          let startMinuteTZ = item.startMinute;

          if (startHourTZ >= 24) {
            if (item.weekday==6) {
              item.weekday=0
            } else {
              item.weekday = item.weekday+1
            }
            startHourTZ= startHourTZ%24
          } else if (startHourTZ < 0) {
            if (item.weekday==0) {
              item.weekday=6
            } else {
              item.weekday= item.weekday-1
            }
            startHourTZ= 24+startHourTZ
          }

          let ind = tempData.findIndex((x) => {
            return (x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ))
          })
          let obj = tempData.find((y) => {
            return (y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ))
          })

          // let dateTZ = -(new Date().getTimezoneOffset()/30)
          // if (dateTZ > ind) {
          //   ind=45-(dateTZ-ind)
          // } else {
          //   ind=dateTZ
          // }

          let day = ''
          switch (item.weekday) {
            case 0:
              day = 'Sunday';
              break;
            case 1:
              day = 'Monday';
              break;
            case 2:
              day = 'Tuesday';
              break;
            case 3:
              day = 'Wednesday';
              break;
            case 4:
              day = 'Thursday';
              break;
            case 5:
              day = 'Friday';
              break;
            case 6:
              day = 'Saturday';
              break;

            default:
              break;
          }
          item.text = 'Available';
          const updatedObj = Object.assign({ [day]: item }, obj);
          tempData.splice(ind, 1, updatedObj);
          setDataSource([...tempData]);
        })
      }
      if (result[1]) {
        let arr = [];
        result[1].data.data.forEach(curr => arr.push({
          schedule: curr.schedule,
          isBooked: curr.isBooked,
          student: curr.student[0]
        }));

        arr.forEach(item => {
          let startHourTZ = Number(item.schedule.startHour) + Number(offSet)
          let startMinuteTZ = item.schedule.startMinute;
          if (startHourTZ >= 24) {
            if (item.schedule.weekday==6) {
              item.schedule.weekday=0
            } else {
              item.schedule.weekday = item.schedule.weekday+1
            }
            startHourTZ= startHourTZ%24
          } else if (startHourTZ < 0) {
            if (item.schedule.weekday==0) {
              item.schedule.weekday=6
            } else {
              item.schedule.weekday= item.schedule.weekday-1
            }
            startHourTZ= 24+startHourTZ
          }

          var ind = tempData.findIndex((x) => {
            return (x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ));
          });
          var obj = tempData.find((y) => {
            return (y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ));
          });

          let day = ''
          switch (item.schedule.weekday) {
            case 0:
              day = 'Sunday';
              break;
            case 1:
              day = 'Monday';
              break;
            case 2:
              day = 'Tuesday';
              break;
            case 3:
              day = 'Wednesday';
              break;
            case 4:
              day = 'Thursday';
              break;
            case 5:
              day = 'Friday';
              break;
            case 6:
              day = 'Saturday';
              break;

            default:
              break;
          }

          if (item.isBooked) {
            item.text = 'Scheduled';
            delete obj[day]
            const updatedObj = Object.assign({ [day]: item }, obj)
            tempData.splice(ind, 1, updatedObj)
            setDataSource([...tempData])
          } else {
            item.text = 'Pending';
            delete obj[day]
            const updatedObj = Object.assign({ [day]: item }, obj)
            tempData.splice(ind, 1, updatedObj)
            setDataSource([...tempData])
          }
          setAppointments([...arr])
        })
      }
      if (result[2]) {
        let arr = []
        result[2].data.data.forEach(curr => arr.push({
          schedule: curr.schedule,
          _id: curr._id
        }))
        //let tempData = [...dataSource];
        arr.forEach(item => {
          let startHourTZ = Number(item.schedule.startHour) + Number(offSet)
          let startMinuteTZ = item.schedule.startMinute
          if (startHourTZ >= 24) {
            if (item.schedule.weekday==6) {
              item.schedule.weekday=0
            } else {
              item.schedule.weekday = item.schedule.weekday+1
            }
            startHourTZ= startHourTZ%24
          } else if (startHourTZ < 0) {
            if (item.schedule.weekday==0) {
              item.schedule.weekday=6
            } else {
              item.schedule.weekday= item.schedule.weekday-1
            }
            startHourTZ= 24+startHourTZ
          }

          var ind = tempData.findIndex((x) => {
            return (x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ))
          })
          var obj = tempData.find((y) => {
            return (y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ))
          })

          let day = ''
          switch (item.schedule.weekday) {
            case 0:
              day = 'Sunday'
              break;
            case 1:
              day = 'Monday'
              break;
            case 2:
              day = 'Tuesday'
              break;
            case 3:
              day = 'Wednesday'
              break;
            case 4:
              day = 'Thursday'
              break;
            case 5:
              day = 'Friday'
              break;
            case 6:
              day = 'Saturday'
              break;

            default:
              break;
          }
          item.text = 'Leave'
          delete obj[day]
          const updatedObj = Object.assign({ [day]: item }, obj)
          tempData.splice(ind, 1, updatedObj)
          setDataSource([...tempData])
          setLeaveDetails([...arr])
        })
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData();
  }, [iDate]);

  useEffect(() => {
    if (record && record.start !== '' && record.day !== '') {
      handleAdd(record.day, record.key)
    }
  }, [record]);

  useEffect(() => {
    if (delRecord) {
      handleDelete(delRecord.day, delRecord.key);
    }
  }, [delRecord])

  useEffect(() => {
    if (objRecord) {
      if (menuKey == 0) {
        handleLeave(objRecord.day);
      }
      if (menuKey == 1) {
        handlePermanentlyDelete(objRecord.day);
      }
      if (menuKey == 2) {
        handleLeaveDelete(objRecord.day);
      }
    }
  }, [objRecord]);
  // Handle Delete
  const handleDelete = (day, key, slot, val) => {
    console.log("This is delobjrecord", delRecord)

    let scheduleStart = new Date(delRecord.start)
    //let scheduleEnd = new Date(record.end)
    let weekdayTZ = scheduleStart.getUTCDay()
    let startHourTZ= scheduleStart.getUTCHours()
    let startMinuteTZ= scheduleStart.getUTCMinutes()

    let weekday;
    switch (day) {
      case 'Sunday':
        weekday = 0;
        break;
      case 'Monday':
        weekday = 1;
        break;
      case 'Tuesday':
        weekday = 2;
        break;
      case 'Wednesday':
        weekday = 3;
        break;
      case 'Thursday':
        weekday = 4;
        break;
      case 'Friday':
        weekday = 5;
        break;
      case 'Saturday':
        weekday = 6;
        break;

      default:
        break;
    }
    const arr = [...dataBody];

    const ind = dataSource.findIndex((item) => {
      return item.key == key;
    });
    const ind2 = arr.findIndex((item) => {
      return (item.startHour==startHourTZ && item.startMinute==startMinuteTZ) && item.weekday==weekdayTZ;
    });
    const obj = dataSource.find(item => {
      return item.key == key && item.hasOwnProperty(day);
    });

    if(obj) {
      if (obj[day].text == 'Available' || obj[day].text == 'Pending' || obj[day].text == 'Scheduled' || obj[day].text == 'Leave') {
        return;
      } else {
        delete obj[day];
        // dataSource.splice(ind, 1, obj);
        setDataSource([...dataSource]);
  
        arr.splice(ind2, 1);
        setDataBody([...arr]);
      }
    }

    setDelRecord(null)
  };
  // Handle Add
  const handleAdd = (day, key) => {
    let temporary = [...dataSource]

    const ind = temporary.findIndex((item) => {
      return item.key == key
    })
    const obj = temporary.find((item) => {
      return item.key == key
    })

    if (obj) {
      if (obj[day]) {
        if (obj[day].text === 'Available' || obj[day].text === 'Pending' || obj[day].text === 'Leave' || obj[day].text === 'Scheduled') {
          return
        }
      }
  
      const updatedObj = Object.assign({ [day]: { text: <CheckOutlined /> } }, obj)
      temporary.splice(ind, 1, updatedObj)
  
      let weekday;
      switch (day) {
        case 'Sunday':
          weekday = 0
          break;
        case 'Monday':
          weekday = 1
          break;
        case 'Tuesday':
          weekday = 2
          break;
        case 'Wednesday':
          weekday = 3
          break;
        case 'Thursday':
          weekday = 4
          break;
        case 'Friday':
          weekday = 5
          break;
        case 'Saturday':
          weekday = 6
          break;
  
        default:
          break;
      }
  
      let scheduleStart = new Date(record.start)
      let scheduleEnd = new Date(record.end)
  
      console.log("This is mozilla", scheduleStart)
      
  
      const body = {
        calID: Number(updatedObj.key),
        weekday: scheduleStart.getUTCDay(),
        startHour: scheduleStart.getUTCHours(),
        startMinute: scheduleStart.getUTCMinutes(),
        endHour: scheduleEnd.getUTCHours(),
        endMinute: scheduleEnd.getUTCMinutes()
      }
  
      console.log("This is create", body)
  
      setDataBody([...dataBody, body])
      setDataSource([...temporary])
    }
  };

  const handleLeave = (day) => {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const ind = dataSource.findIndex((item) => {
      return item.key == objRecord.key;
    });

    const obj = dataSource.find((item) => {
      return item.key == objRecord.key;
    });

    if (obj[day].text != 'Available') {
      setObjRecord(null);
      setMenuKey(-1);
      return;
    }

    let now = new Date();
    let start = objRecord.start;

    if (start < now) {
      notification['warning']({
        message: 'Error',
        description:
          'You cannot mark it as leave when the date has been passed.',
      });
      setObjRecord(null);
      setMenuKey(-1);
      return;
    }

    delete obj[day];
    const updatedObj = Object.assign({ [day]: { text: "Leave" } }, obj);
    dataSource.splice(ind, 1, updatedObj);

    const body = {
      calID: Number(updatedObj.key),
      startDate: moment(objRecord.start).utc(),
      endDate: moment(objRecord.start).utc(),
      teacherID: `${userInfo.loginId}`
    };
    setObjRecord(null);
    setMenuKey(-1);
    setLeaveBody([...leaveBody, body]);
    setDataSource([...dataSource]);
  };

  const handlePermanentlyDelete = (day) => {
    let weekday;
    switch (objRecord.day) {
      case 'Sunday':
        weekday = 0;
        break;
      case 'Monday':
        weekday = 1;
        break;
      case 'Tuesday':
        weekday = 2;
        break;
      case 'Wednesday':
        weekday = 3;
        break;
      case 'Thursday':
        weekday = 4;
        break;
      case 'Friday':
        weekday = 5;
        break;
      case 'Saturday':
        weekday = 6;
        break;

      default:
        break;
    }

    let weekdayTZ = objRecord.start.getUTCDay();

    console.log("Mozilla permanently delete ", weekdayTZ)

    const ind = dataSource.findIndex((item) => {
      return item.key == objRecord.key;
    });
    const ind2 = dataBody.findIndex((item) => {
      return item.calID == objRecord.key && item.weekday == weekdayTZ;
    });

    const obj = dataSource.find((item) => {
      return item.key == objRecord.key;
    });

    if (obj[day].text != 'Available') {
      setObjRecord(null);
      setMenuKey(-1);
      return;
    }

    delete obj[day];

    dataBody.splice(ind2, 1);

    setObjRecord(null);
    setMenuKey(-1);
    setDataSource([...dataSource]);
    setDataBody([...dataBody]);
  };

  const handleLeaveDelete = (day) => {
  
    let weekday;
    switch (objRecord.day) {
      case 'Sunday':
        weekday = 0;
        break;
      case 'Monday':
        weekday = 1;
        break;
      case 'Tuesday':
        weekday = 2;
        break;
      case 'Wednesday':
        weekday = 3;
        break;
      case 'Thursday':
        weekday = 4;
        break;
      case 'Friday':
        weekday = 5;
        break;
      case 'Saturday':
        weekday = 6;
        break;

      default:
        break;
    }
    const ind = dataSource.findIndex((item) => {
      return item.key == objRecord.key;
    });
    const obj1 = leaveDetails.find((item) => {
      return item.schedule.calID == objRecord.key && item.schedule.weekday == weekday;
    });
    const obj2 = dataSource.find((item) => {
      return item.key == objRecord.key;
    });

    if (obj1) {
      setLeaveDeleteBody([...leaveDeleteBody, obj1._id]);
    }
   

    delete obj2[day];

    setObjRecord(null);
    setMenuKey(-1);
    setDataSource([...dataSource]);
  };

  const handleMenuClick = (e) => {
    setMenuKey(e.key);
  };

  const handleStudentDetails = (day, key) => {
    let weekday;
    switch (day) {
      case 'Sunday':
        weekday = 0;
        break;
      case 'Monday':
        weekday = 1;
        break;
      case 'Tuesday':
        weekday = 2;
        break;
      case 'Wednesday':
        weekday = 3;
        break;
      case 'Thursday':
        weekday = 4;
        break;
      case 'Friday':
        weekday = 5;
        break;
      case 'Saturday':
        weekday = 6;
        break;

      default:
        break;
    }
    const obj = appointments.find(item => {
      if (typeof item.schedule === 'undefined') {
        return;
      }
      return item.schedule.weekday == weekday && item.schedule.calID == String(key);
    });

    setStudentDetails({
      name: obj.student.firstName + ' ' + obj.student.lastName,
      country: obj.student.country,
      subjects: obj.student.subjects
    });
  
  };

  const handleSave = async () => {
    setDataSource(data)
    let databody= JSON.parse(JSON.stringify(dataBody))  
    setDataBody([])

    console.log("This is databody: ", data)


    try {
      let result = await Promise.all([
        POST('tutor/create-schedule', databody),
        POST('create-leaves', leaveBody),
        POST('delete-tutor-leaves', leaveDeleteBody)
      ])
      if (result) {
        openNotificationWithIcon('success')
        getData()
      }
    } catch (err) {
      openNotificationWithIcon('warning')
    }
  }

  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const convertMinute12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${minutes}`;
  };

  const getDayandDate = (day, slot) => {
    //debugger
    console.log("this is line 857", day)
    let d = new Date(day).getDate();
    let m = new Date(day).getMonth();
    let y = new Date(day).getFullYear();

    console.log("This is mozilla 862", d, m, y, slot)

    const tempHourStart = convertTime12to24(slot.split('-')[0]);
    const tempMinuteStart = convertMinute12to24(slot.split('-')[0]);

    const tempHourEnd = convertTime12to24(slot.split('-')[1]);
    const tempMinuteEnd = convertMinute12to24(slot.split('-')[1]);
    var now = new Date();
    const start = new Date(`${m + 1} ${d} ${y} ${tempHourStart}:${tempMinuteStart}`);
    const end = new Date(`${m + 1} ${d} ${y} ${tempHourEnd}:${tempMinuteEnd}`);

    console.log("This is mozilla : start and end", start, end)
    // if (start < now) { 
    //   start.setDate(start.getDate() + 7)
    // }
    // if (end < now)
    //   end.setDate(end.getDate() + 7)

    if (record.day == '') {
      setObjRecord(prevState => ({
        ...prevState,
        start,
        end
      }));
    
    } else {
      setRecord(prevState => ({
        ...prevState,
        start,
        end
      }));
    
    }

    return start
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0" icon={<CalendarOutlined style={{ color: '#982ee7' }} />}><span className="calendar-dropdown-text">Do you want to take a leave?</span></Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" icon={<DeleteOutlined style={{ color: '#f44336' }} />}><span className="calendar-dropdown-text">Do you want to delete this slot?</span></Menu.Item>
    </Menu>
  );

  const menu2 = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="2" icon={<DeleteOutlined style={{ color: '#f44336' }} />}><span className="calendar-dropdown-text">Do you want to delete?</span></Menu.Item>
    </Menu>
  );

  const mapcolumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.dataIndex,
        // onMouseLeave: () => setIsShown(false),
        // handleSave: handleSave,
        onMouseEnter: () => {
          const obj = dataSource.find(item => {
            return item.key == record.key && item.hasOwnProperty(col.dataIndex);
          });

          if (obj) {
            if (obj[col.dataIndex] == 'Scheduled') {
              handleStudentDetails(col.dataIndex, record.key);
            }
          }
        },
        onClick: () => {
          //debugger
          if (col.dataIndex === 'slot') {
            return
          }
         
          const obj = dataSource.find(item => {
            return item.key == record.key && item.hasOwnProperty(col.dataIndex);
          });

          if (obj) {
            if (obj[col.dataIndex].text == 'Pending' || obj[col.dataIndex].text == 'Available' || obj[col.dataIndex].text == 'Scheduled' || obj[col.dataIndex].text == 'Leave') {
              setRecord(prevState => ({
                ...prevState,
                day: '',
                key: ''
              }));
              getDayandDate(col.date_, record.display);
              setObjRecord(prevState => ({
                ...prevState,
                day: col.dataIndex,
                key: record.key
              }));
            } else {
              setRecord(prevState => ({
                ...prevState,
                day: '',
                key: ''
              }));
              setRecord(null);
              let start =getDayandDate(col.date_, record.display);
              setDelRecord(prevState => ({
                ...prevState,
                start: start,
                day: col.dataIndex,
                key: record.key
              }))
              //console.log("Start", delRecord )
              
            }
          } else {
            setObjRecord(null);
            getDayandDate(col.date_, record.display);
            setRecord(prevState => ({
              ...prevState,
              day: col.dataIndex,
              key: record.key
            }));
          }
        }
      }),
    };
  });

  function showConfirm () {
    Modal.confirm({
      title: 'Do you want to update the schedule?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk () {
        handleSave();
      },
      onCancel () {
        
      },
    });
  }

  var options = { month: 'long' };
  var clone = [...mapcolumns];
  return (
    <section id="main-content">
      <div className="container-fluid">
        <div className="calendar-page">
          <div className="DateT">
            <button type="button" className="ant-btn previousBtn" onClick={prevWeek}><span>Previous</span></button>
            <button type="button" className="ant-btn nextBtn" onClick={nextWeek}><span>Next</span></button>
            <h3 className="float-right my-3">{new Intl.DateTimeFormat('en-US', options).format(iDate)} {iDate.getFullYear()}</h3>
          </div>
          {loading ?
            <Space size="middle">
              <Spin
                size="large"
              />
              <Table
                columns={clone}
                dataSource={data}
                pagination={false}
                scroll={{ y: 375 }}
                bordered
                className="availability-calendar"
              />
            </Space>
            :
            <Table
              columns={mapcolumns}
              dataSource={dataSource}
              pagination={false}
              scroll={{ y: 375 }}
              bordered
              className="availability-calendar"
            />
          }
          <div className="btn-row"> <Button className="float-right primary-btn" onClick={showConfirm}>Save</Button></div>
        </div>
      </div>
    </section>
  );
};

export default Scheduler;