/* eslint-disable */ import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import './checkoutform.css';
import { notification } from "antd";


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = ({ dataBody, price, setPayment, handleSave }) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [payBtnHide, setPayBtnHide] = useState(false);
  const [tokenId, setTokenId] = useState('');
  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {

      setError(result.error.message);
    } else {
      setError(null);
      setTokenId(result.token.id);
      let isSubject = true;
      dataBody.forEach(item => {
        if (item.subject === undefined) {
          isSubject = false;
        }
      });
      if (isSubject) {
        // setPayBtnHide(true);
        setPayBtnHide(true);
        setPayment(true);
        // handleSave(result.token.id);
        // setPayBtnHide(false);
      }
      else {
        setPayBtnHide(false)
        notification['error']({
          message: 'Error',
          description: 'Please Select Subject',
        });
      }

    }
  };
  useEffect(() => {
    if (payBtnHide) {

      handleSave(tokenId);

    }


  }, [payBtnHide])


  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label htmlFor="card-element" style={{ fontWeight: 'bold' }}>
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
        <div className="card-errors" role="alert">{error}</div>
      </div>
      {!payBtnHide ? <button className="payment-btn" type="submit">$ {price} PAY </button> : null}
    </form>
  );
}


export default CheckoutForm;
