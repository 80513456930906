import socketIOClient from "socket.io-client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as types from "../../store/Actions/Types";
import { socketInitAction } from "../../store/Actions";
// import axios from '../../axios';

const baseUrl = "https://api.nidaulquran.com/";
// const baseUrl = "http://54.186.118.166:5500/";
// const baseUrl = "http://localhost:5500/";

let socket;

function SocketHandler({ info }) {
  let dispatch = useDispatch();

  const establishConnection = (info) => {
    socket = socketIOClient(baseUrl);
    socket.on("connect", () => {
      const userSocketData = socket.emit("userUpdate", {
        socketId: socket.id,
        userId: info.loginId,
        role: info.userRole,
      });
     
      dispatch(socketInitAction(types.SOCKET_INIT, socket));
    });
  };

  useEffect(() => {
    establishConnection(info);
  }, [info]);

  return null;
}
export default SocketHandler;
