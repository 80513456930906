/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { Table, Pagination, Space, Button, DatePicker,
  Modal, Tag, Select,  notification } from "antd";
import './payout.css';
import { useSelector, useDispatch } from "react-redux";

import ExportExcel from '../../../shared/ExcelExport/exportExcel';
import { adminTutorPayouts, adminPay } from '../../../../globalConstant';
import { POST } from '../../../../apiClient';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';


const { Option } = Select;


const Payout = ({ teacherId }) => {
  const columns = [
    {
      title: "Tutor Name",
      // dataIndex:"month",
      width: "20%",
      key: "month",
      render: (text, record) => (
        <Space size="middle" key={text.month}>
          {text.teacherData.firstName}{text.teacherData.lastName}
        </Space>


      )
    },
    {
      title: "Payout Month",
      // dataIndex:"month",
      width: "20%",
      key: "month",
      render: (text, record) => (
        <Space size="middle" key={text.month}>
          {moment({ month: text.month, year: text.year }).format('MMM, YYYY')}
        </Space>


      )
    },
    {
      title: "Payout Transfer Date",
      // dataIndex:"month",
      width: "20%",
      key: "month",
      render: (text, record) => (
        <Space size="middle" key={text.month}>

          {text.payoutTransferDate > 0 ? `${moment(text.payoutTransferDate * 1000).format('dddd, MMM Do YYYY')}` : 'N/A'}

        </Space>


      )
    },
    {
      title: "Payoneer Id",
      // dataIndex:"month",
      width: "15%",
      key: "month",
      render: (text, record) => (
        <Space size="middle" key={text.month}>

          {text.teacherData?.payoneerId ? <span>{text.teacherData?.payoneerId}</span>:<span>N/A</span>}

        </Space>


      )
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      width: "15%",
      key: "totalAmount"
    },
    {
      title: "Status",
      key: "isPayoutGiven",
      dataIndex: "isPayoutGiven",
      width: "20%",

      render: (text, record) => (
        <Space size="middle" key={text}>
          <Tag color={text ? 'green' : 'volcano'}>
            {text ? 'Paid' : 'Pending'}

          </Tag>
        </Space>


      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {text.isPayoutGiven ?
            <Tag color='green' >
              Already Paid
            </Tag>
            : 
            <>
            {
              text.hidePayButton ?
               null
              : <Button onClick={() => showModal(text, record)} type="primary" danger>Pay</Button>
            }
            </>
          }
        </Space>
      ),
    },

  ];
  console.log(teacherId);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [listing, setListing] = useState();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [payoutType, setPayoutType] = useState(undefined);
  const [record, setRecord] = useState({});
  const[month,setMonth]= useState(undefined);
  const[year,setYear]= useState(undefined);




  const handleOk = () => {
    dispatch({ type: "LOADING", payload: true });
    
    handleCancel();
   

    let params = {
      teacherID: teacherId,
      payoutID: record._id,
      transferDate: moment().unix()
    }



    POST(adminPay, params).then(res => {
     

      if (res.status === 200) {
        notification.success({ message: 'Payment Successfull' });
        const values = [...listing];    
        const index = values.findIndex(element => element._id == record._id);   
        values[index] = {...values[index],hidePayButton:true}
        setListing(values);
    

      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
      }

      dispatch({ type: "LOADING", payload: false });
    })
  }
  const [pagination, setPagination] = useState(
    {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  )
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    
    getTransactions();
  

  }, [limit, offset, payoutType,month]);

  const getTransactions = () => {
    dispatch({ type: "LOADING", payload: true });
    let params = {
      limit: limit,
      offset: offset,
      teacherID: teacherId,
      payoutType: payoutType,
      month,year
    }

    if (payoutType == undefined) delete params.payoutType;
    if (month == undefined) delete params.month;
    

    POST(adminTutorPayouts, params).then(res => {
     
      if (res.status === 200) {

        if (res.data.data.tutorPayouts.length > 0) {
          let currentMonthUnix = moment({
            month: moment().month(),
            year: moment().year(),
          }).unix();
          res.data.data.tutorPayouts.forEach((payout) => {
            let payoutUnix = moment({
              month: payout.month,
              year: payout.year
            }).unix();

            if (currentMonthUnix === payoutUnix) {
              payout.hidePayButton = true;
            } else {
              payout.hidePayButton = false;
            }
          });
        }
        const temp = [];
        res.data.data.tutorPayouts.forEach((val) => {
          temp.push(
            {
              TutorName:val.teacherData.firstName+" "+val.teacherData.lastName,
              PayoneerID:val.teacherData.payoneerId,
              PayoutMonth: moment({ month: val.month, year: val.year }).format('MMM, YYYY'),
              PayoutTransferDate: ( val.payoutTransferDate > 0) ? `${moment(val.payoutTransferDate * 1000).format('dddd, MMM Do YYYY')}` : 'N/A',
              Amount: val.totalAmount,
              Status: (val.isPayoutGiven) ? 'Paid' : 'Pending'
            }
          )
        })
        setData(temp)
        setListing(res.data.data.tutorPayouts);
        setTotalRecords(res.data.data.totalCount);
        setPagination({ ...pagination, total: res.data.data.totalCount });


      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
      }

      dispatch({ type: "LOADING", payload: false });
    })
  }
  const handleTableChange = (paginations, filters, sorter) => {
   
    setOffset((paginations.current * paginations.pageSize) - paginations.pageSize);
    setLimit(paginations.pageSize);
    setPagination({ ...pagination, current: paginations.current });
  };
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  }
  function handleChange(value) {

    if (value === "true")
      setPayoutType(true);
    else if (value === "false")
      setPayoutType(false);
    else setPayoutType(undefined);
  }
  const showModal = (val, rec) => {
    console.log(val, rec)
    setRecord(val);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const monthChanged=(date, dateString)=>{   
   
    const temp = moment(date).month();   
    if(!isNaN(temp)){
    setMonth(moment(date).month());
    setYear(moment(date).year());
    }
  }


  return (
    <div className="mx-3">
      <div className="py-3 float-right d-flex">
        <div>    <DatePicker onChange={monthChanged} picker="month" /></div>
        <div className="mx-2"><Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
          <Option value="undefined">All</Option>
          <Option value="true">Paid</Option>
          <Option value="false">Un-Paid</Option>
        </Select></div>
        {/* <div><DatePicker onChange={onChange} /></div> */}
        <div className="mr-2"><ExportExcel data={data} /> </div>
      </div>
      <Table

        columns={columns}
        rowKey={(record) => record._id}
        dataSource={listing}
        pagination={pagination}
        // loading={loading}
        onChange={handleTableChange}
      />

      <Modal
        title="Confirm"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="text-danger">Are you sure you want to pay??</p>
      </Modal>
    </div>
  );
};

export default Payout;
