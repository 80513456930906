/* eslint-disable */
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import "./style.css";
import axios from "../../../../axios";

import getUserInfo from "../../../../shared/Helpers/getUserInfo";
import {
  Tabs, Empty, message, Modal, Spin, Avatar, TimePicker,
  Radio, Form, Input, Button, Checkbox, Space, DatePicker, Table, notification, Popover, Select
} from "antd";

import msgIcon from "../../../../assets/images/messagewhite-icon.png";
import CallIcon from "../../../../assets/images/videocall-icon.png";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import callerTune from "../../../../assets/audio/calling.mp3";
import { GET, POST, PUT } from '../../../../apiClient';
import { freeTrialVersion ,configuration} from '../../../../globalConstant';
import { VideoCameraOutlined, LoadingOutlined, WarningOutlined, CheckCircleTwoTone, FileTextOutlined, UserOutlined, FieldTimeOutlined, RightOutlined, LeftOutlined, TransactionOutlined } from "@ant-design/icons";

import { Option } from "antd/lib/mentions";
import dayjs from "dayjs";
// import { toast } from "react-toastify";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import Moment from "react-moment";
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../CheckoutForm/checkoutform';
import data from './constants';

const stripePromise = loadStripe('pk_live_51HXSdoBtCxfPCZsZllzaBFdOYTNUwMna16ipLFjKLPfuQQAJpoUNfS2PvhOaahBuC7cfKhazT13tpGQ4eVkhh6g000QdIiX6K1');

const { TabPane } = Tabs;
const addDate = (indate, num) => {
  var date = new Date(indate)

  return date.setDate(date.getDate() + num)
}



// const date = new Date()
// const token = JSON.parse(localStorage.getItem('token'));
const getColumns = (date) => {
  let date1 = new Date(date.valueOf())
  let date2 = new Date(date.valueOf());
  //let sunday = new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 6)).getDate()
  const columns = [
    {
      title: '',
      dataIndex: 'slot',
      width: '100px',
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 0)).getDate()}</span> Mon</span>,
      dataIndex: 'Monday',
      date_: addDate(date2, 0),
      value: '1',
      render: (item, record) => data.length >= 1 ? (

        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date.getDate() - (date1.getDay() - 1) + 1)).getDate()}</span> Tue</span>,
      dataIndex: 'Tuesday',
      date_: addDate(date2, 1),
      value: '2',
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 2)).getDate()}</span> Wed</span>,
      dataIndex: 'Wednesday',
      date_: addDate(date2, 2),
      value: '3',
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 3)).getDate()}</span> Thu</span>,
      dataIndex: 'Thursday',
      date_: addDate(date2, 3),
      value: '4',
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 4)).getDate()}</span> Fri</span>,
      dataIndex: 'Friday',
      date_: addDate(date2, 4),
      value: '5',
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 5)).getDate()}</span> Sat</span>,
      dataIndex: 'Saturday',
      value: '6',
      date_: addDate(date2, 5),
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    },
    {
      title: <span><span>{new Date(date1.setDate(date1.getDate() - (date1.getDay() - 1) + 6)).getDate()}</span> Sun</span>,
      dataIndex: 'Sunday',
      date_: addDate(date2, 6),
      value: '0',
      //date_: `${new Date(date2.setDate(date2.getDate() - (date2.getDay() - 1) +6)).getDate()}`,
      render: (item, record) => data.length >= 1 ? (
        item ? item.text == 'Pending' ? <Popover placement="topLeft" content={<div><p><UserOutlined /> {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-pending">{item.text}</span></Popover> : (item.text == 'Scheduled' ? <Popover placement="topLeft" content={<div><p><UserOutlined />  {item.student ? item.student.firstName + ' ' + item.student.lastName : ''}</p><p><FieldTimeOutlined /> {item.student ? item.student.country : ''}</p><p><FileTextOutlined /> {item.student ? item.student.subjects[0] : ''}</p></div>} title="Student Details"><span className="tag-color-approved">{item.text}</span></Popover> : (item.text == 'Available' ? <span className="tag-color-available">Available</span> : (item.text == 'Leave' ? <span className="tag-color-leave">{item.text}</span> : <span>{item.text}</span>)))
          : '') : null
    }
  ];
  return columns
}

const Landing = () => {
  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { confirm } = Modal;
  let userData = useSelector((state) => state.studentProfileReducer);

  // let SocketData = useSelector((state) => state.socketReducer.data);

  const CALL_URL = "https://video.nidaulquran.com/";
  const history = useHistory();
  let audio = new Audio(callerTune);
  const photo =
    "https://emeraldpsychiatry.com/wp-content/uploads/2018/05/dummy_players.png";

  const [isAllTutors, setAllTutors] = useState([]);
  const [isAllInvited, setAllInvited] = useState([]);
  const [isUserInfo, setUserInfo] = useState({});
  const [isInvitedId, setInvitedId] = useState(null);
  const [isPendingInvitations, setPendingInvitations] = useState([]);
  const [isAllRecommendations, setAllRecommendations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState('');
  const [isFreeTrialRequestExists, setIsFreeTrialRequestExists] = useState(false);

  const [dataSource, setDataSource] = useState(data);
  const [selectedTab, setSelectedTab] = useState("");
  const [tabState, setTabState] = useState(false);
  const [profileImg, setProfileImg] = useState([]);
  const [rImg, setRImg] = useState([]);
  const [siImg, setSiImg] = useState([]);
  const [riImg, setRiImg] = useState([]);
  const [record, setRecord] = useState({
    start: '',
    end: '',
    day: '',
    key: ''
  });
  const [visible, setVisible] = useState(false);
  const [visible_, setVisible_] = useState(false);
  const [teacherID, setTeacherID] = useState({
    tid: '',
    contactID: '',
    subjects: []
  });
  const [dataBody, setDataBody] = useState([]);
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  const [price, setPrice] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [payment, setPayment] = useState(false);
  const [packageValue, setPackageValue] = useState(1);
  const [breakDown, setBreakDown] = useState({});
  const [stripeToken, setStripeToken] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [isTrialModal, setIsTrialModal] = useState(false);
  const [weekDate, setWeekDate] = useState(new Date());
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [iDate, setInitialDate] = useState(startOfWeek(new Date()));
  const [columns, setColumn] = useState(getColumns(startOfWeek(new Date())));
  const [hoursRestriction, setHoursRestriction] = useState(0);

  let location = useLocation();


  const showTrialModal = () => {
    setIsTrialModal(true);
  };

  const handleOkTrialModel = () => {
    setIsTrialModal(false);
  };

  const handleCancelTrialModal = () => {
    setIsTrialModal(false);
  };

  const changePackage = (e) => {
    setPackageValue(e.target.value);


  };
  const getPricing = () => {

    if (dataBody.length === 0) {
      notification['info']({
        message: 'Please Select Slots',
      });
      return;
    }
    setLoadingSpin(true);
    setVisible_(true);
    // confirm();
    let params = {
      teacherId: teacherID.tid,
      slots: dataBody,
      package: packageValue

    };
    POST('payment/breakdown', params)
      .then(res => {

        setBreakDown(res.data.data);
        setPrice(res.data.data.totalCharges);
        setLoadingSpin(false);

        setInitialDate(startOfWeek(new Date()));

      })
      .catch(err => {
        console.log('This is error', err)
        setInitialDate(startOfWeek(new Date()));
      });
  };

  useEffect(() => {
    if (teacherID.tid != '') {
      getPricing();
    }
  }, [packageValue]);

  useEffect(() => {
    setInitialDate(startOfWeek(new Date()));
    setColumn(getColumns(startOfWeek(new Date())))
  }, [visible, visible_])

  const getAllTutors = async (user) => {
    try {
      setLoading(true);
      const tutors = await GET(
        `contact/allcontacts?loginId=${user.loginId}&role=${user.userRole}`
      );
      if (tutors && tutors.data && tutors.data.data) {


        tutors.data.data.forEach(element => {

          if (element?.nextClass?.length > 0) {
            const start = element.nextClass[0].startDateUnix;
            const end = element.nextClass[0].endDateUnix;

            const before = moment(start * 1000).subtract(element.joinClassButton, "minutes").unix();
            const cu = moment().unix();

            if (cu > before && cu < end) {

              element.hideBtn = false;
            }
            else {

              element.hideBtn = true;
            }

          }
          else {
            element.hideBtn = true;
          }

        });






        const allTutorsData = tutors.data.data;
        setAllTutors(allTutorsData);
        const userImgs = allTutorsData.map((item, index) => item.profileImage);
        setProfileImg(userImgs);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        setLoading(false);
        toast.notify(
          "Request Timed out. Please Check your internet connectivity."
        );
      }
    }
  };

  const getInvitedUsers = async (user) => {

    try {
      setLoading(true);
      const users = await GET(
        `contact/invitations?loginId=${user.loginId}&role=${user.userRole}`
      );
      setLoading(false);
      if (users.data) {

        const allInvited = users.data.data;
        setAllInvited(allInvited);
        const userImgs = allInvited.map((item, index) => item.profileImage);
        setSiImg(userImgs);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };




  useEffect(() => {

    setSelectedTab(location.tab);
    setTabState(true);
  }, [location]);

  function startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  const prevWeek = () => {
    let myDate = new Date(iDate);
    myDate.setDate(myDate.getDate() - 7);

    setInitialDate(myDate);
    let newCol = getColumns(myDate);
    setColumn(newCol);
  };

  const nextWeek = () => {
    let newDate = dayjs(iDate).add(7, "day").toDate();
    // console.log(newDate);
    const colDate = newDate;
    setInitialDate(newDate);
    let newCol = getColumns(colDate);
    setColumn(newCol);
  };

  // const prevWeekClone = () => {
  //   let myDate = new Date(weekDate)
  //   setWeekDate(myDate.setDate(myDate.getDate() - 8))
  // }

  // const nextWeekClone = () => {
  //   setWeekDate(dayjs(iDate).add(6, "day").toDate())
  // }
  // Hammad end

  // useEffect(() => {
  //   getData()
  // }, [iDate])
  // Hammad end

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Appointments',
      description:
        'Appointments have been created. The email has been sent to the teacher.',
    });
  };

  useEffect(() => {
    if (visible == false) {
      setTeacherID('');
      setDataBody([]);
      setDataSource(data);
      setInitialDate(startOfWeek(new Date()));
    }
  }, [visible]);
  var tempData = [...data];

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    getData();
  }, [teacherID, iDate]);

  useEffect(() => {
    if (record.start !== '') {
      // console.log('Before going to handle add', record.start);
      handleAdd(record.day, record.key);
    }
  }, [record]);

  const getData = async () => {
    if (!teacherID.tid) {
      return
    }
    setLoadingSpin(true)

    let d = new Date(iDate)
    d.setHours(0, 0, 0, 0)
    d.toUTCString()

    let n = d.toISOString()
    let offSet = -(iDate.getTimezoneOffset() / 60)
    try {
      let result = await Promise.all([
        GET(`tutor/get-schedule?teacherId=${teacherID.tid}`),
        GET(`get-appointment-by-id?teacherID=${teacherID.tid}&weekDate=${n}`),
        GET(`get-leaves-by-id?teacherID=${teacherID.tid}&weekDate=${n}`)
      ]);

      if (result[0]) {
        let arrClone = JSON.parse(JSON.stringify(result[0].data.data))

        arrClone.forEach(item => {
          let startHourTZ = Number(item.startHour) + Number(offSet)
          let startMinuteTZ = item.startMinute
          if (startHourTZ >= 24) {
            if (item.weekday == 6) {
              item.weekday = 0
            } else {
              item.weekday = item.weekday + 1
            }
            startHourTZ = startHourTZ % 24
          } else if (startHourTZ < 0) {
            if (item.weekday == 0) {
              item.weekday = 6
            } else {
              item.weekday = item.weekday - 1
            }
            startHourTZ = 24 + startHourTZ
          }

          let ind = tempData.findIndex((x) => {
            return x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ)
          })
          let obj = tempData.find((y) => {
            return y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ)
          })

          let day = ''
          switch (item.weekday) {
            case 0:
              day = 'Sunday'
              break;
            case 1:
              day = 'Monday'
              break;
            case 2:
              day = 'Tuesday'
              break;
            case 3:
              day = 'Wednesday'
              break;
            case 4:
              day = 'Thursday'
              break;
            case 5:
              day = 'Friday'
              break;
            case 6:
              day = 'Saturday'
              break;

            default:
              break;
          }
          item.text = 'Available';
          const updatedObj = Object.assign({ [day]: item }, obj)
          tempData.splice(ind, 1, updatedObj)
          setDataSource([...tempData])
        })
      }
      if (result[1]) {
        let cloneArr = result[1].data.data
        let arr = []

        cloneArr.forEach(curr => {
          arr.push({
            schedule: curr.schedule,
            isBooked: curr.isBooked,
            student: curr.student[0],
            contactId: curr.contactID
          })
        })
        //let tempData = [...dataSource];
        arr.forEach(item => {
          if (item.contactId === teacherID.contactID) {
            let startHourTZ = Number(item.schedule.startHour) + Number(offSet)
            let startMinuteTZ = item.schedule.startMinute
            if (startHourTZ >= 24) {
              if (item.schedule.weekday == 6) {
                item.schedule.weekday = 0
              } else {
                item.schedule.weekday = item.schedule.weekday + 1
              }
              startHourTZ = startHourTZ % 24
            } else if (startHourTZ < 0) {
              if (item.schedule.weekday == 0) {
                item.schedule.weekday = 6
              } else {
                item.schedule.weekday = item.schedule.weekday - 1
              }
              startHourTZ = 24 + startHourTZ
            }

            var ind = tempData.findIndex((x) => {
              return x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ)
            })
            var obj = tempData.find((y) => {
              return y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ)
            })

            let day = ''
            switch (item.schedule.weekday) {
              case 0:
                day = 'Sunday'
                break;
              case 1:
                day = 'Monday'
                break;
              case 2:
                day = 'Tuesday'
                break;
              case 3:
                day = 'Wednesday'
                break;
              case 4:
                day = 'Thursday'
                break;
              case 5:
                day = 'Friday'
                break;
              case 6:
                day = 'Saturday'
                break;

              default:
                break;
            }

            if (item.isBooked) {
              item.text = 'Scheduled';
              delete obj[day]
              const updatedObj = Object.assign({ [day]: item }, obj)
              tempData.splice(ind, 1, updatedObj)
              setDataSource([...tempData])
            } else {
              delete obj[day]
              item.text = 'Pending'
              const updatedObj = Object.assign({ [day]: item }, obj)
              tempData.splice(ind, 1, updatedObj)
              setDataSource([...tempData])
            }
          } else {
            let startHourTZ = Number(item.schedule.startHour) + Number(offSet)
            let startMinuteTZ = item.schedule.startMinute
            if (startHourTZ >= 24) {
              if (item.schedule.weekday == 6) {
                item.schedule.weekday = 0
              } else {
                item.schedule.weekday = item.schedule.weekday + 1
              }
              startHourTZ = startHourTZ % 24
            } else if (startHourTZ < 0) {
              if (item.schedule.weekday == 0) {
                item.schedule.weekday = 6
              } else {
                item.schedule.weekday = item.schedule.weekday - 1
              }
              startHourTZ = 24 + startHourTZ
            }

            var ind = tempData.findIndex((x) => {
              return x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ)
            })
            var obj = tempData.find((y) => {
              return y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ)
            })

            let day = ''
            switch (item.schedule.weekday) {
              case 0:
                day = 'Sunday'
                break;
              case 1:
                day = 'Monday'
                break;
              case 2:
                day = 'Tuesday'
                break;
              case 3:
                day = 'Wednesday'
                break;
              case 4:
                day = 'Thursday'
                break;
              case 5:
                day = 'Friday'
                break;
              case 6:
                day = 'Saturday'
                break;

              default:
                break;
            }
            delete obj[day]
            //item.text = 'Leave'
            const updatedObj = Object.assign({ [day]: { text: '' } }, obj)
            tempData.splice(ind, 1, updatedObj)
            setDataSource([...tempData])
          }
        })
      }
      if (result[2]) {
        let arr = []
        result[2].data.data.forEach(curr => arr.push({
          schedule: curr.schedule,
          isBooked: curr.isBooked
        }))
        //let tempData = [...dataSource];
        arr.forEach(item => {
          let startHourTZ = Number(item.schedule.startHour) + Number(offSet)
          let startMinuteTZ = item.schedule.startMinute
          if (startHourTZ >= 24) {
            if (item.schedule.weekday == 6) {
              item.schedule.weekday = 0
            } else {
              item.schedule.weekday = item.schedule.weekday + 1
            }
            startHourTZ = startHourTZ % 24
          } else if (startHourTZ < 0) {
            if (item.schedule.weekday == 0) {
              item.schedule.weekday = 6
            } else {
              item.schedule.weekday = item.schedule.weekday - 1
            }
            startHourTZ = 24 + startHourTZ
          }

          var ind = tempData.findIndex((x) => {
            return x.startHour == String(startHourTZ) && x.startMinute == String(startMinuteTZ)
          })
          var obj = tempData.find((y) => {
            return y.startHour == String(startHourTZ) && y.startMinute == String(startMinuteTZ)
          })

          let day = ''
          switch (item.schedule.weekday) {
            case 0:
              day = 'Sunday'
              break;
            case 1:
              day = 'Monday'
              break;
            case 2:
              day = 'Tuesday'
              break;
            case 3:
              day = 'Wednesday'
              break;
            case 4:
              day = 'Thursday'
              break;
            case 5:
              day = 'Friday'
              break;
            case 6:
              day = 'Saturday'
              break;

            default:
              break;
          }
          delete obj[day]
          item.text = 'Leave'
          const updatedObj = Object.assign({ [day]: { text: '' } }, obj)
          tempData.splice(ind, 1, updatedObj)
          setDataSource([...tempData])
        })
      }
      setLoadingSpin(false)
    } catch (err) {
      setLoadingSpin(false)
    }
  }

  const handleDelete = (day, key, slot) => {
    const ind = data.findIndex((item) => {
      return item.key == key;
    });
    const obj = data.find(item => {
      return item.key == key && item.hasOwnProperty(day);
    });

    // console.log('This object is to be deleted', obj);
    if (obj) {
      delete obj[day];

      // const updatedObj = Object.assign({[day]: 'Available'}, obj)
      data.splice(ind, 1, obj);
      setDataSource([...data, data]);
    }
    // console.log('This is deleted object', obj);
  };

  const recommendedUsers = async (user) => {
    try {
      setLoading(true);
      const recommendation = await GET(
        `contact/recommendation/?loginId=${user.loginId}&role=${user.userRole}`
      );

      const allRecommendations = recommendation.data.data;
      setAllRecommendations(allRecommendations);
      const userImg = allRecommendations.map((item) => item.profileImage);
      setRImg(userImg);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const setUserName = async () => {
    const userInfo = await localStorage.getItem("userInfo");
    const name = JSON.parse(userInfo);

    setUser(name?.username);
    setIsFreeTrialRequestExists(name.isFreeTrialRequestExists);
    setTimeZone(name.timZone);

  };

  const handleAdd = (day, key) => {
    // let weekday = day.split(' ')[1]
    let weekday = day;
    const tempData = [...dataSource];

    const ind = tempData.findIndex((item) => {
      return item.key == key;
    });
    const obj = tempData.find((item) => {
      return item.key == key;
    });


    console.log(hoursRestriction);
    // let now = new Date();


    let start = record.start;

    // console.log(moment(start).unix())
    // console.log(moment().add(hoursRestriction, 'hours').unix());
    const selectedTime = moment(start).unix();
    const restrictedTime = moment().add(hoursRestriction, 'hours').unix();


    // console.log(new Date(start).getMilliseconds())
    // console.log(new Date().setDate(start))
    // let twodays = new Date().setDate(now.getDate() + 2);

    if (selectedTime < restrictedTime || selectedTime == restrictedTime) {
      notification['warning']({
        message: 'Error',
        description: `You cannot make appointment when the date has been passed or within ${hoursRestriction} hours.`,
      });
      return;
    }

    if (obj[weekday] == { text: 'Pending' }) {
      let arr = [...dataBody];
      const ind2 = arr.findIndex(item => {
        let start_ = String(item.startDate);
        let end_ = String(item.endDate);

        let rstart_ = String(record.start);
        let rend_ = String(record.end);

        return item.calID == Number(record.key) && (start_ == rstart_ && end_ == rend_);
      });
      if (ind2 != -1) {
        arr.splice(ind2, 1);
        setDataBody(arr);

        delete obj[weekday];
        const updatedObj = Object.assign({ [weekday]: { text: 'Available' } }, obj);
        tempData.splice(ind, 1);
        // tempData.slice(0, data.length-1)
        setDataSource([...tempData]);
        return;
      }
      return;
    }

    const data = {
      calID: Number(key),
      startDate: moment(record.start).utc(),
      endDate: moment(record.end).utc(),
      teacherID: teacherID.tid,
      contactID: teacherID.contactID,
      isBooked: false
    };

    setDataBody([...dataBody, data]);

    delete obj[weekday];
    const updatedObj = Object.assign({ [weekday]: { text: 'Pending' } }, obj);
    // tempData.slice(0,)
    tempData.splice(ind, 1, updatedObj);
    setDataSource([...tempData]);
  };

  // const handleSave = () => {
  // const bodyData = {
  //   teacherID: teacherID.tid,
  //   contactID: teacherID.contactID
  // }
  // POST('http://localhost:5500/request-appointment-approval', bodyData, {
  //   headers: {
  //     'authorization': JSON.parse(localStorage.getItem('token'))
  //   }
  // })
  //   .then(res => {
  //     console.log(res)
  //     setVisible(false)
  //   })
  //   .catch(err => console.log(err))
  // };

  const onSubjectChange = (value) => {
    const arr = [...dataBody];
    arr.forEach(item => {
      item.subject = value;
    });

    setDataBody(arr);
  };

  function onBlur() {
    // console.log('blur');
  }

  function onFocus() {
    // console.log('focus');
  }

  function onSearch(val) {
    // console.log('search:', val);
  }

  const handleModalClose = () => {
    setInitialDate(startOfWeek(new Date()));
    setVisible(false);
  };


  const handleSave = (token) => {
    // console.log(dataBody, stripeToken);

    let params = {
      token: token,
      slots: dataBody,
      packageType: packageValue,
      teacherId: teacherID.tid
    };
    POST('tutor/create-appointment', params)
      .then(res => {
        // console.log(res);
        if (res.status === 200) {
          setPaymentSuccess(true);

        }
        else {
          notification['error']({
            message: 'Error',
            description:
              res.err.error.message,
          });
          setPayment(false);
          setPaymentSuccess(false);

        }

      })
      .catch(err => console.log('This is error', err));
  };
  // const stripeTokenHandler = (token) => {
  //   let isSubject = true;
  //   dataBody.forEach(item => {
  //     if (item.subject === undefined) {
  //       isSubject = false;
  //     }
  //   });
  //   if (isSubject) {
  //     // setPayBtnHide(true);
  //     setPayment(true);
  //     handleSave(token.id);
  //     setPayBtnHide(false);
  //   }
  //   else {
  //     setPayBtnHide(false)
  //     notification['error']({
  //       message: 'Error',
  //       description: 'Please Select Subject',
  //     });
  //   }
  // };
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const convertMinute12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${minutes}`;
  };

  const getDayandDate = (day, slot) => {
    let d = new Date(day).getDate();
    let m = new Date(day).getMonth();
    let y = new Date(day).getFullYear();

    // console.log('This si sgetday and time ', day);
    const tempHourStart = convertTime12to24(slot.split('-')[0]);
    const tempMinuteStart = convertMinute12to24(slot.split('-')[0]);

    const tempHourEnd = convertTime12to24(slot.split('-')[1]);
    const tempMinuteEnd = convertMinute12to24(slot.split('-')[1]);

    // const start = new Date(new Date().getFullYear(), new Date().getMonth(), day, convertTime12to24(slot.split('-')[0]), convertMinute12to24(slot.split('-')[0]))
    var now = new Date();
    const start = new Date(`${m + 1} ${d} ${y} ${tempHourStart}:${tempMinuteStart}`);
    const end = new Date(`${m + 1} ${d} ${y} ${tempHourEnd}:${tempMinuteEnd}`);
    // console.log('This is obaid', start, ' ', end);
    // if (start < now)
    //   start.setDate(start.getDate() + 7)
    // if (end < now)
    //   end.setDate(end.getDate() + 7)

    setRecord(prevState => ({
      ...prevState,
      start,
      end
    }));
    // console.log('This is setRecord', record);
  };

  const mapcolumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.dataIndex,
        handleSave: handleSave,
        onMouseEnter: () => {
          const obj = dataSource.find(item => {
            return item.key === record.key && item.hasOwnProperty(col.dataIndex);
          });

          if (obj) {
            if (obj[col.dataIndex] === 'Scheduled') {
              // handleStudentDetails(col.dataIndex, record.key)
            }
          }
        },
        onClick: () => {
          if (col.dataIndex === 'slot') {
            return
          }
          const obj = dataSource.find(item => {
            return item.key === record.key && item.hasOwnProperty(col.dataIndex);
          });

          if (obj) {
            if (obj[col.dataIndex].text == 'Pending' || obj[col.dataIndex].text == 'Scheduled' || obj[col.dataIndex].text == 'Leave') {
              return;
            }
            getDayandDate(col.date_, record.display);
            setRecord(prevState => ({
              ...prevState,
              day: col.dataIndex,
              key: record.key
            }));
          }
        }
      }),
    };
  });

  // const listenCall = async () => {
  //   try {
  //     SocketData.on("initiateCall", (data) => {
  //       let repeate = setInterval(() => {
  //         audio.play();
  //       }, 3000);
  //       confirm({
  //         title: "Do you want to receive call?",
  //         icon: <VideoCameraOutlined />,
  //         content: "Click OK to receive call",
  //         onOk() {
  //           audio.pause();
  //           clearInterval(repeate);
  //           window.open(`${data.callUrl}`, "_blank");
  //         },
  //         onCancel() {
  //           clearInterval(repeate);
  //           audio.pause();
  //         },
  //       });
  //     });
  //   } catch (error) {
  //     console.log("ERROR, while dialing...", error.message);
  //   }
  // };

  const sendInvite = async (invitedTo) => {
    try {
      let data = {
        invitedTo: invitedTo,
        invitedBy: isUserInfo.loginId,
        role: isUserInfo.userRole,
        username: user,
      };
      // console.log("invitedTo", data);
      const invitation = await POST("contact", data);
      invitation && setLoading(false);
      // console.log("Success", invitation);
      setInvitedId(invitedTo);
      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation sent`,
          style: {
            zIndex: "999999",
          },
        });
        getAllTutors(isUserInfo);
        getPendingInvitations(isUserInfo);
        recommendedUsers(isUserInfo);
        getInvitedUsers(isUserInfo);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getPendingInvitations = async (isUserInfo) => {
    setLoading(true);
    try {
      const pendingInvitations = await GET(
        `contact/pendinginvites/?loginId=${isUserInfo.loginId}&role=${isUserInfo.userRole}`
      );
      if (pendingInvitations.data) {
        // console.log("pendingInvitations Success", pendingInvitations.data.data);
        pendingInvitations && setPendingInvitations(pendingInvitations.data.data);
        const allPendingInvites = pendingInvitations.data.data;
        const userImgs = allPendingInvites?.map(
          (item, index) => item.profileImage
        );
        setRiImg(userImgs);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const acceptInvitationHandler = async (value) => {
    const { invitedBy, invitedTo, role, isAccepted } = value;
    try {
      const acceptInvitation = await PUT("contact", {
        invitedBy: invitedBy,
        invitedTo: invitedTo,
        role: role,
        isAccepted,
        username: user,
      });
      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation Accepted!`,
          style: {
            zIndex: "999999",
          },
        });
      });
      getAllTutors(isUserInfo);
      getPendingInvitations(isUserInfo);
      recommendedUsers(isUserInfo);
      getInvitedUsers(isUserInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const rejectInvitationHandler = async (value) => {
    // console.log("VALUE", value);
    const { invitedBy, invitedTo, role, isAccepted } = value;
    try {
      const rejectInvitation = await PUT("contact", {
        invitedBy: invitedBy,
        invitedTo: invitedTo,
        role: role,
        isAccepted,
        username: user,
      });
      message.loading(`Please wait`).then(() => {
        message.success({
          content: `Invitation Rejected!`,
          style: {
            zIndex: "999999",
          },
        });
      });
      getAllTutors(isUserInfo);
      getPendingInvitations(isUserInfo);
      recommendedUsers(isUserInfo);
      getInvitedUsers(isUserInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const sentMessage = async (id) => {
    const token = await localStorage.getItem("token");
    const myToken = JSON.parse(token);
    try {
      const contact = await POST("chatcontact", {
        studentId: `${isUserInfo.loginId}`,
        teacherId: `${id}`,
        role: "student",
      });
      contact.status && history.push("/student/chat");
    } catch (error) {
      // console.log("Already exist!");
    }
    // console.log("!!!", id);
  };
  const getConfiguration=()=>{
    GET(configuration)
    .then(res => {
      // console.log(res);
      if (res.status == 200) {

      // console.log(res);
        setHoursRestriction(res.data.data.configurations.appointmentScheduleRestrictionInHours);
      }
      if (res.status == 409) {
        handleCancelTrialModal();
        notification.error({ message: res.err.message });

      }

    })
    .catch(err => console.log('This is error', err));
  }
  useEffect(() => {

    getUserInfo().then((res) => {
      setUserInfo(res);
      getPendingInvitations(res);
      recommendedUsers(res);
      getAllTutors(res);
      setUserName();
      getInvitedUsers(res);
      getConfiguration();
    });

  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      getUserInfo().then((res) => {
        setUserInfo(res);
        getPendingInvitations(res);
        recommendedUsers(res);
        getAllTutors(res);
        setUserName();
        getInvitedUsers(res);
        getConfiguration();
      });
    }, 30000);
    return () => {

      clearInterval(timer);
    }
    // listenCall();
  }, []);
  // }, [SocketData]);
  var Xmas95 = new Date();
  var options = { month: 'long' };
  const onFinishTrial = values => {
    // console.log('Success:', values);

    const data = JSON.parse(localStorage.getItem('userInfo'));

    // setUser(data)

    let params = {
      scheduleStartTime: moment(values.time).toISOString(),
      scheduleEndTime: moment(values.time).add(30, 'minutes').toISOString(),
      subject: values.subject,
      timeZone: data.timeZone
      // date:moment(values.time).unix()

    };
    POST(freeTrialVersion, params)
      .then(res => {
        // console.log(res);
        if (res.status == 200) {

          const userInfo = localStorage.getItem("userInfo");
          const name = JSON.parse(userInfo);
          name.isFreeTrialRequestExists = true;
          localStorage.setItem("userInfo", JSON.stringify(name));
          notification.success({ message: res.data.data.message });
          setIsFreeTrialRequestExists(true)
          handleCancelTrialModal();

        }
        if (res.status == 409) {
          handleCancelTrialModal();
          notification.error({ message: res.err.message });

        }

      })
      .catch(err => console.log('This is error', err));

  };
  function disabledDate(current) {
    // Can not select days before today and today
    // return current && moment(current).subtract(2, 'days') < moment().endOf('day');

    // const day = hoursRestriction/24;
    let restriction = moment().add(hoursRestriction, 'hours').hours(0).minutes(0).seconds(0).unix();
    let currentDate = moment().hours(0).minutes(0).seconds(0).unix();
    let provideDate = moment(current).hours(0).minutes(0).seconds(0).unix();  

    if (provideDate < currentDate || provideDate < restriction ) {
      return true;
    } else {
      return false;
    }



  }
  const getDisabledHours = () => {
    // console.log(hoursRestriction);
    let restrictionDate = moment().add(hoursRestriction, 'hours');
    // const hour = hoursRestriction % 24;
    var hours = [];
    for (var i = 0; i < restrictionDate.hours(); i++) {
      hours.push(i);
    }
    return hours;
  }

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  }

  const setupCall = async (id, callId) => {

    // alert("dialing........");
    // try {
    //   let call = await POST('call', {
    //     teacherId: isUserInfo.loginId,
    //     studentId: id,
    //     isCallInitiate: true,
    //   });

    // const callInfo = call.data.data;
    // SocketData.emit("call", {
    //   ...callInfo,
    //   callUrl: `${CALL_URL}${id}${isUserInfo.loginId}`,
    //   role: `${isUserInfo.userRole}`,
    // });
    window.open(`/call?callUrl=${CALL_URL}&cid=${callId}&type=1&id=${id}&loginId=${isUserInfo.loginId}`, "_blank")

    // window.open(`${CALL_URL}${id}${isUserInfo.loginId}`, "_blank");
    // } catch (error) {
    //   console.log("ERROR, while dialing...", error.message);
    // }
  };
  const deleteStudent = async (stId) => {
    alert(isUserInfo.loginId);
    if (confirm('Are you sure you want to delete?')) {
      localStorage.setItem(`hideStudent${stId}`, stId+isUserInfo.loginId);
      window.location.reload();
    }
  };
  return (
    <div className="tab-wrapper">
      {visible_ && (
        <Modal style={{ position: 'relative' }}
          title="Summary"
          centered
          visible={visible_}
          footer={null}
          // onOk={handleSave}         
          onCancel={() => { setPaymentSuccess(false); setPayment(false); setVisible_(false); setVisible(false); }}

        >
          {teacherID.subjects.length >= 1 && (
            <Select

              className="summary-dropdown"
              placeholder="Select a subject"
              optionFilterProp="children"
              onChange={onSubjectChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}

              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {teacherID.subjects[0] && (
                <Option value={teacherID.subjects[0]}>
                  {teacherID.subjects[0]}
                </Option>
              )}
              {teacherID.subjects[1] && (
                <Option value={teacherID.subjects[1]}>
                  {teacherID.subjects[1]}
                </Option>
              )}
              {teacherID.subjects[2] && (
                <Option value={teacherID.subjects[2]}>
                  {teacherID.subjects[2]}
                </Option>
              )}
              {teacherID.subjects[3] && (
                <Option value={teacherID.subjects[3]}>
                  {teacherID.subjects[3]}
                </Option>
              )}
              {teacherID.subjects[4] && (
                <Option value={teacherID.subjects[4]}>
                  {teacherID.subjects[4]}
                </Option>
              )}
            </Select>
          )}

          <div className="mt-3">
            <div style={{ fontSize: '15px', fontWeight: 'bold' }}>Package</div>
            <Radio.Group onChange={(e) => changePackage(e)} value={packageValue}>
              <Radio style={radioStyle} value={1}>
                Standard (Check Plans & Pricing for Details)
              </Radio>
              <Radio style={radioStyle} value={2}>
                Advanced (Check Plans & Pricing for Details)
              </Radio>
              {loadingSpin && (
                <div style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0', left: 0, background: ' #00000054' }}>

                  <Space size="middle">
                    <Spin size="large" />

                  </Space>
                </div>
              )}

              {payment && (
                <div style={{ position: 'absolute', zIndex: '2', flexDirection: 'column', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', top: '0', left: 0, background: 'white' }}>

                  <input type="checkbox" checked={paymentSuccess} readOnly={true} className="input-circle" id="check" />
                  <label htmlFor="check" className="tick">
                    <div className="check-icon">
                    </div>
                  </label>
                  {paymentSuccess ?
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                      <h2>Payment Successfull</h2>
                      <Button style={{ background: '#00a58e', color: 'white', marginTop: '1rem' }}
                        onClick={() => { setPaymentSuccess(false); setPayment(false); setVisible_(false); setVisible(false); }}>Done</Button>
                    </div>
                    : <h2>Processing...</h2>}

                </div>
              )}

            </Radio.Group>
          </div>
          <div className="summary-detail-box">
            {" "}
            {dataBody.map((item, index) => (
              <div key={index} className="summary-detail">
                <p>


                  Start: <span>{moment(item.startDate).local().format('ddd MMM DD YYYY hh:mm:ss')}</span>
                </p>
                <p>
                  End: <span>{moment(item.endDate).local().format('ddd MMM DD YYYY hh:mm:ss')
                  }</span>
                </p>
              </div>
            ))}
          </div>

          <div>
            <Elements stripe={stripePromise}>
              <CheckoutForm

                price={price}
                setPayment={setPayment}
                // stripeTokenHandler={stripeTokenHandler}
                handleSave={handleSave}
                dataBody={dataBody}
              />
            </Elements>
          </div>
        </Modal>
      )}
      {visible && (
        <Modal
          title="Create Appointment"
          centered
          className="calendar-page"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={handleModalClose}
          width={1000}
          // footer={[
          //   <div className="btn-row">
          //      <Button
          //       className="float-right primary-btn"
          //       onClick={() =>getPricing()}
          //     >
          //       Save
          //     </Button>
          //   </div>,
          // ]}
          footer={[
            <Button
              onClick={() => getPricing()}
            >
              Save
              </Button>

          ]}
        >
          <div className="DateT">
            <div> {timeZone}</div>

            <button
              type="button"
              className="ant-btn previousBtn"
              onClick={prevWeek}
            >
              <span>Previous</span>
            </button>
            <button type="button" className="ant-btn nextBtn" onClick={nextWeek}>
              <span>Next</span>
            </button>
            <h4 className="float-right my-3">
              {new Intl.DateTimeFormat("en-US", options).format(iDate)}{" "}
              {iDate.getFullYear()}
            </h4>
          </div>
          {/* <Table
            showHeader={true}
            columns={mapcolumns}
            dataSource={dataSource}                               
            pagination={false}
            bordered
            scroll={{ y: 375 }}
            className="availability-calendar"
          /> */}

          {loadingSpin ? (
            <Space size="middle">
              <Spin size="large" />
              <Table
                columns={mapcolumns}
                dataSource={data}
                pagination={false}
                scroll={{ y: 375 }}
                bordered
                className="availability-calendar"
              />
            </Space>
          ) : (
              <Table
                columns={mapcolumns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: 375 }}
                bordered
                className="availability-calendar"
              />
            )}
        </Modal>
      )}


      <Modal title="Free Trial" visible={isTrialModal} footer={false} onOk={handleOkTrialModel} onCancel={handleCancelTrialModal}>
        <Form
          // {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinishTrial}
        // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please input your subject!' }]}
          >
            <Select
              placeholder="Select subject"

            >
              <Option value="recitation">Recitation</Option>
              <Option value="tajweed">Tajweed</Option>
              <Option value="hifz">Hifz</Option>
              <Option value="arabic">Arabic</Option>
              <Option value="islamicStudies">Islamic Studies</Option>
            </Select>
          </Form.Item>


          <Form.Item
            label="Time"
            name="time"
            rules={[{ required: true, message: 'Please input your  date and time!' }]}
          >
            <DatePicker showTime
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              showNow={false}
              disabledHours={getDisabledHours}
              disabledMinutes={getDisabledMinutes}
            />
          </Form.Item>



          <Form.Item
          // {...tailLayout}
          >
            <Button type="primary" htmlType="submit">
              Submit
        </Button>
          </Form.Item>
        </Form>
      </Modal>


      <Tabs defaultActiveKey="1" type="card" size={"large"}>
        <TabPane tab="Connected Tutors" key="1">

          {userData?.isProfileCompleted ?
            <div style={{ width: '50%', padding: '0 23px' }} onClick={() => showTrialModal()}>
              <button style={{

                padding: '10px 30px 5px',
                textAlign: 'center',
                color: '#fff',
                borderRadius: '3px',
                background: " #c82b11",
                border: '1px solid #c82b11',
                fontFamily: 'Nexa-Regular',
                fontSize: '14px',
                marginRight: '3px',
                textDecoration: 'none',
                width: '100%'

              }}>Request for a free trial</button></div>
            : null}


          <div id="current-tutor-wrap">

            <div className="current-tutor-left">
              {isLoading === true ? (
                <div className="example">
                  <Spin size="large" />
                </div>
              ) : isAllTutors ? (
                isAllTutors.map(
                  (student, index) =>
                    student.firstName && (
                      <>
                        <div key={index} className="reusedbox-content clearfix d-flex box-padding position-relative" style={{display : (localStorage.getItem(`hideStudent${student.userId}`) === student.userId + isUserInfo.loginId) ? 'none' : 'flex'}}>
                          {student.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}
                          {/* <div className="image-wrap">
                            <img
                              src={
                                profileImg.length >= 0 &&
                                  profileImg[index] &&
                                  profileImg[index][0]
                                  ? profileImg[index][0].profileImage
                                  : photo
                              }
                              alt=""
                            /> */}

                          <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={
                            profileImg.length >= 0 &&
                              profileImg[index] &&
                              profileImg[index][0]
                              ? profileImg[index][0].profileImage
                              : photo} />

                          {/* </div> */}
                          <div className="boxcontent " style={{ width: '375px' }}>
                            <h4>
                              {student.firstName} {student.lastName}
                            </h4>
                            {/* <span>Next session in:</span>
                                                    <em>10h:03m</em><br /> */}
                            <span>Subject:</span>
                            {student.subjects.map((subject) => (
                              <em
                                key={subject}
                                style={{
                                  textTransform: "capitalize",
                                  marginBottom: "6px",
                                }}
                              >
                                {subject}, &nbsp;
                              </em>
                            ))}
                            <br />
                            {/*<a*/}
                            {/*  className="btn-cancel"*/}
                            {/*  onClick={() => {*/}
                            {/*    setVisible(true);*/}
                            {/*    setTeacherID({*/}
                            {/*      tid: student.userId,*/}
                            {/*      contactID: student.contactID,*/}
                            {/*      subjects: [...student.subjects],*/}
                            {/*    });*/}
                            {/*   */}
                            {/*    setTimeZone(student.timeZone)*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  Book Slots*/}
                            {/*</a>*/}
                            <button style={{ marginRight: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}
                                    onClick={() => {setVisible(true);
                                      setTeacherID({
                                      tid: student.userId,
                                      contactID: student.contactID,
                                      subjects: [...student.subjects],
                                    });

                                      setTimeZone(student.timeZone)}}>Book Slots</button>
                            {/* <a className="btn-cancelborder">
                              End this Contract
                            </a> */}


                            <button style={{
                              background: "#02a28e",
                              color: 'white', paddingLeft: '12px', paddingBottom: '3px',
                              paddingRight: '12px', paddingTop: '7px',
                              borderRadius: '3px'
                            }}
                              onClick={() => sentMessage(student.userId)}>Send Message</button>
                            {!student.hideBtn ?
                              <button style={{
                                background: "#02a28e",
                                color: 'white', paddingLeft: '12px', paddingBottom: '3px',
                                paddingRight: '12px', paddingTop: '7px', marginLeft: '3px',
                                borderRadius: '3px'
                              }}
                                onClick={() => setupCall(student.userId, student.nextClass[0]._id)}>Join a Class</button>
                              : null}

                            {/*<button style={{ marginLeft: '5px', background: "#c82b11", color: 'white', paddingLeft: '12px', paddingBottom: '3px', paddingRight: '12px', paddingTop: '7px', borderRadius: '3px' }}*/}
                            {/*        onClick={() => deleteStudent(student.userId)}>Delete Student</button>*/}
                          </div>
                          {/* <div className="box-otherlinks">
                            <ul>
                              <li>
                                <a
                                  className="bluebox"
                                  onClick={() => sentMessage(student.userId)}
                                >
                                  <img src={msgIcon} alt="icon" />{" "}
                                </a>
                              </li>
                              {!student.hideBtn ?
                              <li>
                              <a  onClick={() => setupCall(student.userId,student.nextClass[0]._id)} className="greenbox" >
                                <img src={CallIcon} alt="icon" />
                              </a>
                            </li>
                            :null}
                            </ul>
                          </div> */}
                        </div>
                      </>
                    )
                )
              ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
            <div className="current-tutor-right">
              <Tabs
                onChange={() => setTabState(false)}
                type="card"
                {...(selectedTab === "tab2" && tabState === true
                  ? { activeKey: "3" }
                  : null)}
                {...(selectedTab === "tab1" && tabState === true
                  ? { activeKey: "1" }
                  : null)}
              >
                <TabPane tab="Recommended" key="1">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isLoading === true ? (
                        <div className="example">
                          <Spin size="large" />
                        </div>
                      ) : isAllRecommendations ? (
                        isAllRecommendations.map(
                          (tutor, index) =>
                            tutor.firstName && (
                              <>
                                <div key={index} className="right-content" style={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)', marginBottom: '30px' }}>
                                  <div className="currenttutuors-tab">
                                    <div className="reusedbox-content d-flex  position-relative " style={{ boxShadow: 'none', marginBottom: '0px' }}>
                                      {tutor.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}
                                      {/* <div className="image-wrap">
                                        <img
                                          src={
                                            rImg.length >= 0 &&
                                              rImg[index] &&
                                              rImg[index][0]
                                              ? rImg[index][0].profileImage
                                              : photo
                                          }
                                          alt=""
                                        />

                                      </div> */}


                                      <div style={{ width: '20%' }}>
                                        <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={
                                          rImg.length >= 0 &&
                                            rImg[index] &&
                                            rImg[index][0]
                                            ? rImg[index][0].profileImage
                                            : photo} />

                                      </div>
                                      <div
                                        className="boxcontent"
                                        style={{ width: "auto" }}
                                      >
                                        <h4>
                                          {tutor.firstName} {tutor.lastName}
                                        </h4>
                                        <span>Interest: </span>
                                        {tutor.subjects.map((subject) => (
                                          <em
                                            key={subject}
                                            style={{
                                              textTransform: "capitalize",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {subject}, &nbsp;
                                          </em>
                                        ))}
                                        <br />
                                        <span>Spoken Languages: </span>
                                        {tutor.languages.map((language) => (
                                          <em
                                            key={language}
                                            style={{ marginBottom: "8px" }}
                                          >
                                            {language}, &nbsp;
                                          </em>
                                        ))}
                                        <br />
                                        <span>Country: </span>
                                        <em style={{ marginBottom: "8px" }}>
                                          {" "}
                                          {tutor.country}
                                        </em>
                                        <br />
                                      </div>




                                    </div>
                                    <div
                                      style={{
                                        textAlign: "right",

                                      }}
                                    >
                                      {isUserInfo &&
                                        isUserInfo.userRole !== "tutor" && (
                                          // <a
                                          //   className="green-btn"
                                          //   onClick={() =>
                                          //     sendInvite(tutor.userId)
                                          //   }
                                          // // onClick = {() => {
                                          // //   setTutorID(tutor.userId)
                                          // //   showCalendar()
                                          // // }}
                                          // >
                                          //   Send Invitation
                                          // </a>
                                          <button

                                            className="greenbtn"
                                            style={{
                                              textAlign: 'center',
                                              color: '#fff',
                                              borderRadius: '3px',
                                              padding: '10px 15px',
                                              marginBottom: '10px',
                                              background: ' #02a28e',
                                              border: '1px solid #02a28e',
                                              fontFamily: 'NexaBold'
                                            }}
                                            onClick={() =>
                                              sendInvite(tutor.userId)
                                            }
                                          >
                                            Send Invitation
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        )
                      )
                          : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Sent Invitations" key="2">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isLoading === true ? (
                        <div className="example">
                          <Spin size="large" />
                        </div>
                      ) : isAllInvited ? (
                        isAllInvited.map(
                          (tutor, index) =>
                            tutor.firstName && (
                              <div key={index}>
                                <div className="right-content">
                                  <div className="currenttutuors-tab">
                                    <div className="reusedbox-content clearfix d-flex position-relative">
                                      {tutor.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}
                                      {/* <div className="image-wrap">
                                        <img
                                          src={
                                            siImg.length >= 0 &&
                                              siImg[index] &&
                                              siImg[index][0]
                                              ? siImg[index][0].profileImage
                                              : photo
                                          }
                                          alt=""
                                        />

                                      </div> */}


                                      <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={
                                        siImg.length >= 0 &&
                                          siImg[index] &&
                                          siImg[index][0]
                                          ? siImg[index][0].profileImage
                                          : photo} />

                                      <div
                                        className="boxcontent"
                                        style={{ width: "auto" }}
                                      >
                                        <h4>
                                          {tutor.firstName} {tutor.lastName}
                                        </h4>
                                        <span>Interest: </span>
                                        {tutor.subjects.map((subject) => (
                                          <em
                                            key={subject}
                                            style={{
                                              textTransform: "capitalize",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {subject}, &nbsp;
                                          </em>
                                        ))}
                                        <br />
                                        <span>Spoken Languages: </span>
                                        {tutor.languages.map((language) => (
                                          <em
                                            key={language}
                                            style={{ marginBottom: "8px" }}
                                          >
                                            {language}, &nbsp;
                                          </em>
                                        ))}
                                        <br />
                                        <span>Country: </span>
                                        <em style={{ marginBottom: "8px" }}>
                                          {" "}
                                          {tutor.country}
                                        </em>
                                        <br />
                                      </div>
                                      {/* <div style={{ textAlign: 'right'}}>
                                                                        {
                                                                                <a href="/" className="green-btn-disabled" disabled>Invitation sent</a>
                                                                        }
                                                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Received Invitations" key="3">
                  <div className="overview-content" id="sessions">
                    <div className="overviewtab-row clearfix">
                      {isLoading === true ? (
                        <div className="example">
                          <Spin size="large" />
                        </div>
                      ) : isPendingInvitations ? (
                        isPendingInvitations.map((tutor, index) => (
                          <div key={index}>
                            <div className="right-content">
                              <div className="currenttutuors-tab">
                                <div className="reusedbox-content clearfix d-flex position-relative">
                                  {tutor.isOnline === true ? <span className="online"></span> : <span className="offline"></span>}
                                  {/* <div className="image-wrap">
                                    <img
                                      src={
                                        riImg.length >= 0 &&
                                          riImg[index] &&
                                          riImg[index][0]
                                          ? riImg[index][0].profileImage
                                          : photo
                                      }
                                      alt=""
                                    />

                                  </div> */}

                                  <Avatar size={80} style={{ border: '3px solid #02a28e', marginRight: '1rem' }} src={
                                    riImg.length >= 0 &&
                                      riImg[index] &&
                                      riImg[index][0]
                                      ? riImg[index][0].profileImage
                                      : photo} />


                                  <div
                                    className="boxcontent"
                                    style={{ width: "auto" }}
                                  >
                                    <h4>
                                      {tutor.firstName} {tutor.lastName}
                                    </h4>
                                    <span>Interest: </span>
                                    {tutor.subjects.map((subject) => (
                                      <em
                                        key={subject}
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        {subject}, &nbsp;
                                      </em>
                                    ))}
                                    <br />
                                    <span>Spoken Languages: </span>
                                    {tutor.languages.map((language) => (
                                      <em
                                        key={language}
                                        style={{ marginBottom: "8px" }}
                                      >
                                        {" "}
                                        {language}, &nbsp;{" "}
                                      </em>
                                    ))}
                                    <br />
                                    <span>Country: </span>
                                    <em style={{ marginBottom: "8px" }}>
                                      {" "}
                                      {tutor.country}
                                    </em>
                                    <br />
                                  </div>
                                  <div style={{ textAlign: "right" }}>
                                    <a
                                      className="accept-btn"
                                      onClick={() =>
                                        acceptInvitationHandler({
                                          invitedBy: tutor.userId,
                                          invitedTo: isUserInfo.loginId,
                                          role: isUserInfo.userRole,
                                          isAccepted: true,
                                        })
                                      }
                                    >
                                      Accept
                                    </a>
                                    &nbsp;&nbsp;
                                    <a
                                      className="reject-btn"
                                      onClick={() =>
                                        rejectInvitationHandler({
                                          invitedBy: tutor.userId,
                                          invitedTo: isUserInfo.loginId,
                                          role: isUserInfo.userRole,
                                          isAccepted: false,
                                        })
                                      }
                                    >
                                      Reject
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Landing;