/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { Table, Pagination, Space, Button, Modal, Tag, Select, DatePicker, notification } from "antd";
import './billing.css';
import { cashInListing } from '../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { POST } from '../../../../apiClient';
import ExportExcel from '../../../shared/ExcelExport/exportExcel';
import moment from 'moment';


const columns = [
  {
    title: "Student Name/Paid By",    
    width: "20%",
    render: (text, record) => (
      <Space size="middle" >

        {text.studentID.username}

      </Space>


    )
  },
  {
    title: "Tutor Name/Paid To",   
    width: "20%",
    render: (text, record) => (
      <Space size="middle" >

        {text.teacherID.username}

      </Space>


    )
  },
  {
    title: "Session",    
    width: "10%",
    render: (text, record) => (
      <Space size="middle" >

        {text.duration * 2}

      </Space>


    )
  },
  {
    title: "Date",    
    width: "20%",
    render: (text, record) => (
      <Space size="middle" >

      {moment(text.createdAt).format('MMM,ddd Do YYYY')}

      </Space>


    )
  },
  {
    title: "Status",
    key: "isPaymentCharged",
    dataIndex: "isPaymentCharged",
    width: "15%",

    render: (isPaymentCharged, record) => (
      <Space size="middle" key={isPaymentCharged}>
        <Tag color={isPaymentCharged ? 'green' : 'volcano'}>
          {isPaymentCharged ? 'Paid' : 'Pending'}

        </Tag>
      </Space>


    )
  },
  
  {
    title: "Total Amount",
    // dataIndex: "amountCharged",
    width: "20%",
    render: (text, record) => (
      <div>
        {text.amountCharged}$
     </div>


    )

  },
];

const Billing = () => {


  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [listing, setListing] = useState();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(
    {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  );

  const[month,setMonth]= useState(undefined);
  const[year,setYear]= useState(undefined);
  
  useEffect(() => {
    cashIn();
    return () => {
    }
  }, [limit, offset, month]);

  const cashIn = () => {

    dispatch({ type: 'LOADING', payload: true });
    let params = {
      limit: limit,
      offset: offset,
      month,
      year
    }

    if (month == undefined) delete params.month;

    POST(cashInListing, params).then(res => {    

      if (res.status === 200) {
        console.log(res)
        const temp = [];
        res.data.data.transactions.forEach((val) => {
          temp.push(
            {
              StudentNamePaidBy:val.studentID.username,
              TutorNamePaidTo: val.studentID.username, 
              Session: val.duration * 2, 
              Amount: val.amountCharged+"$",
              Date: moment(val.createdAt).format('MMM,ddd Do YYYY')
            }
          )
        })
        setData(temp)
        setListing(res.data.data.transactions);
        setPagination({ ...pagination, total: res.data.data.totalCount });
      }
      if (res.status === 500) {
        notification.error({ message: 'Error' });
      }
      dispatch({ type: 'LOADING', payload: false });
    });

  }

  const handleTableChange = (paginations, filters, sorter) => {

    setOffset((paginations.current * paginations.pageSize) - paginations.pageSize);
    setLimit(paginations.pageSize);
    setPagination({ ...pagination, current: paginations.current });
  };

  const monthChanged=(date, dateString)=>{   
   
    const temp = moment(date).month();   
    if(!isNaN(temp)){
      setMonth(moment(date).month());
      setYear(moment(date).year());
    } else {
      setMonth(undefined);
    }
    setOffset(0);
    setPagination({ ...pagination, current: 1 });
  }

  // function handleChange(value) {
  //   if (value === "true")
  //     setPayoutType(true);
  //   else if (value === "false")
  //     setPayoutType(false);
  //   else setPayoutType(undefined);
  // }

  return (
    <div className="mx-3">
        <div className="py-3 float-right d-flex">
          <div>    
            <DatePicker onChange={monthChanged} picker="month" />
          </div>
          {/* <div>
            <Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
              <Option value="undefined">All</Option>
              <Option value="true">Paid</Option>
              <Option value="false">Un-Paid</Option>
            </Select>
          </div> */}
          {/* <div><DatePicker onChange={onChange} /></div> */}
          &nbsp;&nbsp;
          <div className="mr-2"><ExportExcel data={data} /></div>
        </div>
      <Table

        columns={columns}
        rowKey={(record) => record._id}
        dataSource={listing}
        pagination={pagination}
        // loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Billing;
