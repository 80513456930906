/* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Card, Button ,Radio} from 'antd';
import DefaultAvatar from '../../../../../assets/images/defaultAvatar.jpeg';
import EmptyVideo from '../../../../../assets/images/video_files.svg';
import EmptyAudio from '../../../../../assets/images/audio_player.svg';
import { GET, POST } from '../../../../../apiClient';
import { studentDetail } from '../../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';    





const StudentDetail = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [detail, setDetail] = useState({})
    const [level,setLevel] =useState(1);
    const [compSkill,setCompSkill] =useState(1);

    useEffect(() => {
        dispatch({ type: 'LOADING', payload: true });

        GET(`${studentDetail}/${params.id}/details`).then(res => {
            const { data } = res;

            if (data.data.success == 1) {
            
                setDetail(data.data.data);
                setLevel(data.data.data.englishProficiency);
                setCompSkill(data.data.data.computerSkills);
            }
            else {
                notification.error({ message: 'Error' });
            }
            dispatch({ type: 'LOADING', payload: false });
        });


        return () => {


        }
    }, [])


    return (
        <div className="listing">
            <div className="back-btn" onClick={() => history.push(`${history.goBack()}`)} ><span className="mr-2"><ArrowLeftOutlined /></span>Back</div>
            {/* <Button style={{ fontFamily: 'initial' }} type="primary" icon={<ArrowLeftOutlined />}
                 >Back</Button> */}

            <Row className="m-0" align="top" gutter={[48, 32]}>


                <Col span={12}>
                    <Card bordered={false} onClick={() => history.push(`${history.location.pathname}`)}>
                        <div className="center ">
                            <div className="mr-4"><img src={detail.profileImage || DefaultAvatar} className="avatar" /> </div>
                            <div className="info mt-3">
                                <div className="text-capitalize"><b>{detail.firstName} {detail.lastName}</b></div>
                                <div>{detail.email}</div>
                                <div>{detail.gender}</div>
                                <div>{detail.dob}</div>
                                <div>{detail.cellNumber}</div>
                                <div>{detail.province} {detail.country}</div>
                                <div>{detail.timeZone}</div>
                                <div>{detail.sect}</div>

                            </div>

                        </div>






                    </Card>



                </Col>
                <Col span={12}>
                    <Card>
                        <div className="left">
                            <div className="content">
                                <small  >About</small>
                                {detail?.about ? <small><strong> {detail.about} </strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Skills</small>

                                {detail?.skills?.length ? <small ><strong className='text-capitalize'>{detail?.skills?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Languages</small>

                                {detail.languages?.length ? <small > <strong className='text-capitalize'>{detail?.languages?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Subjects</small>
                               {detail.subjects?.length ? <small > <strong className='text-capitalize'>{detail?.subjects?.join(', ')}</strong> </small>: 'N/A'}

                            </div>

                        </div>

                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Last Online</small>
                               {detail.lastOnline ? <small > <strong className='text-capitalize'>{moment(detail?.lastOnline * 1000).format('MMM DD, YYYY')}</strong> </small>: 'N/A'}

                            </div>

                        </div>
                        <Divider />

                        <div className="left">
                            <div className="content">
                                <small>First Class</small>
                               {detail.firstClass ? <small > <strong className='text-capitalize'>{moment(detail?.firstClass * 1000).format('MMM DD, YYYY')}</strong> </small>: 'N/A'}

                            </div>

                        </div>

                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Last Class</small>
                               {detail.lastClass ? <small > <strong className='text-capitalize'>{moment(detail?.lastClass * 1000).format('MMM DD, YYYY')}</strong> </small>: 'N/A'}

                            </div>

                        </div>


                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>SignUp Date</small>
                               {detail.signUpDate ? <small > <strong className='text-capitalize'>{detail?.signUpDate}</strong> </small>: 'N/A'}

                            </div>

                        </div>
                        {/* <Divider />
                        <div className="left">
                            <div className="content">                              
                              
                                   <small>What is your level of English proficiency?</small>
                                <div >
                                   <div className="d-flex mt-4">
                                   <small className="level-text"> <strong> Not Good </strong></small>
                                        <Radio.Group style={{display:'flex'}}  value={level}>
                                        <Radio disabled={true} className="radio-label" value={1}>1</Radio>
                                        <Radio disabled={true} className="radio-label" value={2}>2</Radio>
                                        <Radio disabled={true} className="radio-label" value={3}>3</Radio>
                                        <Radio disabled={true} className="radio-label" value={4}>4</Radio>
                                        <Radio disabled={true} className="radio-label" value={5}>5</Radio>
                                        <Radio disabled={true} className="radio-label" value={6}>6</Radio>
                                        <Radio disabled={true} className="radio-label" value={7}>7</Radio>
                                        <Radio disabled={true} className="radio-label" value={8}>8</Radio>
                                        <Radio disabled={true} className="radio-label" value={9}>9</Radio>
                                        <Radio disabled={true} className="radio-label" value={10}>10</Radio>
                                    </Radio.Group>
                                    <small className="level-text"> <strong> Very Good </strong></small>

                                </div>
                                   </div> 
                             

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">                              
                              
                                   <small>How would you rate your computer skills?</small>
                                <div >
                                   <div className="d-flex mt-4">
                                   <small className="level-text"> <strong> Not Good </strong></small>
                                   <Radio.Group style={{display:'flex'}}  value={compSkill}>
                                        <Radio disabled={true} className="radio-label" value={1}>1</Radio>
                                        <Radio disabled={true} className="radio-label" value={2}>2</Radio>
                                        <Radio disabled={true} className="radio-label" value={3}>3</Radio>
                                        <Radio disabled={true} className="radio-label" value={4}>4</Radio>
                                        <Radio disabled={true} className="radio-label" value={5}>5</Radio>
                                        <Radio disabled={true} className="radio-label" value={6}>6</Radio>
                                        <Radio disabled={true} className="radio-label" value={7}>7</Radio>
                                        <Radio disabled={true} className="radio-label" value={8}>8</Radio>
                                        <Radio disabled={true} className="radio-label" value={9}>9</Radio>
                                        <Radio disabled={true} className="radio-label" value={10}>10</Radio>
                                    </Radio.Group>    
                                    <small className="level-text"> <strong> Very Good </strong></small>

                                </div>
                                   </div> 
                             

                            </div>

                        </div>
                    */}
                    </Card>
                </Col>


            </Row>
            {/* <Row className="m-0" align="top" gutter={[48, 32]}>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Audios</b> </small>


                                {detail?.signUpAudio?.audioLink1 || detail?.signUpAudio?.audioLink2 || detail?.signUpAudio?.audioLink3 ?

                                    <div className="m-0 center" >


                                        <div className="mb-2 mt-3">
                                            {detail?.signUpAudio?.audioLink1 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink1} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div className="mb-2">
                                            {detail?.signUpAudio?.audioLink2 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink2} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div>
                                            {detail?.signUpAudio?.audioLink3 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink3} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>




                                    </div>

                                    :
                                    <div className="w-100 text-center mt-4">
                                        <img src={EmptyAudio} width="150px" />
                                        <p className="not-fount-text">No audio found</p>
                                    </div>
                                }





                            </div>

                        </div>




                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Videos</b></small>
                                <Row className="m-0" align="top" gutter={[20, 20]}>

                                    {detail?.signUpVideos?.videoLink1 || detail?.signUpVideos?.videoLink2 || detail?.signUpVideos?.videoLink3 ?
                                        <>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink1 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink1} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink2 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink2} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink3 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink3} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                        </>
                                        :

                                        <div className="w-100 text-center mt-4">
                                            <img src={EmptyVideo} width="150px" />
                                            <p className="not-fount-text">No video found</p>
                                        </div>
                                    }



                                </Row>

                            </div>

                        </div>
                    </Card>
                </Col>
            </Row>
        */}
        </div>
    );
}

export default StudentDetail;