let data = [
  {
    key: '1',
    display: '12:00 am-12:30 am',
    slot: '00:00',
    startHour: '0',
    startMinute: '0',
    endHour: '0',
    endMinute: '30'
  },
  {
    key: '2',
    display: '12:30 am-01:00 am',
    slot: '00:30',
    startHour: '0',
    startMinute: '30',
    endHour: '1',
    endMinute: '0'
  },
  {
    key: '3',
    display: '01:00 am-01:30 am',
    slot: '01:00',
    startHour: '1',
    startMinute: '0',
    endHour: '1',
    endMinute: '30'
  },
  {
    key: '4',
    display: '01:30 am-02:00 am',
    slot: '01:30',
    startHour: '1',
    startMinute: '30',
    endHour: '2',
    endMinute: '0'
  },
  {
    key: '5',
    display: '02:00 am-02:30 am',
    slot: '02:00',
    startHour: '2',
    startMinute: '0',
    endHour: '2',
    endMinute: '30'
  },
  {
    key: '6',
    display: '02:30 am-03:00 am',
    slot: '02:30',
    startHour: '2',
    startMinute: '30',
    endHour: '3',
    endMinute: '0'
  },
  {
    key: '7',
    display: '03:00 am-03:30 am',
    slot: '03:00',
    startHour: '3',
    startMinute: '0',
    endHour: '3',
    endMinute: '30'
  },
  {
    key: '8',
    display: '03:30 am-04:00 am',
    slot: '03:30',
    startHour: '3',
    startMinute: '30',
    endHour: '4',
    endMinute: '0'
  },
  {
    key: '9',
    display: '04:00 am-04:30 am',
    slot: '04:00',
    startHour: '4',
    startMinute: '0',
    endHour: '4',
    endMinute: '30'
  },
  {
    key: '10',
    display: '04:30 am-05:00 am',
    slot: '04:30',
    startHour: '4',
    startMinute: '30',
    endHour: '5',
    endMinute: '0'
  },
  {
    key: '11',
    display: '05:00 am-05:30 am',
    slot: '05:00',
    startHour: '5',
    startMinute: '0',
    endHour: '5',
    endMinute: '30'
  },
  {
    key: '12',
    display: '05:30 am-06:00 am',
    slot: '05:30',
    startHour: '5',
    startMinute: '30',
    endHour: '6',
    endMinute: '0'
  },
  {
    key: '13',
    display: '06:00 am-06:30 am',
    slot: '06:00',
    startHour: '6',
    startMinute: '0',
    endHour: '6',
    endMinute: '30'
  },
  {
    key: '14',
    display: '06:30 am-07:00 am',
    slot: '06:30',
    startHour: '6',
    startMinute: '30',
    endHour: '7',
    endMinute: '0'
  },
  {
    key: '15',
    display: '7:00 am-07:30 am',
    slot: '07:00',
    startHour: '7',
    startMinute: '0',
    endHour: '7',
    endMinute: '30'
  },
  {
    key: '16',
    display: '07:30 am-08:00 am',
    slot: '07:30',
    startHour: '7',
    startMinute: '30',
    endHour: '8',
    endMinute: '0'
  },
  {
    key: '17',
    display: '08:00 am-08:30 am',
    slot: '08:00',
    startHour: '8',
    startMinute: '0',
    endHour: '8',
    endMinute: '30'
  },
  {
    key: '18',
    display: '08:30 am-09:00 am',
    slot: '08:30',
    startHour: '8',
    startMinute: '30',
    endHour: '9',
    endMinute: '0'
  },
  {
    key: '19',
    display: '09:00 am-09:30 am',
    slot: '09:00',
    startHour: '9',
    startMinute: '0',
    endHour: '9',
    endMinute: '30'
  },
  {
    key: '20',
    display: '09:30 am-10:00 am',
    slot: '09:30',
    startHour: '9',
    startMinute: '30',
    endHour: '10',
    endMinute: '0'
  },
  {
    key: '21',
    display: '10:00 am-10:30 am',
    slot: '10:00',
    startHour: '10',
    startMinute: '0',
    endHour: '10',
    endMinute: '30'
  },
  {
    key: '22',
    display: '10:30 am-11:00 am',
    slot: '10:30',
    startHour: '10',
    startMinute: '30',
    endHour: '11',
    endMinute: '0'
  },
  {
    key: '23',
    display: '11:00 am-11:30 am',
    slot: '11:00',
    startHour: '11',
    startMinute: '0',
    endHour: '11',
    endMinute: '30'
  },
  {
    key: '24',
    display: '11:30 am-12:00 pm',
    slot: '11:30',
    startHour: '11',
    startMinute: '30',
    endHour: '12',
    endMinute: '0'
  },
  {
    key: '25',
    display: '12:00 pm-12:30 pm',
    slot: '12:00',
    startHour: '12',
    startMinute: '0',
    endHour: '12',
    endMinute: '30'
  },
  {
    key: '26',
    display: '12:30 pm-01:00 pm',
    slot: '12:30',
    startHour: '12',
    startMinute: '30',
    endHour: '1',
    endMinute: '0'
  },
  {
    key: '27',
    display: '01:00 pm-01:30 pm',
    slot: '13:00',
    startHour: '13',
    startMinute: '0',
    endHour: '13',
    endMinute: '30'
  },
  {
    key: '28',
    display: '01:30 pm-02:00 pm', 
    slot: '13:30',
    startHour: '13',
    startMinute: '30',
    endHour: '14',
    endMinute: '0'
  },
  {
    key: '29',
    display: '02:00 pm-02:30 pm',
    slot: '14:00',
    startHour: '14',
    startMinute: '0',
    endHour: '14',
    endMinute: '30'
  },
  {
    key: '30',
    display: '02:30 pm-03:00 pm',
    slot: '14:30',
    startHour: '14',
    startMinute: '30',
    endHour: '15',
    endMinute: '0'
  },
  {
    key: '31',
    display: '03:00 pm-03:30 pm',
    slot: '15:00',
    startHour: '15',
    startMinute: '0',
    endHour: '15',
    endMinute: '30'
  },
  {
    key: '32',
    display: '03:30 pm-04:00 pm',
    slot: '15:30',
    startHour: '15',
    startMinute: '30',
    endHour: '16',
    endMinute: '0'
  },
  {
    key: '33',
    display: '04:00 pm-04:30 pm',
    slot: '16:00',
    startHour: '16',
    startMinute: '0',
    endHour: '16',
    endMinute: '30'
  },
  {
    key: '34',
    display: '04:30 pm-05:00 pm',
    slot: '16:30',
    startHour: '16',
    startMinute: '30',
    endHour: '17',
    endMinute: '0'
  },
  {
    key: '35',
    display: '05:00 pm-05:30 pm',
    slot: '17:00',
    startHour: '17',
    startMinute: '0',
    endHour: '17',
    endMinute: '30'
  },
  {
    key: '36',
    display: '05:30 pm-06:00 pm',
    slot: '17:30',
    startHour: '17',
    startMinute: '30',
    endHour: '18',
    endMinute: '0'
  },
  {
    key: '37',
    display: '06:00 pm-06:30 pm',
    slot: '18:00',
    startHour: '18',
    startMinute: '0',
    endHour: '18',
    endMinute: '30'
  },
  {
    key: '38',
    display: '06:30 pm-07:00 pm',
    slot: '18:30',
    startHour: '18',
    startMinute: '30',
    endHour: '19',
    endMinute: '0'
  },
  {
    key: '39',
    display: '07:00 pm-07:30 pm',
    slot: '19:00',
    startHour: '19',
    startMinute: '0',
    endHour: '19',
    endMinute: '30'
  },
  {
    key: '40',
    display: '07:30 pm-08:00 pm',
    slot: '19:30',
    startHour: '19',
    startMinute: '30',
    endHour: '20',
    endMinute: '0'
  },
  {
    key: '41',
    display: '08:00 pm-08:30 pm',
    slot: '20:00',
    startHour: '20',
    startMinute: '0',
    endHour: '20',
    endMinute: '30'
  },
  {
    key: '42',
    display: '08:30 pm-09:00 pm',
    slot: '20:30',
    startHour: '20',
    startMinute: '30',
    endHour: '21',
    endMinute: '0'
  },
  {
    key: '43',
    display: '09:00 pm-09:30 pm',
    slot: '21:00',
    startHour: '21',
    startMinute: '0',
    endHour: '21',
    endMinute: '30'
  },
  {
    key: '44',
    display: '09:30 pm-10:00 pm',
    slot: '21:30',
    startHour: '21',
    startMinute: '30',
    endHour: '22',
    endMinute: '0'
  },
  {
    key: '45',
    display: '10:00 pm-10:30 pm',
    slot: '22:00',
    startHour: '22',
    startMinute: '0',
    endHour: '22',
    endMinute: '30'
  },
  {
    key: '46',
    display: '10:30 pm-11:00 pm',
    slot: '22:30',
    startHour: '22',
    startMinute: '30',
    endHour: '23',
    endMinute: '0'
  },
  {
    key: '47',
    display: '11:00 pm-11:30 pm',
    slot: '23:00',
    startHour: '23',
    startMinute: '0',
    endHour: '23',
    endMinute: '30'
  },
  {
    key: '48',
    display: '11:30 pm-12:00 am',
    slot: '23:30',
    startHour: '23',
    startMinute: '30',
    endHour: '0',
    endMinute: '0'
  }
];

export default data