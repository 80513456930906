import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import './configuration.css';
import {
    Form,
    Button,
    Select,
    InputNumber,
    notification
} from 'antd';


import { GET, POST } from '../../../../apiClient';
import { adminConfig } from '../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";

import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};


const Configuration = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();

    const [getConfig, setConfig] = useState({});
    useEffect(() => {
        getConfiguration();
    }, [])

    const getConfiguration = () => {
        dispatch({ type: 'LOADING', payload: true });

        GET(`${adminConfig}`).then(res => {
            const { data } = res;
          
            if (res.status == 200 ) {
              
                setConfig(data.data.configurations);
                form.setFieldsValue({
                    approvalLinkExpiryInDays : data.data.configurations.approvalLinkExpiryInDays,
                    classReminderEmailNotificationsInMinutes : data.data.configurations.classReminderEmailNotificationsInMinutes,
                    feedbackReminderEmailInMinutes: data.data.configurations.feedbackReminderEmailInMinutes,
                    joinClassVisibilityInMinutes:data.data.configurations.joinClassVisibilityInMinutes,
                    appointmentScheduleRestrictionInHours:data.data.configurations.appointmentScheduleRestrictionInHours
                })

            }
            if (res.status === 500) {
                notification.error({ message: res.err.error.message });
            }
            if (res.status === 200 && data.data.success == 0) {
                notification.error({ message: res.err.error.message });
            }

            dispatch({ type: 'LOADING', payload: false });
        });

    }
    const onFinish = (values) => {
        console.log(values);
        dispatch({ type: "LOADING", payload: true });

        let params = {
            approvalLinkExpiryInDays : +values.approvalLinkExpiryInDays,
            classReminderEmailNotificationsInMinutes : +values.classReminderEmailNotificationsInMinutes,
            feedbackReminderEmailInMinutes : +values.feedbackReminderEmailInMinutes,
            joinClassVisibilityInMinutes : +values.joinClassVisibilityInMinutes,
            appointmentScheduleRestrictionInHours: +values.appointmentScheduleRestrictionInHours
          }
          
      
          POST(adminConfig, params).then(res => {
           
      
            if (res.status === 200) {
              notification.success({ message: 'Update Successfull' });
                     
      
            }
            if (res.status === 500) {
              notification.error({ message: res.err.error.message });
            }
      
            dispatch({ type: "LOADING", payload: false });
          })
    };

    const onReset = () => {

    };

    const onFill = () => {

    };
    return (
        <Fragment >
            <h2 className="p-3">Configurations</h2>
            <Form className="py-5" {...layout} form={form} name="control-hooks" onFinish={onFinish}>

                <Form.Item name="approvalLinkExpiryInDays" label="Approval Link Expiry (Days)"
                    rules={[
                        {
                            type: 'number', min: 1, max: 99,
                            required: true,
                            message: 'Must be greater than 0'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item name="appointmentScheduleRestrictionInHours" label="Appointment Schedule Restriction (Hours)"
                    rules={[
                        {
                            type: 'number', min: 1, max: 99,
                            required: true,
                            message: 'Must be greater than 0'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item name="classReminderEmailNotificationsInMinutes" label="Reminder Email Notifications After Class (Minutes)"
                    rules={[
                        {
                            type: 'number', min: 1, max: 99,
                            required: true,
                            message: 'Must be greater than 0'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>



                <Form.Item name="joinClassVisibilityInMinutes" label="Join Class Button Visible Before (Minutes)"
                    rules={[
                        {
                            type: 'number', min: 1, max: 99,
                            required: true,
                            message: 'Must be greater than 0'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>


                <Form.Item name="feedbackReminderEmailInMinutes" label="Review/Feedback Email (Minutes)"
                    rules={[
                        {
                            type: 'number', min: 1, max: 99,
                            required: true,
                            message: 'Must be greater than 0'
                        }
                    ]}>
                    <InputNumber />
                </Form.Item>







                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                     </Button>
                    {/* <Button htmlType="button" onClick={onReset}>
                        Reset
                     </Button> */}

                </Form.Item>
            </Form>
        </Fragment>
    )
}
export default Configuration;