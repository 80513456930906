/* eslint-disable */ import React, { useState, useEffect } from "react";
import { Table, Pagination, Space, Button, Tag,Select, DatePicker, notification } from "antd";
import './subscription.css';
import { useSelector, useDispatch } from "react-redux";

import ExportExcel from '../../../shared/ExcelExport/exportExcel';
import { tutorSubscription } from '../../../../globalConstant';
import { POST } from '../../../../apiClient';
import moment from 'moment';




const { Option } = Select;
const day = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
const columns = [

  {
    title: "Student Name",
    render: (text, record) => (
      <Space size="middle">

      {text.student[0].firstName || 'N/A'}{text.student[0].lastName}

      </Space>


    )
  },
  {
    title: "Subject",
    dataIndex: "subject",
    width: "20%",
  },
  {
    title: "Booked",
    width: "20%",
    render:(text,record)=>(
      <Space size="middle">
      
      {text.isBooked ? 'Booked':'Free'}
      </Space>
    )
  },
  {
    title: "Day",


    render: (text, record) => (
      <Space size="middle">
        {text?.schedule ? 
        <Tag color='green' >
         {day[text.schedule.weekday]}
        </Tag>
        :
       'N/A'
        }
      </Space>


    )
  },
  {
    title: "Schedule",


    render: (text, record) => (


      <Space size="middle">
       
       {text?.schedule ? 
<div>
          {moment({
            weekday:text.schedule.weekday,
            hour:text.schedule.startHour,
            minute:text.schedule.startMinute
          }).format('hh:mm')}-
          {moment({
            weekday:text.schedule.weekday,
            hour:text.schedule.endHour,
            minute:text.schedule.endMinute
          }).format('hh:mm')}
       </div>
       :'N/A'}
      
      </Space>


    )
  },

];


const Subscription = () => {

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [listing, setListing] = useState();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [listType,setListType]=useState(0);
  const [loader,setLoader] =useState(false);


  const [pagination, setPagination] = useState(
    {
      current: 1,
      pageSize: limit,
      total: 25,
    }
  )

  useEffect(() => {
    getTransactions();

  }, [limit, offset,listType]);

  const getTransactions = () => {
    
    let params = {
      limit, offset,
      listType
    }
if(listType == 0) delete params.listType;
setLoader(true);
    POST(tutorSubscription, params).then(res => {
     
      if (res.status === 200) {
        setListing(res.data.data.tutorSlots);
        setTotalRecords(res.data.data.totalCount);
        setPagination({ ...pagination, total: res.data.data.totalCount });
        // const temp = [];
        // res.data.data.transactions.forEach((val) => {
        //   temp.push(
        //     {
        //       TutorName: val.teacherID.username,
        //       Sessions: val.duration * 2,
        //       Amount: val.amountCharged,
        //       Status: (val.status) ? 'Paid' : 'Pending'
        //     }
        //   )
        // })
        // setData(temp)
      }
      if (res.status === 500) {
        notification.error({ message: res.err.error.message });
      }

      setLoader(false);
    })
  }
  const handleTableChange = (paginations, filters, sorter) => {
   
    setOffset((paginations.current * paginations.pageSize) - paginations.pageSize);
    setLimit(paginations.pageSize);
    setPagination({ ...pagination, current: paginations.current });

  };
  const onChange = (date, dateString) => {
  
  }
  const paginations = (page, pageSize) => {
    setOffset(page * pageSize - pageSize);
    setLimit(pageSize);
  };
  const handleChange=(value)=> {

    setListType(+value)
  }
  return (
    <div className="mx-3">
      <div className="py-3 float-right d-flex">
        {/* <div className="mr-2"><ExportExcel data={data} /> </div> */}
        <div><Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
          <Option value="0">All</Option>
          <Option value="1">Past</Option>
          <Option value="2">Up-comming</Option>
         
        </Select></div>
        {/* <DatePicker onChange={onChange} /> */}
        </div>
      <Table

        columns={columns}
        rowKey={(record) => record._id}
        dataSource={listing}
        pagination={pagination}
        loading={loader}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Subscription;
