
export const studentProfileAction = (type, data) => {
    return {
        type,
        payload: data
    }
}

export const tutorProfileAction = (type, data) => {
    return {
        type,
        payload: data
    }
}

export const socketInitAction = (type, data) => {
    return {
        type,
        payload: data
    }
}
