/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Card, Modal, Button, Radio } from 'antd';
import DefaultAvatar from '../../../../../assets/images/defaultAvatar.jpeg';
import EmptyVideo from '../../../../../assets/images/video_files.svg';
import EmptyAudio from '../../../../../assets/images/audio_player.svg';
import { GET, POST } from '../../../../../apiClient';
import { tutorDetail } from '../../../../../globalConstant';
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Payout from '../../Payouts/payout';





const Detail = (props) => {

    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [detail, setDetail] = useState({})
    const [level, setLevel] = useState(1);
    const [compSkill, setCompSkill] = useState(1);
    const [optionsWithDisabled, setOptions] = useState([

        { label: '1', value: 0, disabled: true },
        { label: '2', value: 1, disabled: true },
        { label: '3', value: 2, disabled: true },
        { label: '4', value: 3, disabled: true },
        { label: '5', value: 4, disabled: true },
        { label: '6', value: 5, disabled: true },
        { label: '7', value: 6, disabled: true },
        { label: '8', value: 7, disabled: true },
        { label: '9', value: 8, disabled: true },
        { label: '10', value: 9, disabled: true },
    ]);
    const [englishOptionsWithDisabled, setEnglishOptions] = useState([

        { label: '1', value: 0, disabled: true },
        { label: '2', value: 1, disabled: true },
        { label: '3', value: 2, disabled: true },
        { label: '4', value: 3, disabled: true },
        { label: '5', value: 4, disabled: true },
        { label: '6', value: 5, disabled: true },
        { label: '7', value: 6, disabled: true },
        { label: '8', value: 7, disabled: true },
        { label: '9', value: 8, disabled: true },
        { label: '10', value: 9, disabled: true },

    ]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };


    useEffect(() => {
        dispatch({ type: 'LOADING', payload: true });

        GET(`${tutorDetail}/${params.id}/details`).then(res => {
            const { data } = res;
            console.log(res);
            if (res.status == 200 && data.data.success == 1) {
                console.log(res)
                setDetail(data.data.data);
              
                const templevel = englishOptionsWithDisabled;
                if (data.data.data.englishProficiency >= 1) {
                    templevel[data.data.data.englishProficiency - 1].disabled = false;
                }
                
                const temp = optionsWithDisabled;
                if (data.data.data.computerSkills >= 1) {
                    temp[data.data.data.computerSkills - 1].disabled = false;
                }
                setOptions(temp)
                setEnglishOptions(templevel);
                setCompSkill(data.data.data.computerSkills - 1);
                setLevel(data.data.data.englishProficiency - 1);


            }
            if (res.status == 500) {
                notification.error({ message: res.err.error.message });

            }

            dispatch({ type: 'LOADING', payload: false });
        });


        return () => {


        }
    }, [])


    return (
        <div className="listing">
            <div className="back-btn" onClick={() => history.push(`${history.goBack()}`)} ><span className="mr-2"><ArrowLeftOutlined /></span>Back</div>
            {/* <Button style={{ fontFamily: 'initial' }} type="primary" icon={<ArrowLeftOutlined />}
                 >Back</Button> */}

            <Row className="m-0" align="top" gutter={[48, 32]}>


                <Col span={12}>
                    <Card bordered={false} onClick={() => history.push(`${history.location.pathname}`)}>
                        <div className="center ">
                            <div className="mr-4"><img src={detail.profileImage || DefaultAvatar} className="avatar" /> </div>
                            <div className="info mt-3">
                                <div className="text-capitalize"><b>{detail.firstName} {detail.lastName}</b></div>
                                <div>{detail.email}</div>
                                <div>{detail.gender}</div>
                                <div>{detail.dob}</div>
                                <div>{detail.cellNumber}</div>
                                <div>{detail.province} {detail.country}</div>
                                <div>{detail.timeZone}</div>
                                <div>{detail.sect}</div>
                                {detail?.payoneerId ? <div>{detail?.payoneerId}</div> : null}
                                {detail?.totalClasses ? <div>Total {detail?.totalClasses} Classes</div> : null}


                            </div>

                        </div>






                    </Card>



                </Col>
                <Col span={12}>
                    <Card>
                        <div className="left">
                            <div className="content">
                                <small  >About</small>
                                {detail?.about ? <small><strong> {detail.about} </strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        {/* <div className="left">
                            <div className="content">
                                <small>Skills</small>

                                {detail?.skills?.length ? <small ><strong className='text-capitalize'>{detail?.skills?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider /> */}
                        <div className="left">
                            <div className="content">
                                <small>Languages</small>

                                {detail.languages?.length ? <small > <strong className='text-capitalize'>{detail?.languages?.join(', ')}</strong></small> : 'N/A'}

                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">
                                <small>Subjects</small>
                                {detail.subjects?.length ? <small > <strong className='text-capitalize'>{detail?.subjects?.join(', ')}</strong> </small> : 'N/A'}

                            </div>

                        </div>





                    </Card>
                </Col>


            </Row>
            <Row className="m-0" align="top" gutter={[48, 32]}>
                <Col span={24}>
                    <Card>

                        <div className="left">
                            <div className="content">

                                <small>What is your level of English proficiency?</small>
                                <div >
                                    <div className="d-flex mt-4">
                                        <small className="level-text"> <strong> Not Good </strong></small>


                                        <Radio.Group style={{ display: 'flex' }}
                                            options={englishOptionsWithDisabled}
                                            value={level}
                                            defaultValue={level}

                                        />


                                        <small className="level-text"> <strong> Very Good </strong></small>

                                    </div>
                                </div>


                            </div>

                        </div>
                        <Divider />
                        <div className="left">
                            <div className="content">

                                <small>How would you rate your computer skills?</small>
                                <div >
                                    <div className="d-flex mt-4">
                                        <small className="level-text"> <strong> Not Good </strong></small>

                                        <Radio.Group style={{ display: 'flex' }}
                                            options={optionsWithDisabled}
                                            value={compSkill}
                                            defaultValue={compSkill}


                                        />
                                        <small className="level-text"> <strong> Very Good </strong></small>

                                    </div>
                                </div>


                            </div>

                        </div>

                    </Card>
                </Col>
            </Row>

            <Row className="m-0" align="top" gutter={[48, 32]}>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Audios</b> </small>


                                {detail?.signUpAudio?.audioLink1 || detail?.signUpAudio?.audioLink2 || detail?.signUpAudio?.audioLink3 ?

                                    <div className="m-0 center" >


                                        <div className="mb-2 mt-3">
                                            {detail?.signUpAudio?.audioLink1 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink1} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div className="mb-2">
                                            {detail?.signUpAudio?.audioLink2 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink2} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>
                                        <div>
                                            {detail?.signUpAudio?.audioLink3 &&
                                                <audio controls  >
                                                    <source src={detail.signUpAudio.audioLink3} type="video/mp4" />


                                                </audio>
                                            }
                                        </div>




                                    </div>

                                    :
                                    <div className="w-100 text-center mt-4">
                                        <img src={EmptyAudio} width="150px" />
                                        <p className="not-fount-text">No audio found</p>
                                    </div>
                                }





                            </div>

                        </div>




                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <div className="center ">
                            <div className="content">
                                <small><b>Videos</b></small>
                                <Row className="m-0" align="top" gutter={[20, 20]}>

                                    {detail?.signUpVideos?.videoLink1 || detail?.signUpVideos?.videoLink2 || detail?.signUpVideos?.videoLink3 ?
                                        <>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink1 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink1} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink2 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink2} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                            <Col>
                                                {detail?.signUpVideos?.videoLink3 &&
                                                    <video width="220" height="220" controls  >
                                                        <source src={detail.signUpVideos.videoLink3} type="video/mp4" />


                                                    </video>
                                                }
                                            </Col>
                                        </>
                                        :

                                        <div className="w-100 text-center mt-4">
                                            <img src={EmptyVideo} width="150px" />
                                            <p className="not-fount-text">No video found</p>
                                        </div>
                                    }



                                </Row>

                            </div>

                        </div>
                    </Card>
                </Col>
            </Row>
            {detail?.certification?.length > 0 && (
                <Row className="m-0" align="top" gutter={[48, 32]}>
                    <Col span={24}>
                        <Card>
                            <small><b>Certificates</b></small>
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '35px' }}>

                                {detail?.certification && detail?.certification.map((item, index) => (

                                    <div key={index} onClick={() => { setModalImage(item.certificationImageUrl); setIsModalVisible(true) }}>
                                        <img style={{ width: '135px', marginRight: '35px' }} src={item.certificationImageUrl} alt="image" />
                                    </div>

                                ))}
                            </div>
                        </Card>
                    </Col>
                </Row>
            )}

            <Modal
                onCancel={handleCancel}
                visible={isModalVisible}
                footer={false}
            >
                <img className="w-100" src={modalImage} />
            </Modal>
            <Payout teacherId={params.id} />

        </div>
    );
}

export default Detail;