export const UPDATE_DOB = 'UPDATE_DOB';
export const UPDATE_FNAME = 'UPDATE_FNAME';
export const UPDATE_LNAME = 'UPDATE_LNAME';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const UPDATE_CELL = 'UPDATE_CELL';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const UPDATE_TIMEZONE = 'UPDATE_TIMEZONE';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const UPDATE_GENDER = 'UPDATE_GENDER';
export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SOCKET_INIT = 'SOCKET_INIT';

